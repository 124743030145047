/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { IEnvironment } from "@identity/imslib/adobe-id/IEnvironment";
import { JanusRoutes } from "@shared/common";
const {
    HERMES_API_HOST,
    IMS_KEY_HOST,
    ROUTER_BASE_PATH: _basePath,
    SVC_ENV,
} = window.adbJns;
const localBuildEnv = $LOCAL_BUILD_ENV;

let apiHost =
    HERMES_API_HOST && HERMES_API_HOST === "$SUBST_HERMES_API_HOST"
        ? "https://substance3d-dev-va6.adobe.io"
        : `https://${HERMES_API_HOST}`;

if (localBuildEnv === "api-local") {
    apiHost = "http://localhost:8050";
}

export const ENV = SVC_ENV;
export const API_HOST = apiHost;
export const IMS_ENV = IMS_KEY_HOST;
export const HI5_API_KEY = "3dis4r_vr"; // initiated from Hi5 web app
export const HI5_WEBVIEW_API_KEY = "3dis4r_vr_webview"; // initiated from Hi5 VR app
// _basePath is '' on main branch, and not replaced in local dev
export const ROUTER_BASE_PATH =
    !_basePath || _basePath.startsWith("$SUBST") ? undefined : _basePath;

// drop trailing '/'
export const baseUrl =
    origin + (ROUTER_BASE_PATH ? ROUTER_BASE_PATH.slice(0, -1) : "");

const VUPLEX_URLS = [
    JanusRoutes.logInCheck.path,
    JanusRoutes.signedOut.path,
    JanusRoutes.startScreen.path,
    JanusRoutes.assertLogOut.path,
    JanusRoutes.accessCheck.path,
    JanusRoutes.enterReview.path,
    JanusRoutes.logInRequired,
    JanusRoutes.accessRequired,
    JanusRoutes.accessRequested,
    JanusRoutes.assetNotFound,
    JanusRoutes.oneUp.path,
    JanusRoutes.reportAbuse.path,
    JanusRoutes.appSettings.path,
    JanusRoutes.legalNotice.path,
    JanusRoutes.aboutHighFive.path,
];
export const isVRPage = VUPLEX_URLS.some((url) => location.href.includes(url as string));

export const ADOBE_IMS_CONFIG = {
    client_id: isVRPage ? HI5_WEBVIEW_API_KEY : HI5_API_KEY,
    redirect_uri: () => {
        if (location.href.includes(JanusRoutes.accessRequired.path)) {
            return location.href.replace(
                JanusRoutes.accessRequired.path,
                JanusRoutes.accessCheck.path,
            );
        } else {
            return location.href;
        }
    },
    locale: "en_US",
    scope: [
        "ab.manage",
        "AdobeID",
        "openid",
        "creative_cloud",
        "pps.read",
        "additional_info.ownerOrg",
    ].join(","),
    api_parameters: {
        authorize: {
            el: "true",
        },
    },
    environment: IMS_ENV === "prod" ? IEnvironment.PROD : IEnvironment.STAGE,
};

export const ASSET_APIS = {
    cdnSharing:
        IMS_ENV === "prod"
            ? "https://cdn-sharing.adobecc.com"
            : "https://cdn-sharing-stage.adobecc.com",
    hostAcp:
        IMS_ENV === "prod"
            ? "https://platform-cs.adobe.io"
            : "https://platform-cs-stage.adobe.io",
    ims:
        IMS_ENV === "prod"
            ? "https://ims-na1.adobelogin.com"
            : "https://ims-na1-stg1.adobelogin.com",
    invitations:
        IMS_ENV === "prod"
            ? "https://invitations.adobe.io/api/v4"
            : "https://invitations-stage.adobe.io/api/v4",
    leia:
        IMS_ENV === "prod"
            ? "https://leia.adobe.io/v1"
            : "https://leia-stage.adobe.io/v1",
    linkRelationBase: "http://ns.adobe.com/adobecloud/rel",
    substance3d: `${apiHost}/v1`,
    universalSearch:
        IMS_ENV === "prod"
            ? "https://adobesearch.adobe.io/universal-search/v2"
            : "https://adobesearch-stage.adobe.io/universal-search/v2",
};

export const JANUS_URL = baseUrl;

export const ADOBE_ACCOUNT_URL =
    IMS_ENV === "prod"
        ? "https://account.adobe.com/"
        : "https://stage.account.adobe.com/";

export const INGEST_URL =
    IMS_ENV === "prod"
        ? "https://cc-api-data.adobe.io/ingest"
        : "https://cc-api-data-stage.adobe.io/ingest";

export const VERSION = "0.1.0";

export const AEP_ENDPOINT =
    IMS_ENV === "prod"
        ? "https://assets.adobedtm.com/b4d61a9b00a2/8fa57cd316a1/launch-40e4b9f3fe13.min.js"
        : "https://assets.adobedtm.com/b4d61a9b00a2/8fa57cd316a1/launch-d6d1ab362259-staging.min.js";

export const AEP_CONFIG = {
    datastreamId:
        IMS_ENV === "prod"
            ? "913eac4d-900b-45e8-9ee7-306216765cd2"
            : "72b074a6-76d2-43de-a210-124acc734f1c",
    orgId: "9E1005A551ED61CA0A490D45@AdobeOrg",
};

export const PHOTON_CONFIG = {
    Wss: true,
    AppVersion: VERSION,
    NameServer: "ns.photonindustries.io",
    Region: "US",
    Port: 443,
};

export const PHOTON_REALTIME_APPID = "32ca04a6-6084-4876-8d69-995d90e95cdb";
export const PHOTON_VOICE_APPID = "ec08134a-4120-4e02-bd1b-f5e3da8a9e37";
export const PHOTON_VOICE_DEVAPPID = "2e8a483d-0715-4434-9f50-a98a575eacb8";

// TODO: update this to the correct max players before release
export const PHOTON_MAX_PLAYERS = 0;

export const PHOTON_PLAYER_TTL = 2000;
