/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    View,
    Flex,
    ActionGroup,
    Item,
    Text,
    ActionButton,
    MenuTrigger,
    Menu,
    Icon,
    Selection,
} from "@adobe/react-spectrum";
import type { IconProps } from "@adobe/react-spectrum";
import { useCameraControlsToggle } from "@components/studio/src/hooks/useCameraControlsToggle";
import { ToggleMode } from "@components/studio/src/scene/PanningArcRotateCameraPointersInput";
import { SceneManager } from "@components/studio/src/scene/SceneManager";
import Dolly from "@spectrum-icons/workflow/Dolly";
import Orbit from "@spectrum-icons/workflow/Orbit";
import Pan from "@spectrum-icons/workflow/Pan";
import Select from "@spectrum-icons/workflow/Select";
import { useState, useEffect, type ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { Beta } from "./Beta";
import { useNetworkContext } from "@src/contexts/NetworkContext";

import SelectAndMoveSVG from "@src/images/select-and-move.svg";
import SelectAndRotateSVG from "@src/images/select-and-rotate.svg";

const NAV_PANEL_KEYS = {
    rotate: "rotate",
    dolly: "dolly",
    pan: "pan",
    none: "none",
} as const;
type NavPanelKey = (typeof NAV_PANEL_KEYS)[keyof typeof NAV_PANEL_KEYS];

type PanelButton = {
    key: NavPanelKey;
    label: string;
    icon: ReactElement;
    tooltip: string;
    description: string;
};

type NavigationMode = "none" | "rotate" | "pan" | "dolly";

const navigationPanelButtons = [
    {
        key: NAV_PANEL_KEYS.rotate,
        label: "nav.label.orbit",
        icon: <Orbit size="S" />,
        tooltip: "nav.tooltip.orbit",
        description: "",
    },
    {
        key: NAV_PANEL_KEYS.dolly,
        label: "nav.label.dolly",
        icon: <Dolly size="S" />,
        tooltip: "nav.tooltip.dolly",
        description: "",
    },
    {
        key: NAV_PANEL_KEYS.pan,
        label: "nav.label.pan",
        icon: <Pan size="S" />,
        tooltip: "nav.tooltip.pan",
        description: "",
    },
];

const OBJECT_PANEL_KEYS = {
    select: "select",
    move: "move",
    rotate: "rotate",
} as const;

type ObjectMode = "select" | "move" | "rotate";

interface NavigationPanelProps {
    sceneManager: SceneManager | undefined;
}

const SelectAndMove = (props: Omit<IconProps, "children">) => {
    return (
        <Icon {...props}>
            <img
                src={SelectAndMoveSVG}
                alt="Select and Move"
                style={{ width: "25px", height: "25px" }}
            />
        </Icon>
    );
};

const SelectAndRotate = (props: Omit<IconProps, "children">) => {
    return (
        <Icon {...props}>
            <img
                src={SelectAndRotateSVG}
                alt="Select and Move"
                style={{ width: "25px", height: "25px" }}
            />
        </Icon>
    );
};

export function NavigationPanel({ sceneManager }: NavigationPanelProps) {
    const [selectedNavigationKey, setSelectedNavigationKey] =
        useState<Selection>(new Set([NAV_PANEL_KEYS.rotate]));
    const [selectedObjectKey, setSelectedObjectKey] = useState<Selection>(
        new Set([]),
    );
    const [isLockedOut, setIsLockedOut] = useState<boolean>(false);
    const [grouping, setGrouping] = useState(false);

    const { mode, setMode } = useCameraControlsToggle(sceneManager);

    const { networkManager } = useNetworkContext();
    const { t } = useTranslation("web");

    useEffect(() => {
        if (sceneManager) {
            const pinManager = sceneManager.viewer.plugins.pins;
            pinManager?.toggleGrouping(grouping);
            if (pinManager && !grouping) {
                // Hack for resetting pins
                // @ts-ignore
                pinManager?.setPins(pinManager.pins.map((pin) => pin.pinData));
            }
        }
    }, [grouping, sceneManager]);

    useEffect(() => {
        if (!sceneManager) return;
    }, [sceneManager]);

    const toggleMode = (buttonMode: ToggleMode) => {
        if (mode !== buttonMode) {
            setMode(buttonMode);
        } else {
            setMode("rotate");
        }
    };

    useEffect(() => {
        const handleOnAssetLockedOut = (event: any) => {
            setIsLockedOut(event.detail.isLockedOut);
        };
        window.addEventListener("onAssetLockedOut", handleOnAssetLockedOut);

        return () => {
            window.removeEventListener(
                "onAssetLockedOut",
                handleOnAssetLockedOut,
            );
        };
    });

    useEffect(() => {
        const selectedKey = [...selectedNavigationKey][0] as NavigationMode;
        toggleMode(selectedKey as ToggleMode);
    }, [selectedNavigationKey]);

    useEffect(() => {
        const selectedKey = [...selectedObjectKey][0] as ObjectMode;
        console.log("selectedKey", selectedKey);
        if (selectedKey === null) {
            networkManager?.getObjectManager()?.disableGizmos();
        } else {
            if (selectedKey === OBJECT_PANEL_KEYS.select) {
                networkManager?.getObjectManager()?.enableAllGizmos();
            } else if (selectedKey === OBJECT_PANEL_KEYS.move) {
                networkManager?.getObjectManager()?.enablePositionGizmoOnly();
            } else if (selectedKey === OBJECT_PANEL_KEYS.rotate) {
                networkManager?.getObjectManager()?.enableRotationGizmoOnly();
            }
        }
    }, [selectedObjectKey]);

    return (
        <View
            backgroundColor="gray-100"
            height="100%"
            width="48px"
            borderStartColor="gray-75"
            borderStartWidth="thick"
            paddingTop="size-100"
            paddingX="size-75">
            <Flex height="100%" direction="column" gap="7px">
                <MenuTrigger trigger="longPress" direction="end" align="end">
                    <ActionButton
                        aria-label="Select"
                        isQuiet
                        isDisabled={isLockedOut}
                        onPress={() => {
                            setSelectedObjectKey(
                                new Set([OBJECT_PANEL_KEYS.select]),
                            );
                        }}>
                        <Select size="S" />
                    </ActionButton>
                    <Menu
                        selectionMode="single"
                        onSelectionChange={(key) => setSelectedObjectKey(key)}>
                        <Item textValue="Move" key={OBJECT_PANEL_KEYS.move}>
                            <SelectAndMove />
                            <Text>Move object</Text>
                        </Item>
                        <Item textValue="Rotate" key={OBJECT_PANEL_KEYS.rotate}>
                            <SelectAndRotate />
                            <Text>Rotate object</Text>
                        </Item>
                    </Menu>
                </MenuTrigger>
                <ActionGroup
                    orientation="vertical"
                    selectionMode="single"
                    selectedKeys={selectedNavigationKey}
                    onSelectionChange={setSelectedNavigationKey}
                    buttonLabelBehavior="hide"
                    isQuiet>
                    {navigationPanelButtons.map((button: PanelButton) => {
                        return (
                            <Item key={button.key} aria-label={t(button.label)}>
                                {button.icon}
                                <Text>
                                    {t(button.tooltip)}
                                    {button.description}
                                </Text>
                            </Item>
                        );
                    })}
                </ActionGroup>
                <div
                    style={{
                        border: grouping
                            ? "1px solid gray"
                            : "1px solid transparent",
                        width: "34px",
                        height: "31px",
                        overflow: "hidden",
                        borderRadius: "2px",
                    }}>
                    <ActionButton
                        isQuiet
                        onPress={() => setGrouping(!grouping)}>
                        <Beta />
                    </ActionButton>
                </div>
            </Flex>
        </View>
    );
}
