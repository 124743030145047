/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {EventEmitter} from "events";

export interface SpeechEvents {
    SPEAKING: [isSpeaking: boolean]
}

export class DetectSpeech extends EventEmitter<SpeechEvents>{
    private analyser: AnalyserNode;
    private audioContext: AudioContext
    private polling = false;
    private lastSpoke = 0;

    public isSpeaking = false;

    constructor(
        mediaStream: MediaStream,
        public speakingThreshold = 0.1,
        public deadMicThreshold = 200, // Milliseconds since speech last detected
    ) {
        super();
        this.audioContext = new AudioContext();
        const mediaStreamSource =
            this.audioContext.createMediaStreamSource(mediaStream);
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 2048;
        mediaStreamSource.connect(this.analyser);
        this.poll();
        console.log("Constructor");
    }

    detect() {
        const dataArray = new Uint8Array(this.analyser.fftSize);

        this.analyser.getByteTimeDomainData(dataArray);

        let speakingNow = false;
        let maxAmp = 0;
        for (let i = 0; i < dataArray.length; i++) {
            const amplitude = Math.abs(dataArray[i] / 128 - 1.0);
            maxAmp = amplitude > maxAmp ? amplitude : maxAmp;
            if (amplitude > this.speakingThreshold) {
                speakingNow = true;
                break;
            }
        }
        // console.log("maxAmp", maxAmp);

        const now = Date.now();

        if (!speakingNow && this.isSpeaking && now - this.lastSpoke > this.deadMicThreshold) {
            this.isSpeaking = speakingNow;
            this.emit("SPEAKING", speakingNow);
        }
        if (!this.isSpeaking && speakingNow) {
            this.isSpeaking = speakingNow;
            this.emit("SPEAKING", speakingNow);
        }

        if (speakingNow) {
            this.lastSpoke = now;
        }
    };

    poll() {
        window.requestAnimationFrame(() => {
            this.detect();
            if (this.polling) {
                this.poll();
            }
        })
        this.polling = true;
    }

    stopPolling() {
        this.polling = false;
    }

    dispose() {
        this.removeAllListeners();
        this.stopPolling();
        this.audioContext.close();
    }
}
