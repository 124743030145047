/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Avatar,
    Divider,
    Flex,
    Text,
    View,
    Link as LinkSpectrum,
} from "@adobe/react-spectrum";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useTranslation } from "react-i18next";

import { Preferences } from "./Preferences";
import { StorageQuotaView } from "../components/StorageQuotaView";
import { ADOBE_ACCOUNT_URL } from "../config";
import { useOpenInNewTab } from "../hooks/useOpenInNewTab";

interface UserProfileDropdownProps {
    name: string;
    email: string;
    avatarUrl: string;
}

export function UserProfileDropdown({
    name,
    email,
    avatarUrl,
}: UserProfileDropdownProps) {
    const { t } = useTranslation(["common", "web"]);
    const { openInNewTab } = useOpenInNewTab();
    const { logIn, logOut, userProfile } = useHi5UserContext();

    return (
        <View maxWidth="size-4600" borderColor="gray-50" borderWidth="thin" borderRadius="small">
            <Flex direction="column" alignItems="start" marginTop="size-100">
                <Flex
                    direction="column">
                    <Flex
                        alignItems="center"
                        justifyContent="start"
                        columnGap="size-200"
                        marginX="size-200"
                        marginY="size-150"
                        marginEnd="size-1000">
                        <Avatar
                            src={avatarUrl}
                            size={64}
                            alt={t("accessibility.account.profilePicture")}
                        />
                        <Flex direction="column" alignItems="start">
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.25em",
                                    fontWeight: "700",
                                }}>
                                {name}
                            </Text>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1em",
                                }}>
                                {email}
                            </Text>
                            <Flex
                                direction="column"
                                alignItems="start"
                                marginTop="1vh">
                                <LinkSpectrum
                                    onPress={() => {
                                        openInNewTab(ADOBE_ACCOUNT_URL);
                                    }}
                                    variant="secondary">
                                    {t("web:account.adobeAccount")}
                                </LinkSpectrum>
                            </Flex>
                        </Flex>
                    </Flex>
                    <StorageQuotaView />
                </Flex>
                <Divider size="S" />
                <Flex
                    direction="column"
                    alignItems="start"
                    justifyContent="start"
                    columnGap="size-200"
                    marginTop="size-100"
                    marginBottom="size-100"
                    width="100%">
                    <Text
                        UNSAFE_style={{
                            fontSize: "0.75em",
                            fontWeight: "600",
                            color: "gray-600",
                            marginLeft: "22px",
                            marginBottom: "8px",
                            marginTop: "5px",
                            textTransform: "uppercase",
                        }}>
                        {t("web:header.adobe")}
                    </Text>
                    <Preferences isSignedIn={true} />
                </Flex>
                <Divider size="S" />
                <Flex
                    direction="column"
                    alignItems="start"
                    justifyContent="start"
                    columnGap="size-200"
                    marginY="size-100"
                    width="100%">
                    <ActionButton
                        isQuiet
                        onPress={userProfile ? logOut : logIn}
                        UNSAFE_style={{
                            display: "block",
                            width: "100%",
                            textAlign: "left",
                            paddingLeft: "8px",
                            borderRadius: "0px",
                        }}>
                        <Text>
                            {userProfile
                                ? t("common:account.signOut")
                                : t("web:account.signin")}
                        </Text>
                    </ActionButton>
                </Flex>
            </Flex>
        </View>
    );
}
