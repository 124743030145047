/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    DialogTrigger,
    ActionButton,
    Dialog,
    Heading,
    Divider,
    Content,
    Picker,
    Item,
    ButtonGroup,
    Button,
    Text,
    Flex,
} from "@adobe/react-spectrum";
import Settings from "@spectrum-icons/workflow/Settings";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useLocaleContext } from "../contexts/LocaleContext";
import { useThemeContext } from "../contexts/ThemeContext";

import type { Key } from "react";

interface PreferencesProps {
    isSignedIn: boolean;
}

export function Preferences({ isSignedIn }: PreferencesProps) {
    const { t } = useTranslation("web");

    const { locale, availableLocales, changeLocale } = useLocaleContext();
    const { themeOptions, currentTheme, handleThemeChange } = useThemeContext();

    const [selectedLocale, setSelectedLocale] = useState<string>(locale);
    const [selectedTheme, setSelectedTheme] = useState<string>(currentTheme);

    return (
        <DialogTrigger type="modal">
            {isSignedIn ? (
                <ActionButton
                    isQuiet
                    UNSAFE_style={{
                        display: "block",
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "10px",
                        borderRadius: "0px",
                    }}>
                    {t("account.preferences")}
                </ActionButton>
            ) : (
                <Button variant="primary">
                    <Settings aria-label={t("account.preferences")} />
                    <Text>{t("account.preferences")}</Text>
                </Button>
            )}

            {(close) => (
                <Dialog width="360px">
                    <Heading>{t("account.preferences")}</Heading>
                    <Divider />
                    <Content>
                        <Flex direction="column" gap="size-100">
                            <Picker
                                width="100%"
                                label={t("account.language")}
                                items={availableLocales}
                                defaultSelectedKey={selectedLocale}
                                onSelectionChange={(key: Key) => {
                                    setSelectedLocale(key as string);
                                }}>
                                {(item) => (
                                    <Item key={item.code}>{item.language}</Item>
                                )}
                            </Picker>
                            <Picker
                                width="100%"
                                label={t("account.preferences.colorTheme")}
                                items={themeOptions}
                                defaultSelectedKey={currentTheme}
                                onSelectionChange={(key: Key) => {
                                    setSelectedTheme(key as string);
                                }}>
                                {(item) => (
                                    <Item key={item.theme}>{t(item.name)}</Item>
                                )}
                            </Picker>
                        </Flex>
                    </Content>
                    <ButtonGroup>
                        <Button
                            variant="primary"
                            onPress={() => {
                                close();
                                changeLocale(selectedLocale);
                                setSelectedLocale(selectedLocale);
                                handleThemeChange(selectedTheme);
                            }}
                            autoFocus>
                            {t("actions.done")}
                        </Button>
                    </ButtonGroup>
                </Dialog>
            )}
        </DialogTrigger>
    );
}
