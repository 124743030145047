/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type { GraphSpec, ModelMediaExtensionsUsd } from "@shared/types";
import { JobGraph } from "@shared/common";

export function getConversionSpec(
    accessToken: string,
    assetId: string,
    componentId: string,
    componentVersion: string,
    originalExtension: string
) {
    const job = new JobGraph(sandbox => {
        const model = sandbox.httpsAcpComponentDownload(
            {
                assetId: assetId,
                componentId: componentId,
                version: componentVersion,
                imsToken: accessToken,
            },
            `model/${originalExtension as ModelMediaExtensionsUsd}`,
        );
        if (originalExtension !== "usdz") {
            model
                .modelConvert("model/.usdz")
                .httpsAcpComponentUpload({
                    imsToken: accessToken,
                    assetId: assetId,
                    componentName: "convertedUsdzModel",
                    componentPath: "renditions/convertedUsdzModel.usdz",
                });
        }
        if (originalExtension !== "glb") {
            model
                .modelConvert("model/.glb")
                .httpsAcpComponentUpload({
                imsToken: accessToken,
                assetId: assetId,
                componentName: "convertedGlbModel",
                componentPath: "renditions/convertedGlbModel.glb",
                });
        }
    });
    return job.body.spec;
}


const THUMBNAIL_WIDTH = 256;
const THUMBNAIL_HEIGHT = 256;
const THUMBNAIL_SAMPLES = 16;

export function getThumbnailSpec(
    accessToken: string,
    assetId: string,
    componentId: string,
    componentVersion: string,
    originalExtension: string,
) {

    const job = new JobGraph(sandbox => {
        sandbox.httpsAcpComponentDownload(
            {
                assetId,
                componentId,
                version: componentVersion,
                imsToken: accessToken
                // @ts-ignore : hopefully something checks the extension here
            }, `model/${originalExtension}`
        ).modelRender(
            {
                resolution: {
                    width: THUMBNAIL_WIDTH,
                    height: THUMBNAIL_HEIGHT,
                },
                backgroundColor: {
                    red: 0.0,
                    green: 0.0,
                    blue: 0.0,
                    alpha: 0.0,
                },
                samples: THUMBNAIL_SAMPLES,
                gpu: false,
                denoise: true,
                groundplane: false,
                stripLights: true,
            }, 'image/.png'
        ).httpsAcpComponentUpload({
            imsToken: accessToken,
            assetId: assetId,
            componentName: "thumbnail",
            componentPath: "renditions/image-256-256.png",
            componentRelationship: "rendition",

        });
    });
    return job.body.spec;
}
