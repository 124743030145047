/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Text, Image, View, Button, Footer } from "@adobe/react-spectrum";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import landingImage from "../images/rw_landing.png";
import { AppHeader } from "@src/components/AppHeader";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useRedirects } from "@src/hooks/useRedirects";

export function Landing() {
    const { t } = useTranslation(["common", "web"]);
    const { accessToken, logIn, imsReady } = useHi5UserContext();

    const { homeRedirect } = useRedirects();

    useEffect(() => {
        if (imsReady && accessToken) {
            homeRedirect();
        }
        return;
    }, [accessToken, imsReady]);

    return (
        <View height="100vh">
            <Flex direction="column" height="100vh">
                <AppHeader />
                <View
                    backgroundColor="gray-100"
                    paddingX="4vw"
                    paddingY="15vh"
                    height="100%">
                    <Flex direction="row" gap="size-200">
                        <Flex direction="column" maxWidth="40vw">
                            <View paddingY="10vh">
                                <View marginBottom="1vh">
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "2.75em",
                                            fontWeight: 700,
                                        }}>
                                        {t("web:home.welcome")}
                                    </Text>
                                </View>
                                <View marginBottom="3vh">
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "1em",
                                            fontWeight: 700,
                                        }}>
                                        {t("web:landing.details")}
                                    </Text>
                                </View>
                                <Flex alignItems="start">
                                    <Button
                                        onPress={logIn}
                                        variant="accent"
                                        minWidth="72px">
                                        <Text>
                                            {t("web:landing.getStarted")}
                                        </Text>
                                    </Button>
                                </Flex>
                            </View>
                        </Flex>
                        <View>
                            <Image
                                width="50vw"
                                height="28vw"
                                minWidth="100px"
                                minHeight="56px"
                                alt={t("common:header.appIcon")}
                                src={landingImage}
                                UNSAFE_style={{
                                    borderRadius: "8px",
                                }}
                            />
                        </View>
                    </Flex>
                </View>
                <View backgroundColor="gray-75" paddingY="5vh">
                    <Footer>
                        <Flex
                            direction="row"
                            gap="size-200"
                            justifyContent="center">
                            <View>
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "1em",
                                        fontWeight: 500,
                                        color: "var(--spectrum-global-color-gray-200))",
                                    }}>
                                    {t("web:landing.footer.copyright")}
                                </Text>
                            </View>
                        </Flex>
                    </Footer>
                </View>
            </Flex>
        </View>
    );
}
