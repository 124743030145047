/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Text,
    View,
    ActionButton,
    Divider,
    Switch,
    Item,
    Picker,
    ContextualHelp,
    Content,
    Heading,
} from "@adobe/react-spectrum";
import GlobeGrid from "@spectrum-icons/workflow/GlobeGrid";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { PreferenceName } from "./AppSettingsTabsView";
import { useLocaleContext } from "../contexts/LocaleContext";
import { usePostMessageContext } from "../contexts/PostMessageContext";

import type { Key } from "@react-types/shared";

interface PreferencesProps {
    selectedLang: Key;
    selectedUiSize: Key;
    selectedController: Key;
    selectedHaptics: boolean;
    selectedPreset: Key;
    selectedResolution: boolean;
    selectedSpaceWarp: boolean;
    selectedCustomResolution: Key;
    setPreferenceState: (preference: Key, value: any) => void;
}

export function VRPreferences({
    selectedLang,
    selectedUiSize,
    selectedController,
    selectedHaptics,
    selectedPreset,
    selectedResolution,
    selectedSpaceWarp,
    selectedCustomResolution,
    setPreferenceState,
}: PreferencesProps) {
    const { t } = useTranslation("common");

    const { availableLocales, changeLocale } = useLocaleContext();

    const { postMessageBusApi } = usePostMessageContext();

    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab") || "";

    useEffect(() => {
        if (tab == "preferences") {
            console.log("tab", tab);
        }
    }, [tab]);

    const uiSizes = [
        { size: "Small", name: t("preferences.ui.size.small") },
        { size: "Medium", name: t("preferences.ui.size.medium") },
        { size: "Large", name: t("preferences.ui.size.large") },
    ];

    const controllers = [
        { controller: "Right", name: t("preferences.controller.right") },
        { controller: "Left", name: t("preferences.controller.left") },
    ];

    const presets = [
        {
            preset: "Automatic",
            name: t("preferences.graphics.preset.automatic"),
        },
        { preset: "High", name: t("preferences.graphics.preset.high") },
        { preset: "Balanced", name: t("preferences.graphics.preset.balanced") },
        {
            preset: "Performance",
            name: t("preferences.graphics.preset.performance"),
        },
        { preset: "Custom", name: t("preferences.graphics.preset.custom") },
    ];

    const customResolutions = [
        {
            customResolution: "SuperSample",
            name: t("preferences.graphics.resolution.superSample"),
        },
        {
            customResolution: "Balanced",
            name: t("preferences.graphics.resolution.balanced"),
        },
        {
            customResolution: "Performance",
            name: t("preferences.graphics.resolution.performance"),
        },
    ];

    function NoCustom() {
        if (tab == "preferences")
            postMessageBusApi?.resizeWindow("app-settings-preferences");
        else postMessageBusApi?.resizeWindow("app-settings-preferences-ext");

        return (
            <Flex direction="row" width="100%"
               justifyContent='space-between'>
                <Text
                    marginTop="8px"
                    UNSAFE_style={{
                        fontSize: bodyFontSize,
                    }}>
                    {t("preferences.graphics.preset")}
                </Text>
                <Picker
                    // marginStart="118px"
                    width="size-2400"
                    items={presets}
                    selectedKey={selectedPreset}
                    onSelectionChange={(key: Key) => {
                        postPresetChange(key);
                    }}>
                    {(item) => (
                        <Item key={item.preset} textValue={item.name}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: bodyFontSize,
                                    padding: "5px",
                                }}>
                                {item.name}
                            </Text>
                        </Item>
                    )}
                </Picker>
            </Flex>
        );
    }

    function ShowCustom() {
        postMessageBusApi?.resizeWindow("app-settings-preferences-customGP");
        return (
            <View>
                <Flex direction="column" gap="15px">
                    <Flex
                        direction="row"
                        width="100%"
                        justifyContent="space-between">
                        <Text
                            marginTop="8px"
                            UNSAFE_style={{
                                fontSize: bodyFontSize,
                            }}>
                            {t("preferences.graphics.preset")}
                        </Text>
                        <Picker
                            // marginStart="118px"
                            width="size-2400"
                            items={presets}
                            selectedKey={selectedPreset}
                            onSelectionChange={(key: Key) => {
                                postPresetChange(key);
                            }}>
                            {(item) => (
                                <Item key={item.preset} textValue={item.name}>
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: bodyFontSize,
                                            padding: "5px",
                                        }}>
                                        {item.name}
                                    </Text>
                                </Item>
                            )}
                        </Picker>
                    </Flex>
                    <Flex direction="row">
                        <Switch
                            isSelected={selectedResolution}
                            onChange={() => {
                                postResolutionChange(!selectedResolution);
                            }}>
                            {t("preferences.graphics.dynamic.resolution")}
                        </Switch>
                        <ContextualHelp variant="info" marginTop="6px">
                            <Heading>
                                {t("preferences.graphics.dynamic.resolution")}
                            </Heading>
                            <Content>
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "1.25em",
                                    }}>
                                    {t(
                                        "preferences.graphics.dynamic.resolution.help",
                                    )}
                                </Text>
                            </Content>
                        </ContextualHelp>
                    </Flex>
                    <Flex
                        direction="row"
                        width="100%"
                        justifyContent="space-between">
                        <Text
                            marginTop="8px"
                            UNSAFE_style={{
                                fontSize: bodyFontSize,
                            }}>
                            {t("preferences.graphics.resolution")}
                        </Text>
                        <Picker
                            // marginStart="96px"
                            width="size-2400"
                            items={customResolutions}
                            selectedKey={selectedCustomResolution}
                            onSelectionChange={(key: Key) => {
                                postCustomResolutionChange(key);
                            }}>
                            {(item) => (
                                <Item
                                    key={item.customResolution}
                                    textValue={item.name}>
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: bodyFontSize,
                                            padding: "5px",
                                        }}>
                                        {item.name}
                                    </Text>
                                </Item>
                            )}
                        </Picker>
                    </Flex>
                    <Flex direction="row">
                        <Switch
                            isSelected={selectedSpaceWarp}
                            onChange={() => {
                                postSpaceWarpChange(!selectedSpaceWarp);
                            }}>
                              {t("preferences.graphics.space.warp")}
                        </Switch>
                        <ContextualHelp variant="info" marginTop="6px">
                            <Heading
                                UNSAFE_style={{
                                    fontSize: bodyFontSize,
                                }}>
                                {t("preferences.graphics.space.warp")}
                            </Heading>
                            <Content>
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "1.25em",
                                    }}>
                                    {t("preferences.graphics.space.warp.help")}
                                </Text>
                            </Content>
                        </ContextualHelp>
                    </Flex>
                </Flex>
            </View>
        );
    }

    const postLocaleChange = (value: Key) => {
        setPreferenceState(PreferenceName.lang, value);
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("language", value);
        }
        changeLocale(value as string);
    };

    const openAboutHighFive = () => {
        if (postMessageBusApi) {
            postMessageBusApi.openAboutHighFive();
        }
    };

    const postUiSizeChange = (value: Key) => {
        setPreferenceState(PreferenceName.uiSize, value);
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("uiSize", value as string);
        }
    };

    const postControllerChange = (value: Key) => {
        setPreferenceState(PreferenceName.controller, value);
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("controller", value as string);
        }
    };

    const postHapticsChange = (value: boolean) => {
        setPreferenceState(PreferenceName.haptics, value);
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("haptics", value);
        }
    };

    const postResolutionChange = (value: boolean) => {
        setPreferenceState(PreferenceName.resolution, value);
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("resolution", value);
        }
    };

    const postSpaceWarpChange = (value: boolean) => {
        setPreferenceState(PreferenceName.spaceWarp, value);
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("spaceWarp", value);
        }
    };

    const postPresetChange = (value: Key) => {
        setPreferenceState(PreferenceName.preset, value);
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("preset", value);
        }
    };

    const postCustomResolutionChange = (value: Key) => {
        setPreferenceState(PreferenceName.customResolution, value);
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("customResolution", value);
        }
    };

    const headerColor = "rgb(150,150,150)";
    const headerFontSize = "1.1em";
    const bodyFontSize = "1.4em";
    return (
        <View width="500px" height="572px">
            <Flex direction="column" gap="15px">
                <View>
                    <Flex direction="column" gap="15px"
                    justifyContent="space-between">
                        <Text
                            UNSAFE_style={{
                                color: headerColor,
                                fontSize: headerFontSize,
                            }}>
                            {t("preferences.ui.settings")}
                        </Text>
                        <Flex
                            direction="row"
                            width="100%"
                            justifyContent="space-between">
                            <Text
                                UNSAFE_style={{
                                    fontSize: bodyFontSize,
                                }}>
                                {t("preferences.language")}
                            </Text>
                            <Picker
                                //marginEnd="100px"
                                width="size-2400"
                                items={availableLocales}
                                selectedKey={selectedLang}
                                onSelectionChange={(key: Key) => {
                                    postLocaleChange(key);
                                }}>
                                {(item) => (
                                    <Item
                                        key={item.code}
                                        textValue={item.language}>
                                        <Text
                                            UNSAFE_style={{
                                                fontSize: bodyFontSize,
                                                padding: "5px",
                                            }}>
                                            {item.language}
                                        </Text>
                                    </Item>
                                )}
                            </Picker>
                        </Flex>
                        <Flex
                            direction="row"
                            width="100%"
                            justifyContent="space-between">
                            <Text
                                UNSAFE_style={{
                                    fontSize: bodyFontSize,
                                }}>
                                {t("preferences.ui.size")}
                            </Text>
                            <Picker
                                // marginEnd="100px"
                                width="size-2400"
                                items={uiSizes}
                                selectedKey={selectedUiSize}
                                onSelectionChange={(key: Key) => {
                                    postUiSizeChange(key);
                                }}>
                                {(item) => (
                                    <Item key={item.size} textValue={item.name}>
                                        <Text
                                            UNSAFE_style={{
                                                fontSize: bodyFontSize,
                                                padding: "5px",
                                            }}>
                                            {item.name}
                                        </Text>
                                    </Item>
                                )}
                            </Picker>
                        </Flex>
                        <Divider size="M" />
                    </Flex>
                </View>
                <View>
                    <Flex direction="column" gap="15px" justifyContent="space-between">
                        <Text
                            UNSAFE_style={{
                                color: headerColor,
                                fontSize: headerFontSize,
                            }}>
                            {t("preferences.input")}
                        </Text>
                        <Flex
                            direction="row"
                            width="100%"
                            justifyContent="space-between">
                            <Text
                                UNSAFE_style={{
                                    fontSize: bodyFontSize,
                                }}>
                                {t("preferences.input.contorller")}
                            </Text>
                            <Picker
                                // marginStart="50px"
                                width="size-2400"
                                items={controllers}
                                selectedKey={selectedController}
                                onSelectionChange={(key: Key) => {
                                    postControllerChange(key);
                                }}>
                                {(item) => (
                                    <Item
                                        key={item.controller}
                                        textValue={item.name}>
                                        <Text
                                            UNSAFE_style={{
                                                fontSize: bodyFontSize,
                                                padding: "5px",
                                            }}>
                                            {item.name}
                                        </Text>
                                    </Item>
                                )}
                            </Picker>
                        </Flex>
                        <Divider size="M" />
                    </Flex>
                </View>
                <View>
                    <Text
                        UNSAFE_style={{
                            color: headerColor,
                            fontSize: headerFontSize,
                        }}>
                        {t("preferences.accesibility")}
                    </Text>
                    <Flex direction="column" gap="8.5px">
                        <></>
                        {/* <ActionButton width="180px" isDisabled>
                            Quest accesibility settings
                        </ActionButton> */}
                    </Flex>
                </View>
                <View>
                    <Switch
                        isEmphasized
                        isSelected={selectedHaptics}
                        onChange={() => {
                            postHapticsChange(!selectedHaptics);
                        }}>
                             {t("preferences.accesibility.haptics")}
                    </Switch>
                    {/* <Switch marginStart="30px">
                        {t("preferences.reference.object")}
                    </Switch> <--- disabling for now per Soonho */}
                </View>
                <Divider size="M" />
                <View>
                    <Text
                        UNSAFE_style={{
                            paddingTop: "10px",
                            color: headerColor,
                            fontSize: headerFontSize,
                        }}>
                        {t("preferences.graphics")}
                    </Text>
                    <Flex direction="column" gap="12px" justifyContent="space-between">
                        {selectedPreset !== "Custom" ? (
                            <>{NoCustom()}</>
                        ) : (
                            <>{ShowCustom()}</>
                        )}
                    </Flex>
                </View>
                <Divider size="M" />
                <View>
                    <Text
                        UNSAFE_style={{
                            paddingTop: "10px",
                            color: headerColor,
                            fontSize: headerFontSize,
                        }}>
                        {t("preferences.about")}
                    </Text>
                    <Flex direction="column" gap="15px" marginTop="10px" alignContent="center">
                        <Flex direction="column">
                            <ActionButton
                                height="3em"
                                onPress={() => {
                                    if (postMessageBusApi)
                                        postMessageBusApi.showLegalNotice();
                                }}
                                UNSAFE_style={{
                                    paddingLeft: "1em",
                                    paddingRight: "1em",
                                }}>
                                <GlobeGrid />
                                <Text UNSAFE_style={{ fontSize: bodyFontSize }}>
                                    {t("preferences.legal.notice")}
                                </Text>
                            </ActionButton>
                        </Flex>
                        <Flex direction="column">
                            <ActionButton
                                height="3em"
                                onPress={openAboutHighFive}
                                UNSAFE_style={{
                                    paddingLeft: "1em",
                                    paddingRight: "1em",
                                }}>
                                <Text UNSAFE_style={{ fontSize: bodyFontSize }}>
                                    {t("preferences.legal.about")}
                                </Text>
                            </ActionButton>
                        </Flex>
                    </Flex>
                </View>
            </Flex>
        </View>
    );
}
