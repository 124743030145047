/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Dialog,
    Heading,
    Divider,
    Content,
    Flex,
    TextField,
    ButtonGroup,
    Button,
    Form,
    Image,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAcpContext } from "@src/contexts/AcpContext";
import { usePostMessageContext } from "@src/contexts/PostMessageContext";

interface AssetRenameDialogProps {
    urn: string;
    displayName: string;
    thumbnailUrl: string;
    isForVr: boolean;
    toggleRenameDialog: (isShowing: boolean) => void;
}

export function AssetRenameDialog({
    urn: assetId,
    displayName,
    thumbnailUrl,
    isForVr,
    toggleRenameDialog,
}: AssetRenameDialogProps) {
    const { t } = useTranslation("common");
    const [targetName, setTargetName] = useState<string>(displayName);
    const {refreshMenuInputFields} = usePostMessageContext();
    const {useRenameReviewMutation} = useAcpContext();
    const {mutateAsync: renameAsset} = useRenameReviewMutation();

    async function handleRename() {
        toggleRenameDialog(false);
        const isSuccessful = await renameAsset({assetId, name: targetName});
        if (isSuccessful) {
            ToastQueue.positive(t("toast.rename.successful"), {
                timeout: 5000,
            });
        } else {
            ToastQueue.negative(t("toast.rename.fail"), { timeout: 5000 });
        }
    }

    useEffect(() => {
        setTimeout(() => {
            refreshMenuInputFields();
        }, 500)
    }, [])

    return (
        <Dialog size="S">
            <Heading>{t("rename.header")}</Heading>
            <Divider />
            <Content>
                <Flex direction="row" gap="size-200">
                    <Image
                        minWidth={isForVr ? "2vw" : "size-600"}
                        minHeight={isForVr ? "2vw" : "size-600"}
                        maxWidth={isForVr ? "2vw" : "size-600"}
                        maxHeight={isForVr ? "2vw" : "size-600"}
                        alt={t("accessibility.asset.thumbnail")}
                        src={thumbnailUrl}
                        UNSAFE_style={{
                            borderRadius: "8px",
                        }}
                    />
                    <Form
                        width="100%"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleRename();
                        }}>
                        <TextField
                            defaultValue={displayName}
                            onChange={setTargetName}
                        />
                    </Form>
                </Flex>
            </Content>
            <ButtonGroup>
                <Button
                    variant="secondary"
                    onPress={() => toggleRenameDialog(false)}>
                    {t("actions.cancel")}
                </Button>
                <Button
                    type="submit"
                    variant="accent"
                    onPress={() => {
                        handleRename();
                    }}
                    isDisabled={
                        targetName === "" || targetName === displayName
                    }>
                    {t("actions.save")}
                </Button>
            </ButtonGroup>
        </Dialog>
    );
}
