/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Flex,
    TextField,
    View,
} from "@adobe/react-spectrum";
import { StudioTool } from "@components/studio";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AssetTechInfoView } from "./AssetTechInfoView";
import { ProgressCircleView } from "./ProgressCircleView";
import { StudioView } from "./StudioView";
import {
    AssetTechInfo,
    getAssetTechInfo,
    updateTechInfoFromViewer,
} from "../util/HealthCheckUtils";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useRedirects } from "@src/hooks/useRedirects";

import type { AdobeViewer } from "@3di/adobe-3d-viewer";

interface UploadPreviewDialogProps {
    assetId: string;
    assetName: string;
    setAssetName: (assetName: string) => void;
    confirmHandler: () => void;
}

async function getFileData(file: File, originalFormat?: string) {
    const techInfo = await getAssetTechInfo(file, originalFormat);
    const data: AssetFileData = {
        name: file.name,
        url: URL.createObjectURL(file),
        file,
        techInfo,
    };
    return data;
}

type AssetFileData = {
    name?: string;
    url?: string;
    urn?: string;
    file: File;
    techInfo: AssetTechInfo | undefined;
};

export function PreviewDialog({
    assetId,
    assetName,
    setAssetName,
    confirmHandler,
}: UploadPreviewDialogProps) {
    const { t } = useTranslation(["common", "web"]);
    const { homeRedirect } = useRedirects();
    const {
        useReviewListItem,
        useGlbUrl,
        useMutateEnvironmentMeta,
        useOriginalFileFormat,
    } = useAcpContext();

    const { data: glbUrl } = useGlbUrl(assetId);
    const { data: meta } = useReviewListItem(assetId);
    const { mutateAsync } = useMutateEnvironmentMeta(assetId);
    const { data: originalFormat } = useOriginalFileFormat(assetId);

    const [previewFileData, setPreviewFileData] = useState<AssetFileData>();

    function goBack() {
        mutateAsync({
            physicalSize: undefined,
            environment: undefined,
            pedestal: undefined,
        });
        homeRedirect(true);
    }

    function confirm() {
        confirmHandler();
    }

    useEffect(() => {
        if (glbUrl && originalFormat) {
            (async () => {
                const glb = await fetch(glbUrl);
                const file = new File(
                    [await glb.blob()],
                    meta?.displayName || "",
                );
                const fileData = await getFileData(file, originalFormat);
                setPreviewFileData(fileData);
            })();
        }
    }, [glbUrl, originalFormat]);

    const [viewerInstance, setViewerInstance] = useState<
        AdobeViewer | undefined
    >();
    const [sceneInstance, setSceneInstance] = useState<any>();

    useEffect(() => {
        if (
            !(
                viewerInstance &&
                sceneInstance &&
                previewFileData &&
                assetId &&
                glbUrl
            )
        )
            return;
        if (!previewFileData.techInfo) return;

        const oldValue = previewFileData.techInfo.physicalSize.value.toString();

        const updatedTechInfo = updateTechInfoFromViewer(
            viewerInstance,
            sceneInstance,
            previewFileData.techInfo,
        );

        if (
            updatedTechInfo?.physicalSize.value.toString() !==
            oldValue
        ) {
            console.log("updating tech info");
            mutateAsync({
                physicalSize: JSON.stringify(
                    updatedTechInfo?.physicalSize.value,
                ),
            });
            setPreviewFileData({
                ...previewFileData,
                techInfo: updatedTechInfo,
            });
        }
    }, [viewerInstance, sceneInstance, assetId, glbUrl, previewFileData]);

    return (
        <View width="80vw" height="80vh" backgroundColor="gray-100">
            {previewFileData ? (
                <Flex direction="row" width="100%" height="100%">
                    <View width="65%">
                        <StudioView
                            modelUrl={previewFileData.url}
                            editorTools={[
                                StudioTool.cameraControls,
                                StudioTool.frameButton,
                            ]}
                            setSceneInstance={setSceneInstance}
                            setViewerInstance={setViewerInstance}
                        />
                    </View>
                    <Flex
                        width="35%"
                        direction="column"
                        UNSAFE_style={{
                            padding:
                                "var(--spectrum-global-dimension-size-250)",
                            overflow: "auto",
                        }}>
                        <Flex
                            direction="column"
                            justifyContent="space-between"
                            height="100%">
                            <View>
                                <TextField
                                    defaultValue={assetName}
                                    onChange={setAssetName}
                                />
                                <AssetTechInfoView
                                    techInfo={previewFileData.techInfo}
                                />
                            </View>
                            <Flex
                                direction="column"
                                justifyContent="end"
                                alignItems="end">
                                <ButtonGroup marginBottom="size-200">
                                    <Button
                                        variant="secondary"
                                        onPress={goBack}>
                                        {t("common:actions.cancel")}
                                    </Button>
                                    <Button
                                        variant="accent"
                                        onPress={confirm}
                                        autoFocus>
                                        {t("web:actions.next")}
                                    </Button>
                                </ButtonGroup>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            ) : (
                <ProgressCircleView />
            )}
        </View>
    );
}
