/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { AdobeViewer } from "@3di/adobe-3d-viewer";
import { ToastQueue } from "@react-spectrum/toast";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { HI5_API_KEY, IMS_ENV } from "@src/config";
import { useNetworkContext } from "@src/contexts/NetworkContext";
import { PinningSession } from "@src/scene/PinningSession";

export function useNetworking(
    pinningSession: PinningSession | undefined,
    assetId: string,
    avatarUrl: string,
    displayName: string,
    accessToken: string | undefined,
    viewer: AdobeViewer | undefined,
    isJoinReviewReady: boolean = true,
) {
    const { networkManager, voiceManager } = useNetworkContext();
    const { t } = useTranslation("web");

    const [users, setUsers] = useState<Set<string>>(new Set());

    const [realtimeStatus, setRealtimeStatus] = useState<string | undefined>();
    const [inVoiceRoom, setInVoiceRoom] = useState(false);

    const [authParameters, setAuthParameters] = useState("");
    const authType =
        Photon.LoadBalancing.Constants.CustomAuthenticationType.Custom;

    const addUser = (actorName: string) => {
        // if user is not in list, add user to list
        if (!users.has(actorName)) {
            setUsers((prev) => new Set([...prev, actorName]));
        }
    };

    const removeUser = (actorName: string) => {
        setUsers(
            (prev) => new Set([...prev].filter((user) => user !== actorName)),
        );
    };

    // auths + connects to servers and voice room
    useEffect(() => {
        if (
            voiceManager &&
            !voiceManager.isJoinedRoom() &&
            realtimeStatus === "Joined" &&
            !inVoiceRoom
        ) {
            Photon.setOnLoad(() => {
                try {
                    // only auth in prod env
                    if (IMS_ENV === "prod") {
                        voiceManager.setCustomAuthentication(
                            authParameters,
                            authType,
                        );
                    }

                    voiceManager.initializeVoiceRoom(
                        assetId + "_voice",
                        displayName,
                    );

                    setInVoiceRoom(true);
                } catch (error) {
                    console.error("Error initializing voice:", error);
                }
            });
        }
    }, [voiceManager, realtimeStatus, inVoiceRoom]);

    useEffect(() => {
        if (accessToken) {
            setAuthParameters(
                `user_token=${accessToken}&api_key=${HI5_API_KEY}`,
            );
        }
    }, [accessToken]);

    // connect to realtime room
    useEffect(() => {
        if (!networkManager || !viewer || !isJoinReviewReady || !authParameters || !pinningSession)
            return;

        const initRealtimeConnection = async () => {
            try {
                if (IMS_ENV === "prod") {
                    networkManager.setCustomAuthentication(
                        authParameters,
                        authType,
                    );
                }
                await networkManager.joinReviewRoom(
                    pinningSession,
                    assetId,
                    viewer,
                    displayName,
                    avatarUrl,
                );
            } catch (error) {
                console.error("Error initializing realtime:", error);
            }
        };

        initRealtimeConnection();

        return () => {
            if (voiceManager) {
                voiceManager.leaveRoom();
            }
            if (networkManager) {
                networkManager.stopConnection();
            }
        };
    }, [viewer, isJoinReviewReady, authParameters, pinningSession]);

    useEffect(() => {
        const handleStateChange = () => {
            setRealtimeStatus(networkManager?.updateStatus());
        };
        if (networkManager) {
            networkManager.onStateChange = handleStateChange;
        }
    }, [networkManager]);

    useEffect(() => {
        const handleOnJoinRoom = () => {
            // populate users list with current users in room
            if (networkManager) {
                networkManager
                    .myRoomActorsArray()
                    .forEach((actor: Photon.LoadBalancing.Actor) => {
                        const displayName =
                            actor.getCustomProperty("displayName");
                        if (displayName) {
                            addUser(displayName);
                        }
                    });
                viewer?.renderLoop.toggle(500);
            }
        };

        window.addEventListener("onJoinRoom", handleOnJoinRoom);

        return () => {
            window.removeEventListener("onJoinRoom", handleOnJoinRoom);
        };
    }, [networkManager]);

    // listen for when actors join or leave
    useEffect(() => {
        if (!networkManager || !voiceManager) return;
        const handleOnActorJoin = (event: any) => {
            const displayName = event.detail.displayName;
            if (displayName) {
                addUser(displayName);

                ToastQueue.info(
                    `${t("web:toast.review.userJoined", { userName: displayName })}`,
                    {
                        timeout: 5000,
                    },
                );
            }
        };

        const handleOnActorLeave = (event: any) => {
            //get the actor that left and remove the color assigned to them
            const displayName = event.detail.displayName;
            if (displayName) {
                removeUser(event.detail.displayName);

                ToastQueue.info(
                    `${t("web:toast.review.userLeft", { userName: displayName })}`,
                    {
                        timeout: 5000,
                    },
                );
            }
            viewer?.renderLoop.toggle(500);
        };

        window.addEventListener("onActorJoin", handleOnActorJoin);
        window.addEventListener("onActorLeave", handleOnActorLeave);

        return () => {
            window.removeEventListener("onActorJoin", handleOnActorJoin);
            window.removeEventListener("onActorLeave", handleOnActorLeave);
        };
    }, [networkManager, voiceManager]);

    // detect AFK status based on window visibility
    useEffect(() => {
        if (!networkManager) return;
        const handleVisibilityChange = () => {
            networkManager.setIsIdleFlag(document.hidden);
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
        };
    }, [networkManager]);

    // detect AFK status based on mouse movement (idle after 2 mins of inactivity)
    useEffect(() => {
        if (!networkManager) return;
        let timeout: Timer;

        const handleIdleStatusChange = () => {
            networkManager.setIsIdleFlag(false);

            clearTimeout(timeout);
            timeout = setTimeout(() => {
                networkManager.setIsIdleFlag(true);
            }, 120000);
        };

        document.addEventListener("mousemove", handleIdleStatusChange);

        return () => {
            document.removeEventListener("mousemove", handleIdleStatusChange);
        };
    }, [networkManager]);

    useEffect(() => {
        const handleOnAssetReset = (event: any) => {
            ToastQueue.info(
                `${t("toast.review.userResetObject", { userName: event.detail.name })}`,
                {
                    timeout: 5000,
                },
            );
        };

        window.addEventListener("onAssetReset", handleOnAssetReset);
        return () => {
            window.removeEventListener("onAssetReset", handleOnAssetReset);
        };
    }, []);

    return { users };
}
