/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

import { HI5_API_KEY } from "../config";
import { SHARE_SHEET_SHARE_ID } from "../types/CCACData";

import type { AnalyticsData, ShareSheetAssetData } from "../types/CCACData";

export interface ImsData {
    id: string;
    token: string;
    email?: string;
    name?: string;
}

const SHARE_SHEET_ANALYTICS_SERVICE = {
    event: {
        category: "WEB",
        device_guid: "",
    },
};

const SHARE_SHEET_ANALYTICS_SOURCE = {
    source: {
        client_id: HI5_API_KEY,
        name: "HI5", // TODO: placeholder - replace with real SAP code once registered
        os_version: "",
        platform: "web",
        version: "0", // add app version to config
    },
};

/**
 * Hook for setting share-for-review for the given asset
 * @param imsData: CCACImsData - The ims data for the logged in user
 * @param assetId - The model asset id or the project asset id for the share sheet component.
 * @param assetName - Name of the asset
 * @param requiresAnalytics - If true setup additional data require for Adobe analytics. The default is false.
 * @returns { shareAssetData: ShareSheetAssetData, shareSheetDialogId: string }
 *
 */
export const useShareSheet = (
    assetId: string | null,
    assetName: string,
    imsData?: ImsData,
) => {
    const [analyticsData, setAnalyticsData] = useState<AnalyticsData>();
    const [shareAssetData, setShareAssetData] = useState<ShareSheetAssetData>();
    const [shareSheetDialogId, setShareSheetDialogId] = useState<string>("");

    useEffect(() => {
        if (assetId && imsData && shareAssetData?.id !== assetId) {
            const assetData: ShareSheetAssetData = {
                id: assetId as string,
                name: assetName,
                useProductViewUrl: true,
            };
            setShareAssetData(assetData);
            setShareSheetDialogId(SHARE_SHEET_SHARE_ID);
            setAnalyticsData({
                ...SHARE_SHEET_ANALYTICS_SERVICE,
                ...SHARE_SHEET_ANALYTICS_SOURCE,
            } as AnalyticsData);
        }
    }, [assetId, imsData]);

    useEffect(() => {
        if (assetName && shareAssetData?.name !== assetName) {
            const assetData: ShareSheetAssetData = {
                id: assetId as string,
                name: assetName,
                useProductViewUrl: true,
            };
            setShareAssetData(assetData);
        }
    }, [assetName]);

    return { analyticsData, shareAssetData, shareSheetDialogId };
};
