/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Text,
    Image,
    View,
    Link as LinkSpectrum,
} from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { usePostMessageContext } from "../../contexts/PostMessageContext";
import logo from "../../images/adobe_logo_splash.png";
import appIcon from "../../images/rw_appicon_noshadow_128.png";

export function AboutHighFive() {
    const { t } = useTranslation("common");
    const { postMessageBusApi } = usePostMessageContext();


    return (
        <View UNSAFE_style={
            {
                backgroundColor: "#EBEBEB",
            }
        }>
            <Flex direction="row" alignItems="start" gap="20px">
                <Image
                    width="30px"
                    height="30px"
                    minWidth="60px"
                    minHeight="60px"
                    alt="highfive app icon"
                    src={appIcon}
                    UNSAFE_style={{
                        borderRadius: "8px",
                        paddingLeft: "20px",
                        paddingTop: "20px",
                    }}
                />
                <Flex direction="column" alignItems="start">
                    <Text
                        UNSAFE_style={{
                            color: "black",
                            fontSize: "2.56em",
                            paddingTop: "25px",
                            fontWeight: "bold",
                        }}>
                        {t("about.h5.name")}
                    </Text>
                    <Text
                        UNSAFE_style={{
                            color: "black",
                            fontSize: "1.25em",
                            paddingTop: "10px",
                        }}>
                        {t("about.h5.version")}
                    </Text>
                </Flex>
            </Flex>
            <Flex direction="column" alignItems="start" gap="5px">
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                        paddingTop: "20px",
                    }}>
                    {t("about.h5.build")}
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                        paddingTop: "15px",
                    }}>
                    {t("about.h5.core.team")}
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                    }}>
                    {t("about.h5.extended.team")}
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                    }}>
                    {t("about.h5.prototyping")}
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                    }}>
                    {t("about.h5.interns")}
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                    }}>
                    {t("about.h5.special.thanks")}
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                    }}>
                    {t("about.h5.support")}
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                    }}>
                    {t("about.h5.alumni")}
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                        paddingTop: "10px",
                    }}>
                    {t("about.h5.splash.screen")}
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                        paddingTop: "15px",
                    }}>
                    {t("about.h5.copyright")}
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                    }}>
                    {t("about.h5.software.contains")}
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                    }}>
                    {t("about.h5.program.written")}
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                    }}>
                    {t("about.h5.portions.utilize")}
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                    }}>
                    {t("about.h5.third.party")}{" "}
                    <LinkSpectrum
                        onPress={() => {
                            if (postMessageBusApi) {
                                postMessageBusApi.interceptLink(
                                    "https://www.adobe.com/go/thirdparty",
                                );
                            }
                        }}>
                        {"https://www.adobe.com/go/thirdparty"}
                    </LinkSpectrum>
                </Text>
            </Flex>
            <Flex direction="row" alignItems="start" gap="5px">
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                        paddingTop: "10px",
                    }}>
                    {
                        <LinkSpectrum
                            onPress={() => {
                                if (postMessageBusApi) {
                                    postMessageBusApi.interceptLink(
                                        "https://www.adobe.com/legal/terms.html",
                                    );
                                }
                            }}>
                            {t("about.h5.general.terms")}
                        </LinkSpectrum>
                    }
                </Text>
                <Text
                    UNSAFE_style={{
                        color: "black",
                        fontSize: "1.25em",
                        paddingLeft: "25px",
                        paddingTop: "10px",
                    }}>
                    {
                        <LinkSpectrum
                            onPress={() => {
                                if (postMessageBusApi) {
                                    postMessageBusApi.interceptLink(
                                        "https://www.adobe.com/privacy.html",
                                    );
                                }
                            }}>
                            {t("about.h5.privacy.policy")}
                        </LinkSpectrum>
                    }
                </Text>
                <Image
                    width="130px"
                    height="130px"
                    alt="Adobe Logo"
                    src={logo}
                    UNSAFE_style={{
                        paddingLeft: "220px",
                    }}
                />
            </Flex>
        </View>
    );
}
