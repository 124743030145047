/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Text, View } from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { useMessagingContext } from "@shared/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { AssetTabsView } from "../components/AssetTabsView";
import { AssetUploadView } from "../components/AssetUploadView";
import { useLocaleContext } from "../contexts/LocaleContext";
import { EventType, useAnalytics } from "../hooks/useAnalytics";
import { AppHeader } from "@src/components/AppHeader";
import { EnterReviewDialog } from "@src/components/EnterReviewDialog";
import { EnvironmentDialog } from "@src/components/EnvironmentDialog";
import { PreviewDialog } from "@src/components/PreviewDialog";
import { ShareDialog } from "@src/components/ShareDialog";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useRedirects } from "@src/hooks/useRedirects";

export function Home() {
    const { t } = useTranslation(["common", "web"]);
    const { modal, assetId: urlAssetId } = useParams();
    const { locale } = useLocaleContext();
    const { userProfile, imsReady } = useHi5UserContext();

    const { homeModalRedirect, reviewRedirect, homeRedirect } = useRedirects();

    const { acpClient, useReviewListItem, getEnvironmentMeta } = useAcpContext();

    const { data: reviewItem } = useReviewListItem(urlAssetId || "");
    console.log("reviewItem", reviewItem);

    const { sendLaunchAnalytics } = useAnalytics();

    const { showModal, closeModal } = useMessagingContext();
    const [assetName, setAssetName] = useState<string>("");

    useEffect(() => {
        if (imsReady && userProfile == undefined) {
            sendLaunchAnalytics(EventType.success, window.location.pathname);
        }
    }, [imsReady]);

    useEffect(() => {
        if (reviewItem)
            setAssetName(reviewItem?.displayName)
    }, [reviewItem])

    async function onAssetClick(assetId: string) {
        const envMeta = await getEnvironmentMeta(assetId);
        if (!envMeta?.physicalSize) {
            homeModalRedirect("preview", assetId);
        } else if (!envMeta.environment) {
            homeModalRedirect("environment", assetId);
        } else {
            closeModal();
            homeModalRedirect("enter", assetId);
        }
    }

    async function previewUpload(assetId: string) {
        homeModalRedirect("preview", assetId);
    }

    async function handleRename() {
        if (urlAssetId && reviewItem && reviewItem.displayName !== assetName) {
            const isSuccessful = await acpClient.renameComposite(urlAssetId, assetName)
            if (isSuccessful) {
                ToastQueue.positive(t("toast.rename.successful"), {
                    timeout: 5000,
                });
            } else {
                ToastQueue.negative(t("toast.rename.fail"), { timeout: 5000 });
                setAssetName(reviewItem.displayName);
            }
        }
    }

    useEffect(() => {
        console.log("assetName", assetName)
        if (modal === "preview" && urlAssetId) {
            showModal(
                <PreviewDialog
                    assetId={urlAssetId}
                    assetName={assetName}
                    setAssetName={setAssetName}
                    confirmHandler={() =>
                        homeModalRedirect("environment", urlAssetId)
                    }
                />,
            );
        }
        if (modal === "environment" && urlAssetId) {
            showModal(
                <EnvironmentDialog
                    assetId={urlAssetId}
                    assetName={assetName}
                    setAssetName={setAssetName}
                    confirmHandler={() =>
                        homeModalRedirect("share", urlAssetId)
                    }
                />,
            );
        }
        if (modal === "share" && urlAssetId) {
            showModal(
                <ShareDialog
                    assetId={urlAssetId}
                    assetName={assetName}
                    setAssetName={setAssetName}
                    confirmHandler={async () => {
                        await handleRename();
                        homeRedirect(true);
                        reviewRedirect(urlAssetId);
                    }}
                />,
            );
        }
        if (modal === "enter") {
            showModal(
                <EnterReviewDialog
                    assetId={urlAssetId}
                    assetName={assetName}
                />,
            );
        }
        if (!modal) {
            closeModal();
        }
        return () => {
            if (modal) {
                closeModal();
            }
        };
    }, [modal, urlAssetId, assetName]);

    return (
        <View height="100vh">
            <Flex direction="column" gap="size-250">
                <AppHeader />
                <View paddingX="4vw">
                    <View marginBottom="1vh">
                        <Text
                            UNSAFE_style={{
                                fontSize: "1.75em",
                                fontWeight: 500,
                            }}>
                            {userProfile
                                ? `${t("web:home.welcome")}, ${
                                      userProfile.displayName
                                  }`
                                : `${t("web:home.welcome")}`}
                        </Text>
                    </View>
                    <AssetUploadView onComplete={previewUpload} />
                </View>
                <View
                    backgroundColor="gray-100"
                    paddingX="4vw"
                    paddingBottom="1vh">
                    <AssetTabsView
                        onAssetClick={onAssetClick}
                        isForVr={false}
                        locale={locale}
                    />
                </View>
            </Flex>
        </View>
    );
}
