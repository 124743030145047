/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import studio_black_soft_light_02 from "./ibl/studio_black_soft_light_02.env";

import type { AdobeViewerConfiguration } from "@3di/adobe-3d-viewer/dist/config";

export const viewerConfig: AdobeViewerConfiguration = {
    autoStopRendering: true,
    extends: "minimal",
    engine: {
        antialiasing: true,
        engineOptions: {
            preserveDrawingBuffer: true,
            adaptToDeviceRatio: true,
            antialias: true,
        },
    },
    autoCreateSunlight: false,
    assetsBaseUrl: "",
    defaultEnvironmentPath: "",
    iblUrl: studio_black_soft_light_02,
    unnormalized: true,
};
