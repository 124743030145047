/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

// @ts-ignore
import "../lib/photon/photon-voice.js";

import { AdobeViewer } from "@3di/adobe-3d-viewer";
import { PointerEventTypes } from "@babylonjs/core/Events/pointerEvents.js";
import { Scene } from "@babylonjs/core/scene.js";
import { ToastQueue } from "@react-spectrum/toast";


import { AvatarsManager } from "./AvatarsManager";
import { ObjectManager } from "./ObjectManager";
import {
    PHOTON_CONFIG,
    PHOTON_MAX_PLAYERS,
    PHOTON_PLAYER_TTL,
    PHOTON_REALTIME_APPID,
} from "@src/config";
import { PinningSession } from "@src/scene/PinningSession.js";
import { throttleAnimation } from "@src/util/AnimationThrottleUtils.js";
import type {
    NetworkAssetData,
    Vector3 as NetworkVector3,
    NetworkTeleportData,
} from "@src/util/NetworkDataUtils";
import {
    HeartbeatData,
    INTEREST_GROUPS,
    NetworkPlayerHighFreqData,
    NetworkPlayerLowFreqData,
    PlayerStateFlags,
    packHeartbeatData,
    packHighFreqNetworkAssetData,
    packHighFreqNetworkPlayerData,
    packLowFreqNetworkPlayerData,
    setPlayerStateFlag,
    unpackHeartbeatData,
    unpackHighFreqNetworkPlayerData,
    unpackLowFreqNetworkPlayerData,
    unpackHighFreqNetworkAssetData,
    packNetworkTeleportData,
    unpackNetworkTeleportData,
} from "@src/util/NetworkDataUtils";

import type { TFunction } from "i18next";

// const HIGH_FREQUENCY_INTERVAL = 33;
const HEARTBEAT_INTERVAL = 1000;

enum EVENT_CODES {
    HEARTBEAT_DATA = 0,
    PLAYER_STATE_FLAGS = 1,
    PLAYER_HIGH_FREQUENCY_DATA = 2,
    ASSET_HIGH_FREQUENCY_DATA = 3,
    LASER_POINTER_HIGH_FREQUENCY_DATA = 4,
    // 5 is reserved for META_AVATAR_DATA
    REQUEST_ASSET_CONTROL = 6,
    RELEASE_ASSET_CONTROL = 7,
    RESET_ASSET = 8,
    TELEPORT = 9,
}

const LBC = Photon.LoadBalancing.LoadBalancingClient;

export class NetworkManager extends Photon.LoadBalancing.LoadBalancingClient {
    private ticker: Timer | undefined = undefined;
    private heartbeatTicker: Timer | undefined = undefined;

    private assetOwner: number = 0;
    // player number and state flags
    private playerData: Record<number, number> = {};
    // state flags for local player
    private playerStateFlags: number = 0;

    private lastHeartbeatUpdateTime: number = 0;
    private lastLowFreqPlayerUpdateTime: number = 0;
    private lastHighFreqPlayerUpdateTime: number = 0;
    private lastAssetUpdateTime: number = 0;

    avatarsManager: AvatarsManager | undefined;
    private objectManager: ObjectManager | undefined;
    private pinningSession: PinningSession | undefined;

    private rooms: Photon.LoadBalancing.RoomInfo[] = [];

    private usersInTeleport: Set<number> = new Set();

    private t: TFunction<"web">;

    constructor(t: TFunction) {
        super(
            PHOTON_CONFIG.Wss
                ? Photon.ConnectionProtocol.Wss
                : Photon.ConnectionProtocol.Ws,
            PHOTON_REALTIME_APPID,
            PHOTON_CONFIG.AppVersion,
        );
        this.t = t;
    }

    startConnection(lobbyID: string) {
        return new Promise<void>((resolve, reject) => {
            if (PHOTON_CONFIG.NameServer) {
                this.setNameServerAddress(PHOTON_CONFIG.NameServer);
            }

            if (this.isJoinedLobby()) {
                this.disconnect();
            }

            const options = {
                region: PHOTON_CONFIG.Region,
                lobbyName: lobbyID,
                lobbyStats: true,
            };
            this.connectToNameServer(options);

            // join room when joined to lobby successfully
            const checkJoinedLobby = () => {
                if (this.isJoinedLobby()) {
                    this.requestLobbyStats();
                    clearInterval(checkInterval);
                    resolve();
                } else if (this.isJoinedError()) {
                    clearInterval(checkInterval);
                    console.error("Running is joined error");
                    reject(new Error("Connection to realtime failed"));
                }
            };

            const checkInterval = setInterval(checkJoinedLobby, 1000);
            setTimeout(() => {
                clearInterval(checkInterval);

                reject(new Error("Timeout while joining realtime lobby"));
            }, 30000);
        });
    }

    async joinReviewRoom(
        pinningSession: PinningSession,
        roomName: string,
        viewer: AdobeViewer,
        displayName?: string,
        avatarUrl?: string,
    ) {
        if (!this.isJoinedLobby()) {
            console.log("Not joined to lobby, starting connection");
            await this.startConnection(roomName);
        }
        this.avatarsManager = new AvatarsManager(viewer);
        this.objectManager = new ObjectManager(viewer, pinningSession);
        const scene = viewer.sceneManager.scene;
        this.pinningSession = pinningSession;

        let avatarUrlUpdated = avatarUrl;
        if (avatarUrl) {
            const resIndex = avatarUrl.lastIndexOf("/");
            // get 276 resolution profile picture
            const res = avatarUrl.substring(resIndex + 1);
            if (res !== "276") {
                avatarUrl = avatarUrl.substring(0, resIndex + 1).concat("276");
            }
            avatarUrlUpdated = avatarUrl;
        }

        try {
            this.joinRoom(
                roomName,
                { createIfNotExists: true },
                {
                    isVisible: true,
                    isOpen: true,
                    maxPlayers: PHOTON_MAX_PLAYERS,
                    playerTTL: PHOTON_PLAYER_TTL,
                    lobbyName: roomName,
                },
            );
            this.updatePlayerInfo(displayName ?? "", avatarUrlUpdated ?? "");
            this.changeGroups([INTEREST_GROUPS.VR], [INTEREST_GROUPS.WEB]);

            this.heartbeatTicker = setInterval(() => {
                this.heartbeatTick();
            }, HEARTBEAT_INTERVAL);

            throttleAnimation(
                scene.cameras[0].onViewMatrixChangedObservable,
                () => {
                    this.sendPlayerData();
                    if (this.avatarsManager) {
                        // scale avatars
                        const avatars: Record<number, any> =
                            this.avatarsManager.getAvatarMap() ?? [];
                        if (avatars) {
                            for (const actorNr in avatars) {
                                const avatar = avatars[actorNr];
                                avatar.position =
                                    this.avatarsManager.getRemoteAvatar(
                                        actorNr as any,
                                    ).position;
                                this.avatarsManager.scaleAvatar(avatar);
                                this.avatarsManager.updateOffscreenVisual(
                                    avatar,
                                );
                            }
                        }
                    }

                    // scale reset button
                    if (this.objectManager?.isResetVisible) {
                        this.objectManager?.scaleButton();
                    }
                },
            );

            this.setupGizmoObservables();
            this.setupPointerEvents(scene);
        } catch (error) {
            console.error("Failed to initialize realtime room: ", error);
        }
    }

    setupGizmoObservables() {
        if (!this.objectManager) return;

        const positionGizmo =
            this.objectManager.gizmoManager.gizmos.positionGizmo;
        const rotationGizmo =
            this.objectManager.gizmoManager.gizmos.rotationGizmo;

        if (!positionGizmo || !rotationGizmo) return;

        positionGizmo.onDragStartObservable.add(() => {
            this.sendRequestAssetControl();
            if (!this.objectManager) return;
            this.objectManager.toggleResetVisibility(true);
            this.objectManager.toggleResetDisabled(true);
            this.objectManager.previousPosition = this.objectManager
                .getRootMesh()
                .position.clone();
        });

        throttleAnimation(positionGizmo.onDragObservable, () => {
            if (!this.objectManager) return;
            if (this.assetOwner === this.myActor().actorNr) {
                if (
                    this.objectManager.isCollidingEnv() ||
                    this.objectManager.isCollidingPedestal()
                ) {
                    this.objectManager.getRootMesh().position =
                        this.objectManager.previousPosition.clone();
                } else {
                    this.objectManager.previousPosition = this.objectManager
                        .getRootMesh()
                        .position.clone();
                }

                this.sendAssetData();
                this.objectManager.autoReset(() => {
                    this.raiseEvent(EVENT_CODES.RESET_ASSET);
                    this.sendResetAssetWindowEvent(this.myActor().name);
                });
            } else if (this.assetOwner !== 0) {
                this.objectManager.disableGizmos();
            }
        });

        positionGizmo.onDragEndObservable.add(() => {
            this.objectManager?.toggleResetDisabled(false);
            this.sendReleaseAssetControl();
        });

        rotationGizmo.onDragStartObservable.add(() => {
            this.sendRequestAssetControl();
            if (!this.objectManager) return;
            this.objectManager.toggleResetVisibility(true);
            this.objectManager.toggleResetDisabled(true);
            this.objectManager.previousRotation = this.objectManager
                .getRootMesh()
                .rotation.clone();
        });

        throttleAnimation(rotationGizmo.onDragObservable, () => {
            if (!this.objectManager) return;
            if (this.assetOwner === this.myActor().actorNr) {
                this.objectManager.getRootMesh().computeWorldMatrix(true);
                if (
                    !this.objectManager.isCollidingEnv() ||
                    !this.objectManager.isCollidingPedestal()
                ) {
                    this.objectManager.getRootMesh().rotation =
                        this.objectManager.previousRotation.clone();
                } else {
                    this.objectManager.previousRotation = this.objectManager
                        .getRootMesh()
                        .rotation.clone();
                }

                this.sendAssetData();
            } else if (this.assetOwner !== 0) {
                this.objectManager.disableGizmos();
            }
        });

        rotationGizmo.onDragEndObservable.add(() => {
            this.objectManager?.toggleResetDisabled(false);
            this.sendReleaseAssetControl();
        });
    }

    setupPointerEvents(scene: Scene) {
        scene.onPointerObservable.add((e: any) => {
            if (this.objectManager?.isResetDisabled) return;
            switch (e.type) {
                case PointerEventTypes.POINTERMOVE:
                    this.objectManager?.handlePointerMove();
                    break;
                case PointerEventTypes.POINTERDOWN:
                    if (this.objectManager) {
                        this.objectManager.handlePickResetStart(() => {
                            this.raiseEvent(EVENT_CODES.RESET_ASSET, null);
                            this.sendResetAssetWindowEvent(this.myActor().name);
                        });

                        this.objectManager.handlePickMesh();
                    }
                    break;
                case PointerEventTypes.POINTERUP:
                    this.objectManager?.handlePickResetStop();
                    break;
            }
        });
    }

    stopConnection() {
        clearTimeout(this.ticker);
        clearTimeout(this.heartbeatTicker);
        this.leaveRoom();
        this.disconnect();
    }

    heartbeatTick() {
        if (!this.isJoinedToRoom()) return;
        // only the host sends heartbeat data
        if (this.isHost()) {
            this.sendHeartbeatData();
        }
    }

    isJoinedLobby(): boolean {
        return this.state() == LBC.State.JoinedLobby;
    }

    isJoinedError(): boolean {
        return this.state() == LBC.State.Error;
    }

    isConnectedToNameServer(): boolean {
        return this.state() == LBC.State.ConnectedToNameServer;
    }

    updateStatus() {
        // updates status
        var statusText = LBC.StateToName(this.state());
        return statusText;
    }

    setIsIdleFlag(isIdle: boolean) {
        this.playerStateFlags = setPlayerStateFlag(
            this.playerStateFlags,
            PlayerStateFlags.isIdle,
            isIdle === true ? 1 : 0,
        );
        this.sendPlayerStateFlags();
    }

    handleIsIdleFlag(playerStateFlags: number, actorNr: number) {
        const isIdle =
            (playerStateFlags & PlayerStateFlags.isIdle) === 0 ? false : true;
        // update nameplate visual
        if (actorNr !== this.myActor().actorNr) {
            this.avatarsManager?.updateIsIdleState(actorNr, isIdle);
        }

        // dispatch event for presence bubbles
        const handleIsIdleEvent = new CustomEvent("isIdle", {
            detail: { actorNr, isIdle },
        });
        window.dispatchEvent(handleIsIdleEvent);
    }

    /**
     * @summary sets isMuted flag for the local player and sends the updated state flags to all other players
     *
     * @param isMuted
     */
    setIsMutedFlag(isMuted: boolean) {
        this.playerStateFlags = setPlayerStateFlag(
            this.playerStateFlags,
            PlayerStateFlags.isMuted,
            isMuted === true ? 1 : 0,
        );
        this.sendPlayerStateFlags();
    }

    handleIsMutedFlag(playerStateFlags: number, actorNr: number) {
        const isMuted =
            (playerStateFlags & PlayerStateFlags.isMuted) === 0 ? false : true;
        // update nameplate visual
        if (actorNr !== this.myActor().actorNr) {
            this.avatarsManager?.updateIsMutedState(actorNr, isMuted);
        }

        // dispatch event for presence bubbles
        const handleIsIdleEvent = new CustomEvent("isMuted", {
            detail: { actorNr, isMuted },
        });
        window.dispatchEvent(handleIsIdleEvent);
    }

    /**
     * @summary sets isTalking flag for the local player and sends the updated state flags to all other players
     *
     * @param isTalking
     */
    setIsTalkingFlag(isTalking: boolean) {
        this.playerStateFlags = setPlayerStateFlag(
            this.playerStateFlags,
            PlayerStateFlags.isTalking,
            isTalking === true ? 1 : 0,
        );
        this.sendPlayerStateFlags();
    }

    /**
     * @summary handles updates to the isTalking flag for a player:
     * 1. updates nameplate visual
     * 2. dispatches window event to presence bubbles component
     *
     * @param playerStateFlags
     * @param actorNr
     */
    handleIsTalkingFlag(playerStateFlags: number, actorNr: number) {
        const isTalking =
            (playerStateFlags & PlayerStateFlags.isTalking) === 0
                ? false
                : true;

        // update nameplate visual
        if (actorNr !== this.myActor().actorNr) {
            this.avatarsManager?.updateIsTalkingState(actorNr, isTalking);
        }

        // dispatch event for presence bubbles
        const handleIsTalkingEvent = new CustomEvent("isTalking", {
            detail: { actorNr, isTalking },
        });
        window.dispatchEvent(handleIsTalkingEvent);
    }

    sendHeartbeatData() {
        this.playerData[this.myActor().actorNr] = this.playerStateFlags;
        const heartbeatData: HeartbeatData = {
            assetOwner: this.assetOwner,
            playerCount: this.myRoomActorCount(),
            playerData: this.playerData,
        };

        const packedHeartbeatData = packHeartbeatData(heartbeatData);
        this.raiseEvent(EVENT_CODES.HEARTBEAT_DATA, packedHeartbeatData);
    }

    receiveHeartbeatData(rawData: number[]) {
        const heartbeatData = unpackHeartbeatData(
            rawData,
            this.lastHeartbeatUpdateTime,
        );
        this.lastHeartbeatUpdateTime = heartbeatData?.time ?? 0;

        if (!this.objectManager) {
            console.error("Object manager not initialized");
            return;
        }

        // update asset owner
        if (this.assetOwner != heartbeatData?.assetOwner) {
            if (
                heartbeatData?.assetOwner == 0 ||
                heartbeatData?.assetOwner == undefined
            ) {
                // if nobody owns the asset anybody can reset it
                this.onAssetLockoutEnd();
            } else {
                this.onAssetLockoutStart(heartbeatData?.assetOwner);
            }
        }

        this.assetOwner = heartbeatData?.assetOwner ?? 0;

        if (!heartbeatData?.playerCount || !heartbeatData?.playerData) return;

        this.playerData = heartbeatData.playerData;

        for (const playerNumber in this.playerData) {
            const actorNr = parseInt(playerNumber);
            const actor = this.myRoomActorsArray().find(
                (actor) => actor.actorNr === actorNr,
            );
            // check if player has avatar
            if (!this.avatarsManager?.hasAvatar(actorNr)) {
                // spawn new avatar for actor
                if (actor && !actor.isLocal) {
                    const { avatarUrl, color } = actor.getCustomProperties();
                    const displayName = actor.name;
                    this.avatarsManager?.spawnAvatar(
                        actorNr,
                        displayName,
                        avatarUrl,
                        color,
                    );
                }
            }

            this.handlePlayerStateFlags(this.playerData[actorNr], actorNr);
        }
    }

    sendPlayerStateFlags() {
        const playerData: NetworkPlayerLowFreqData = {
            packedStateFlags: this.playerStateFlags,
        };
        const packedPlayerData = packLowFreqNetworkPlayerData(playerData);
        this.raiseEvent(EVENT_CODES.PLAYER_STATE_FLAGS, packedPlayerData);
    }

    receivePlayerStateFlags(rawData: number[], actorNr: number) {
        const playerData = unpackLowFreqNetworkPlayerData(
            rawData,
            this.lastLowFreqPlayerUpdateTime,
        );
        this.lastLowFreqPlayerUpdateTime = playerData?.time ?? 0;

        this.handlePlayerStateFlags(playerData?.packedStateFlags ?? 0, actorNr);
    }

    handlePlayerStateFlags(playerStateFlags: number, actorNr: number) {
        this.playerData[actorNr] = playerStateFlags;
        this.handleIsMutedFlag(playerStateFlags, actorNr);
        this.handleIsTalkingFlag(playerStateFlags, actorNr);
        this.handleIsIdleFlag(playerStateFlags, actorNr);
    }

    sendPlayerData() {
        const cameraPos = this.avatarsManager?.getCameraPosition();
        const localCameraPosVector = [
            cameraPos?.x,
            cameraPos?.y,
            cameraPos?.z,
        ] as NetworkVector3;

        const playerData: NetworkPlayerHighFreqData = {
            position: localCameraPosVector,
        };
        const packedPlayerData = packHighFreqNetworkPlayerData(playerData);
        this.raiseEvent(
            EVENT_CODES.PLAYER_HIGH_FREQUENCY_DATA,
            packedPlayerData,
        );
    }

    receivePlayerData(rawData: number[], actorNr: number) {
        const playerData = unpackHighFreqNetworkPlayerData(
            rawData,
            this.lastHighFreqPlayerUpdateTime,
        );
        console.log("Received: ");
        console.log(playerData);
        if (playerData) {
            this.avatarsManager?.updateAvatarPosition(
                actorNr,
                playerData.position,
            );
        }
    }

    sendAssetData() {
        if (this.objectManager) {
            const position = this.objectManager.getPosition();
            const rotation = this.objectManager.getRotation();
            if (!position || !rotation) return;
            const assetData: NetworkAssetData = {
                position: position,
                rotation: rotation,
                scale: 0,
            };
            const packedAssetData = packHighFreqNetworkAssetData(assetData);
            this.raiseEvent(
                EVENT_CODES.ASSET_HIGH_FREQUENCY_DATA,
                packedAssetData,
            );
        }
    }

    receiveAssetData(rawData: number[]) {
        let isInitAssetData = false;
        if (this.lastAssetUpdateTime === 0) {
            // this is the first time we are receiving the asset data after joining the room
            isInitAssetData = true;
        }
        const assetData = unpackHighFreqNetworkAssetData(
            rawData,
            this.lastAssetUpdateTime,
        );

        if (!this.objectManager) return;

        if (assetData) {
            if (isInitAssetData) {
                if (
                    assetData.position.some((pos) => pos !== 0) ||
                    assetData.rotation.slice(0, 2).some((rot) => rot !== 0)
                ) {
                    // asset has been moved prior to joining room
                    this.objectManager?.toggleResetVisibility(true);
                }
            }

            this.objectManager.updatePosition(assetData.position);
            this.objectManager.updateRotation(assetData.rotation);
        }
    }

    sendRequestAssetControl() {
        console.log("request asset control");
        this.raiseEvent(EVENT_CODES.REQUEST_ASSET_CONTROL, null, {
            receivers: Photon.LoadBalancing.Constants.ReceiverGroup.All,
        });
    }

    sendReleaseAssetControl() {
        console.log("release asset control");
        this.raiseEvent(EVENT_CODES.RELEASE_ASSET_CONTROL, null, {
            receivers: Photon.LoadBalancing.Constants.ReceiverGroup.All,
        });
    }

    sendLaserPointerData() {
        // TODO
    }

    receiveLaserPointerData() {
        // TODO
    }

    receiveTeleportData(content: any) {
        const unpackedTeleportData = unpackNetworkTeleportData(content);
        const start = unpackedTeleportData.sourceActorNumber;
        const target = unpackedTeleportData.targetActorNumber;
        this.usersInTeleport.add(start);
        this.usersInTeleport.add(target);
        if (this.myActor().actorNr == target) {
            this.avatarsManager?.disableCamera();
        }

        const teleportDuration = 1000;
        this.avatarsManager?.getNetworkTeleportEffect(start, target);

        setTimeout(() => {
            this.avatarsManager?.enableCamera();
            this.usersInTeleport.clear();
            if (this.myActor().actorNr == target) {
                const avatarMap = this.avatarsManager?.getAvatarMap();
                if (avatarMap && avatarMap[start]) {
                    console.log(
                        "showing toast now for username",
                        avatarMap[start].name,
                    );

                    ToastQueue.info(
                        `${this.t("web:toast.review.userTeleportedToYou", { userName: avatarMap[start].name })}`,
                        {
                            timeout: 5000,
                        },
                    );
                }
            }
        }, teleportDuration);
    }

    /**
     * @summary Updates the player's info with the display name and avatar url
     */
    updatePlayerInfo(displayName: string, avatarUrl: string) {
        this.myActor().setName(displayName);
        const properties = {
            avatarUrl: avatarUrl,
            platform: "WEB",
        };
        this.myActor().setCustomProperties(properties);
    }

    isHost() {
        return this.myRoomMasterActorNr() === this.myActor().actorNr;
    }

    checkVersion() {
        // version checking
        const roomVersion = this.myRoom().getCustomProperty("version");
        if (roomVersion !== PHOTON_CONFIG.AppVersion) {
            // TODO: placeholder for version mismatch handling
            console.error("Room version mismatch");
            this.disconnect();
            return;
        }
    }

    getPlayerCountInRoom(roomName: string) {
        const room = this.rooms.find((room) => room.name === roomName);
        return room ? room.playerCount : 0;
    }

    sendResetAssetWindowEvent(name: string) {
        const event = new CustomEvent("onAssetReset", {
            detail: { name },
        });
        window.dispatchEvent(event);
    }

    /**
     * @summary called when another user has control of the asset
     *
     * @param ownerNr actor number of the asset owner
     */
    onAssetLockoutStart(ownerNr: number) {
        if (!this.objectManager) return;

        // disable gizmos if not the owner
        if (ownerNr !== this.myActor().actorNr) {
            this.objectManager.disableGizmos();

            // hide pins
            this.pinningSession?.pinManager.togglePinsVisibility();
        }

        // highlight mesh
        if (ownerNr === this.myActor().actorNr) {
            this.objectManager.highlightMesh();
        } else {
            const owner = this.getActorByNr(ownerNr);
            this.objectManager.highlightMesh(
                owner?.getCustomProperties().color,
            );
        }

        // show reset button in disabled state
        this.objectManager.toggleResetDisabled(true);

        // send window event
        if (ownerNr !== this.myActor().actorNr) {
            const event = new CustomEvent("onAssetLockedOut", {
                detail: { isLockedOut: true },
            });
            window.dispatchEvent(event);
        }
    }

    onAssetLockoutEnd() {
        if (!this.objectManager) return;

        // unhighlight mesh
        if (this.objectManager.isGizmoEnabled) {
            this.objectManager.highlightMesh();
        } else {
            this.objectManager.unhighlightMesh();
            // show pins
            this.pinningSession?.pinManager.togglePinsVisibility();
        }

        // show reset button in enabled stsate
        this.objectManager.toggleResetDisabled(false);

        // send window event
        const event = new CustomEvent("onAssetLockedOut", {
            detail: { isLockedOut: false },
        });
        window.dispatchEvent(event);
    }

    // OVERRIDES
    onEvent(code: number, content: any, actorNr: number) {
        switch (code) {
            case EVENT_CODES.HEARTBEAT_DATA:
                this.receiveHeartbeatData(content);
                break;
            case EVENT_CODES.PLAYER_STATE_FLAGS:
                this.receivePlayerStateFlags(content, actorNr);
                break;
            case EVENT_CODES.PLAYER_HIGH_FREQUENCY_DATA:
                this.receivePlayerData(content, actorNr);
                break;
            case EVENT_CODES.ASSET_HIGH_FREQUENCY_DATA:
                this.receiveAssetData(content);
                break;
            case EVENT_CODES.LASER_POINTER_HIGH_FREQUENCY_DATA:
                this.receiveLaserPointerData();
                break;
            case EVENT_CODES.REQUEST_ASSET_CONTROL:
                if (this.isHost() && this.objectManager) {
                    if (this.assetOwner == 0) {
                        this.assetOwner = actorNr;
                        this.onAssetLockoutStart(actorNr);
                        this.sendHeartbeatData();
                    }
                }
                break;
            case EVENT_CODES.RELEASE_ASSET_CONTROL:
                if (this.isHost() && this.objectManager) {
                    this.assetOwner = 0;
                    this.sendHeartbeatData();
                    this.onAssetLockoutEnd();
                }
                break;
            case EVENT_CODES.RESET_ASSET:
                this.objectManager?.resetModelTransform();

                const actor = this.myRoomActorsArray().find(
                    (actor) => actor.actorNr === actorNr,
                );
                this.sendResetAssetWindowEvent(actor?.name ?? "");
                break;
            case EVENT_CODES.TELEPORT:
                this.receiveTeleportData(content);
                break;
            default:
                break;
        }
    }

    /**
     * @summary Callback when the client joins a room; overrides the default
     * behavior provided by Photon.
     * 1. Spawns avatars for all existing users in the room
     * 2. Master client:
     *   a. Assigns user colors for all users in the room
     *   b. Sets room version
     * 3. Non-master clients:
     *   a. Checks room version for compatibility
     *   b. Gets color assigned to all other users and updates local copy of color map
     * 4. Send local state flags and player data to all other players
     */
    onJoinRoom() {
        // populate avatars for all existing users in the room
        const currentActors = this.myRoomActorsArray();
        currentActors.forEach((actor) => {
            let color;
            if (this.isHost()) {
                // init player data for all existing actors in the room
                this.playerData[this.myActor().actorNr] = 0;

                // host assigns user colors
                color = this.avatarsManager?.assignUserColor(actor.actorNr);
                actor.setCustomProperty("color", color);

                // set version for room
                this.myRoom().setCustomProperty(
                    "version",
                    PHOTON_CONFIG.AppVersion,
                );
            } else {
                this.checkVersion();

                // local actor will receive color assignment from master client
                if (!actor.isLocal) {
                    // get color from custom property
                    color = actor.getCustomProperties().color;
                    this.avatarsManager?.updateUserColor(actor.actorNr, color);
                }
            }

            if (!actor.isLocal && color) {
                const { avatarUrl } = actor.getCustomProperties();

                this.avatarsManager?.spawnAvatar(
                    actor.actorNr,
                    actor.name,
                    avatarUrl,
                    color,
                );
            }
        });

        this.sendPlayerData();
        // send local state flags to all other players
        this.sendPlayerStateFlags();

        const event = new CustomEvent("onJoinRoom");
        window.dispatchEvent(event);
    }

    onActorJoin(actor: Photon.LoadBalancing.Actor): void {
        console.log("new player joined: " + actor.actorNr);
        const { avatarUrl } = actor.getCustomProperties();
        const displayName = actor.name;

        if (this.isHost()) {
            // update this.playerData with new actor
            this.playerData[actor.actorNr] = 0;
            // send heartbeat data with new actor
            this.sendHeartbeatData();

            // send asset data once to catch up new user
            this.sendAssetData();

            // assign color and set custom property to notify all other users
            const color = this.avatarsManager?.assignUserColor(actor.actorNr);
            if (color) {
                actor.setCustomProperty("color", color);

                // spawn avatar for new actor -- note that non master clients will spawn avatar
                // on receiving onActorPropertiesChange callback
                if (!actor.isLocal) {
                    this.avatarsManager?.spawnAvatar(
                        actor.actorNr,
                        displayName,
                        avatarUrl,
                        color,
                    );
                }
            }
        }
        this.sendPlayerData();

        const event = new CustomEvent("onActorJoin", {
            detail: { displayName },
        });
        window.dispatchEvent(event);
    }

    onActorLeave(actor: Photon.LoadBalancing.Actor): void {
        console.log("player left: " + actor.actorNr);

        // update heartbeat data
        delete this.playerData[actor.actorNr];

        // update asset owner if player who left was asset owner
        if (this.assetOwner == actor.actorNr) {
            this.assetOwner = 0;

            const event = new CustomEvent("onAssetLockedOut", {
                detail: { isLockedOut: false },
            });
            window.dispatchEvent(event);
        }
        // remove avatar and color for actor
        this.avatarsManager?.releaseUserColor(actor.actorNr);
        this.avatarsManager?.removeAvatar(actor.actorNr);

        // host migration -- if the host has left and you are now the new host, takeover responsibilities
        if (this.isHost()) {
            this.lastHeartbeatUpdateTime = 0;
            this.heartbeatTicker = setInterval(() => {
                this.heartbeatTick();
            }, HEARTBEAT_INTERVAL);

            this.myRoom().setCustomProperty(
                "version",
                PHOTON_CONFIG.AppVersion,
            );
        }

        // dispatch event for presence bubbles
        const name = actor.name;
        const event = new CustomEvent("onActorLeave", { detail: { name } });
        window.dispatchEvent(event);
    }

    onActorPropertiesChange(actor: Photon.LoadBalancing.Actor): void {
        console.log("actor properties changed realtime: " + actor.actorNr);
        console.log(actor);
        this.avatarsManager?.removeAvatar(actor.actorNr);

        const { avatarUrl, color } = actor.getCustomProperties();
        this.avatarsManager?.updateUserColor(actor.actorNr, color);

        if (!actor.isLocal && color) {
            this.avatarsManager?.spawnAvatar(
                actor.actorNr,
                actor.name,
                avatarUrl,
                color,
            );
        }
    }

    onActorSuspend(actor: Photon.LoadBalancing.Actor): void {
        console.log("actor suspended: " + actor.actorNr);

        // update heartbeat data
        delete this.playerData[actor.actorNr];

        // update asset owner if player who left was asset owner
        if (this.assetOwner == actor.actorNr) {
            this.assetOwner = 0;

            const event = new CustomEvent("onAssetLockedOut", {
                detail: { isLockedOut: false },
            });
            window.dispatchEvent(event);
        }
        // remove avatar and color for actor
        this.avatarsManager?.releaseUserColor(actor.actorNr);
        this.avatarsManager?.removeAvatar(actor.actorNr);

        // host migration -- if the host has left and you are now the new host, takeover responsibilities
        if (this.isHost()) {
            this.lastHeartbeatUpdateTime = 0;
            this.heartbeatTicker = setInterval(() => {
                this.heartbeatTick();
            }, HEARTBEAT_INTERVAL);

            this.myRoom().setCustomProperty(
                "version",
                PHOTON_CONFIG.AppVersion,
            );
        }

        // dispatch event for presence bubbles
        const name = actor.name;
        const event = new CustomEvent("onActorLeave", { detail: { name } });
        window.dispatchEvent(event);
    }

    onMyRoomPropertiesChange(): void {
        this.checkVersion();
    }

    onRoomList(rooms: Photon.LoadBalancing.RoomInfo[]): void {
        this.rooms = rooms;
    }

    onRoomListUpdate(rooms: Photon.LoadBalancing.RoomInfo[]): void {
        this.rooms = rooms;
        const event = new CustomEvent("onRoomListUpdate");
        window.dispatchEvent(event);
    }

    onLobbyStats(errorCode: number, errorMsg: string, lobbies: any[]): void {
        console.log("lobby stats: ", lobbies);
    }

    getObjectManager() {
        return this.objectManager;
    }

    getActorByNr(actorNr: number) {
        const actor = this.myRoomActorsArray().find(
            (actor) => actor.actorNr === actorNr,
        );
        return actor;
    }

    getAvatarsManager() {
        return this.avatarsManager;
    }

    onTeleportStart(target: Photon.LoadBalancing.Actor): void {
        console.log("teleport started: " + target.actorNr);
        this.avatarsManager?.disableCamera();

        const teleportData: NetworkTeleportData = {
            sourceActorNumber: this.myActor().actorNr,
            targetActorNumber: target.actorNr,
        };

        this.usersInTeleport.add(this.myActor().actorNr);
        this.usersInTeleport.add(target.actorNr);

        const packedTeleportData = packNetworkTeleportData(teleportData);
        this.raiseEvent(EVENT_CODES.TELEPORT, packedTeleportData);

        if (this.avatarsManager) {
            const targetPosition = this.avatarsManager.getRemoteAvatar(
                target.actorNr,
            ).position;

            const currentTimestamp = Date.now();
            this.avatarsManager.getLocalTeleportEffect(currentTimestamp);

            setTimeout(() => {
                this.avatarsManager?.updateCameraPosition(targetPosition);
                this.avatarsManager?.enableCamera();
                this.usersInTeleport.clear();
            }, 1000);

            // send heartbeat data
            this.sendHeartbeatData();

            // send asset data once to catch up new user
            this.sendAssetData();
        }
        this.sendPlayerData();
    }

    isUserInTeleport(actorNr: number) {
        return this.usersInTeleport.has(actorNr);
    }
}
