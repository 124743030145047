/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { getAuthHeaders } from "../ims/Auth";
import { ASSET_APIS } from "@src/config";

export async function checkUserAccess(accessToken: string | undefined, urn: string) {
    if (!(accessToken && urn)) return;
    const response = await fetch(
        `${ASSET_APIS.invitations}/auth/${urn}`,
        {headers: getAuthHeaders(accessToken)},
    );

    return response.status;
}

export async function getGuestAccessToken(urn: string) {
    const response = await fetch(`${ASSET_APIS.invitations}/auth/${urn}?cdnAcceleration=true`);

    if (response.status === 200) {
        return (await response.json()).accessToken as string;
    }

    return;
}

export async function requestAccess(accessToken: string, assetId: string, targetUrl: string) {
    if (!(accessToken && assetId)) return;
    const response = await fetch(
        `${ASSET_APIS.invitations}/accessrequests`,
        {
            method: "POST",
            headers: getAuthHeaders(accessToken),
            body: JSON.stringify({
                notification: {
                    email: {
                        locale: "en-US",
                        templateName: "cc_collab_clouddoc_request_access",
                    },
                    parameters: {
                        targetUrl,
                    },
                },
                resourceUrn: assetId,
                requestedPermissions: {
                    canComment: true,
                    canShare: true,
                    role: "editor",
                },
            })
        },
    );
    return response.status;
}
