/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Camera } from "@babylonjs/core/Cameras/camera";
import { BoundingBox } from "@babylonjs/core/Culling/boundingBox";
import { EngineStore } from "@babylonjs/core/Engines/engineStore";
import { Matrix, Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { Scene } from "@babylonjs/core/scene";
import { Sprite } from "@babylonjs/core/Sprites/sprite";

export class BabylonGUIManager {
    protected scene: Scene;
    protected camera: Camera;
    protected viewportWidth: number;
    protected viewportHeight: number;

    constructor(scene: Scene) {
        this.scene = scene;
        this.camera = scene.cameras[0];
        const engine = scene.getEngine();

        // Fixes error thrown for invalid engine
        EngineStore._LastCreatedScene = scene;
        EngineStore.Instances.push(engine);

        this.viewportWidth = engine.getRenderWidth();
        this.viewportHeight = engine.getRenderHeight();
    }

    scaleMesh(
        mesh: Mesh,
        minSizeDistance: number,
        maxSizeDistance: number,
        minSize: number,
        maxSize: number,
    ) {
        // 1. get screen space height of avatar
        const boundingInfo = mesh.getBoundingInfo().boundingBox;
        const height = this.getScreenSpaceSize(boundingInfo).height;
        if (
            height === Number.POSITIVE_INFINITY ||
            height === Number.NEGATIVE_INFINITY
        )
            return;
        const distance = Vector3.Distance(this.camera.position, mesh.position);

        // 2. compare height to min and max sizes and determine scale factor for screen space height
        // NOTE: this calculates the scale FACTOR not the actual scale value
        let scale = 1;
        if (height == 0) return;
        if (distance > minSizeDistance || height <= minSize) {
            scale = minSize / height;
        } else if (distance < maxSizeDistance || height >= maxSize) {
            scale = maxSize / height;
        } else {
            // LERP based on distance from camera; scale distance to 0 to 1 for LERP parameter
            const t =
                (distance - minSizeDistance) /
                (maxSizeDistance - minSizeDistance);
            const size = this.lerp(minSize, maxSize, t);
            scale = size / height;
        }

        // 3. apply scale factor to mesh by multiplying current scale by the calculated factor
        if (scale > Number.EPSILON) {
            mesh.scaling = mesh.scaling.multiplyByFloats(scale, scale, scale);
        }
    }

    scaleSprite(
        sprite: Sprite,
        minSizeDistance: number,
        maxSizeDistance: number,
        minSize: number,
        maxSize: number,
    ) {
        const height = sprite.height;
        const distance = Vector3.Distance(
            this.camera.position,
            sprite.position,
        );
        let scale = 1;
        if (height == 0) return;
        if (distance > minSizeDistance) {
            scale = minSize;
        } else if (distance < maxSizeDistance) {
            scale = maxSize;
        } else {
            // LERP based on distance from camera; scale distance to 0 to 1 for LERP parameter
            const t =
                (distance - minSizeDistance) /
                (maxSizeDistance - minSizeDistance);
            const size = this.lerp(minSize, maxSize, t);
            scale = size;
        }

        sprite.width = scale;
        sprite.height = scale;
    }

    lerp(a: number, b: number, t: number) {
        return a + t * (b - a);
    }

    worldToScreen(vector: Vector3) {
        const viewport = this.camera.viewport.toGlobal(
            this.viewportWidth,
            this.viewportHeight,
        );
        return Vector3.Project(
            vector, // vector to project
            Matrix.Identity(), // world matrix
            this.scene.getTransformMatrix(), // transform matrix
            viewport, // viewport
        );
    }

    screenToWorld(vector: Vector3) {
        return Vector3.Unproject(
            vector, // vector to unproject
            this.viewportWidth, // viewport width
            this.viewportHeight, // viewport height
            Matrix.Identity(), // world matrix
            this.scene.getViewMatrix(), // view matrix
            this.scene.getProjectionMatrix(), // projection matrix
        );
    }

    getScreenSpaceSize(boundingInfo: BoundingBox): {
        width: number;
        height: number;
    } {
        let minX = Number.POSITIVE_INFINITY,
            minY = Number.POSITIVE_INFINITY,
            maxX = Number.NEGATIVE_INFINITY,
            maxY = Number.NEGATIVE_INFINITY;
        for (const vertex of boundingInfo.vectorsWorld) {
            const temp = this.worldToScreen(vertex);
            if (minX > temp.x) minX = temp.x;
            if (maxX < temp.x) maxX = temp.x;
            if (minY > temp.y) minY = temp.y;
            if (maxY < temp.y) maxY = temp.y;
        }

        return { width: maxX - minX, height: maxY - minY };
    }
}
