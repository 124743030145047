/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export * as Tiers from "./tiers.js";
export * as Calliope from "./calliope.js";

export const THUMBNAIL_WIDTH = 476;
export const THUMBNAIL_HEIGHT = 476;

/**
 * The render quality for USD model render job is based on the samples value and resolution.
 * See 'shared/server/src/models/jobs.ts' for warm/cold determination as relates to quality values.
 *
 * These values can come from:
 * https://git.corp.adobe.com/euclid/stager/blob/develop/components/geometry/source/lantern/LanternRenderSettings.cpp#L28
 * But for now, we have larger WIP values
 *
 * Mappings between these values and render parameters will need to be updated as when this updates.
 * See generateRendersSpec.ts and generateThumbnailSpec.ts.
 */
export enum RenderQualitySamplesValue {
    draft = 16,
    medium = 64,
    high = 256,
}

export enum RenderScaleValue {
    full = 1,
    half = 0.5,
    quarter = 0.25,
}



