/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";
import { useSearchParams } from "react-router-dom";

import { ReportAbuseView } from "../../components/ReportAbuseView";
import { usePostMessageContext } from "../../contexts/PostMessageContext";

export function ReportAbuseDialog() {
    const { postMessageBusApi } = usePostMessageContext();
    const [searchParams] = useSearchParams();
    const urn = searchParams.get("urn") || "";

    function submitCallback(responseStatus: number) {
        postMessageBusApi &&
            postMessageBusApi.reportAbuseSubmitted(responseStatus);
    }

    function cancelCallback() {
        postMessageBusApi && postMessageBusApi.reportAbuseCancelled();
    }

    return (
        <View
            height="100%"
            maxHeight="700px"
            paddingX="size-200"
            paddingTop="size-200"
            paddingBottom="size-300">
            <ReportAbuseView
                assetUrn={urn}
                onCancel={cancelCallback}
                onSubmit={submitCallback}
            />
        </View>
    );
}
