/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Button } from "@adobe/react-spectrum";

import { useSeashellLinking } from "../hooks/useSeashellLinking";

export function SeashellButton({ assetId }: { assetId: string }) {
    {
        const { hasSeashell, openSeashellLink, downloadSeashell } =
            useSeashellLinking(assetId);

        return (
            <Button
                onPress={() =>
                    hasSeashell ? openSeashellLink() : downloadSeashell()
                }
                variant="cta">
                {hasSeashell ? "Open in Seashell" : "Download Seashell"}
            </Button>
        );
    }
}
