/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { CubeTexture } from "@babylonjs/core/Materials/Textures/cubeTexture";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { CreatePlane } from "@babylonjs/core/Meshes/Builders/planeBuilder";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { GridMaterial } from "@babylonjs/materials/grid/gridMaterial";

import environmentCourtyardIblUrl from "../ibl/reviewer-env-courtyard.env";
import environmentGarageIblUrl from "../ibl/reviewer-env-garage.env";
import environmentStudioIblUrl from "../ibl/reviewer-env-studio.env";
import environmentDefaultIblUrl from "../ibl/studio_black_soft_light_02.env";
import pedestalLargeUrl from "../models/pedestal_minimal_large.glb";
import pedestalMediumeUrl from "../models/pedestal_minimal_medium.glb";
import pedestalSmallUrl from "../models/pedestal_minimal_small.glb";
import environmentCourtyardUrl from "../models/reviewer-env-courtyard.glb";
import environmentGarageUrl from "../models/reviewer-env-garage.glb";
import environmentStudioUrl from "../models/reviewer-env-studio.glb";
import { throttleAnimation } from "@src/util/AnimationThrottleUtils";

import type { Camera } from "@babylonjs/core/Cameras/camera";
import type { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import type { SceneManager } from "@components/studio/src/scene/SceneManager";

export type Environment =
    | "default"
    | "courtyard"
    | "garage"
    | "studio"
    | "none";
export type Pedestal = "small" | "large" | "medium" | "none";
export type Grounding = "grounded" | "ungrounded";
export type Centering = "centered" | "uncentered";
export type UpAxis = "y+" | "y-" | "x+" | "x-" | "z+" | "z-";

export type EnvironmentState = {
    size: [number, number, number];
    scaling: number;
    pedestal: Pedestal;
    environmentModel: Environment;
    groundModel: Grounding;
    centerModel: Centering;
    upAxis: UpAxis;
};

const envUrlMap: Record<Environment, string> = {
    none: "",
    default: environmentStudioUrl,
    studio: environmentStudioUrl,
    garage: environmentGarageUrl,
    courtyard: environmentCourtyardUrl,
};

const envIblUrlMap: Record<Environment, string> = {
    none: environmentDefaultIblUrl,
    default: environmentDefaultIblUrl,
    garage: environmentGarageIblUrl,
    studio: environmentStudioIblUrl,
    courtyard: environmentCourtyardIblUrl,
};

interface CameraLimits {
    min: Vector3;
    max: Vector3;
}

const envCameraLimitsMap: Record<Environment, CameraLimits> = {
    none: {
        min: new Vector3(-10, -10, -10),
        max: new Vector3(10, 10, 10),
    },
    default: {
        min: new Vector3(-6.5, 0, -6.5),
        max: new Vector3(6.5, 3, 6.5),
    },
    garage: {
        min: new Vector3(-6.2, 0, -6.2),
        max: new Vector3(6.2, 3, 6.2),
    },
    studio: {
        min: new Vector3(-5.9, 0.15, -4.1),
        max: new Vector3(3.8, 3, 4.15),
    },
    courtyard: {
        min: new Vector3(-6.2, 0.15, -10.5),
        max: new Vector3(7, 6.6, 10.4),
    },
};

const envBackgroundColorMap: Record<
    Environment,
    [number, number, number, number]
> = {
    none: [0.2, 0.2, 0.2, 1],
    default: [1, 1, 1, 1],
    studio: [1, 1, 1, 1],
    garage: [1, 1, 1, 1],
    courtyard: [1, 1, 1, 1],
};

const pedUrlMap: Record<Pedestal, string> = {
    large: pedestalLargeUrl,
    medium: pedestalMediumeUrl,
    small: pedestalSmallUrl,
    none: "",
};

export const pedHeightMap: Record<Pedestal, number> = {
    large: 1,
    medium: 1,
    small: 1,
    none: 0,
};

export function getEnvUrl(env: Environment) {
    return envUrlMap[env];
}

export function getPedestalUrl(ped: Pedestal) {
    return pedUrlMap[ped];
}

export class EnvironmentBuilder {
    static defaultPosition: Vector3 = new Vector3();
    static defaultRotation: Vector3 = new Vector3();
    static pedestal: Pedestal = "none";

    constructor(
        private sceneManager: SceneManager,
        private currentEnvironment: EnvironmentState,
    ) {
        this.updateEnvironment();
        throttleAnimation(
            this.sceneManager.viewer.onAdobeViewerRenderCompleteObservable,
            () => {
                if (this.sceneManager.camera) {
                    this.sceneManager.camera.onViewMatrixChangedObservable.add(
                        this.clampCamera,
                    );
                }
            },
        );
    }

    async updateEnvironment(oldEnvironment?: EnvironmentState) {
        if (oldEnvironment?.scaling !== this.currentEnvironment.scaling) {
            await this.updateScaling();
        }
        if (
            oldEnvironment?.environmentModel !==
            this.currentEnvironment.environmentModel
        ) {
            this.updateEnvironmentModel();
        }
        if (oldEnvironment?.pedestal !== this.currentEnvironment.pedestal) {
            await this.updatePedestal();
        }
        if (oldEnvironment?.upAxis !== this.currentEnvironment.upAxis) {
            this.updateOrientation();
        }
        if (
            oldEnvironment?.centerModel !== this.currentEnvironment.centerModel
        ) {
            this.updateCentering();
        }
        if (
            oldEnvironment?.groundModel !==
                this.currentEnvironment.groundModel ||
            oldEnvironment?.pedestal !== this.currentEnvironment.pedestal
        ) {
            this.updateGrounding();
        }

        // zero out y axis rotation on pivotMesh and __root__ model
        const pivotMesh = this.sceneManager.scene.getNodeByName(
            "pivotMesh",
        ) as TransformNode;
        const modelRoot = this.sceneManager.scene.getMeshByName("__root__");

        if (pivotMesh && modelRoot) {
            pivotMesh.rotation.setAll(0);
            modelRoot.rotationQuaternion?.setAll(0);
        }
        this.sceneManager.viewer.renderLoop.toggle(100);
    }

    set environment(environment: EnvironmentState) {
        const oldEnvironment = this.currentEnvironment;
        this.currentEnvironment = environment;
        this.updateEnvironment(oldEnvironment);
    }

    get environment() {
        return this.currentEnvironment;
    }

    private updateScaling() {
        const rootModel = this.sceneManager.viewer.getRootMesh();

        if (rootModel) {
            rootModel.scaling.setAll(this.currentEnvironment.scaling);
            this.sceneManager.viewer.modelScale =
                this.currentEnvironment.scaling;
            this.updateBounds(rootModel);
            this.updateGrounding();
            this.updateCentering();
            this.reframe();
        }
    }

    private async updatePedestal() {
        this.sceneManager.viewer.sceneManager.scene
            .getNodes()
            .forEach((node) => {
                if (node.name.includes("pedestal")) {
                    node.dispose();
                }
            });

        const model = this.sceneManager.viewer.getRootMesh();

        if (pedUrlMap[this.currentEnvironment.pedestal]) {
            await this.sceneManager.viewer.append(
                pedUrlMap[this.currentEnvironment.pedestal],
                false,
                null,
                null,
                "pedestal.glb",
            );
        }

        if (model) {
            this.updateGrounding();
        }
        this.updateBackground();
        this.setNoPick();
        this.reframe();

        EnvironmentBuilder.pedestal = this.currentEnvironment.pedestal;
    }

    private async updateEnvironmentModel() {
        this.sceneManager.viewer.sceneManager.scene
            .getNodes()
            .forEach((node) => {
                if (node.name.includes("environment")) {
                    node.dispose();
                }
            });

        if (envUrlMap[this.currentEnvironment.environmentModel]) {
            await this.sceneManager.viewer.append(
                envUrlMap[this.currentEnvironment.environmentModel],
                false,
                null,
                null,
                "environment.glb",
            );
        }

        if (this.currentEnvironment.environmentModel === "none") {
            const ground = CreatePlane(
                "reviewer_environment_grid",
                {
                    sideOrientation: 2,
                    width: 40,
                    height: 40,
                },
                this.sceneManager.scene,
            );
            ground.rotation.x = Math.PI / 2;
            const grid = new GridMaterial("grid_mat", this.sceneManager.scene);
            grid.majorUnitFrequency = 10;
            grid.minorUnitVisibility = 0.3;
            grid.gridRatio = 0.1;
            grid.backFaceCulling = false;
            grid.mainColor = new Color3(1, 1, 1);
            grid.lineColor = new Color3(1, 1, 1);
            grid.opacityTexture = new Texture(
                "https://assets.babylonjs.com/environments/backgroundGround.png",
            );
            grid.opacity = 0.56;
            ground.material = grid;
        }

        this.updateIbl();
        this.updateBackground();
        this.setNoPick();
        this.reframe();
    }

    private updateOrientation() {
        const model = this.sceneManager.viewer.getRootMesh();
        if (model) {
            model.position.setAll(0);
            model.rotation.setAll(0);
            const rotation = Math.PI / 2;
            switch (this.currentEnvironment.upAxis) {
                case "y-":
                    model.rotation.y = 2 * rotation;
                    break;
                case "z+":
                    model.rotation.z = rotation;
                    break;
                case "z-":
                    model.rotation.z = -rotation;
                    break;
                case "x+":
                    model.rotation.x = rotation;
                    break;
                case "x-":
                    model.rotation.x = -rotation;
                    break;
            }
            this.updateGrounding();
            this.updateCentering();

            EnvironmentBuilder.defaultRotation = model.rotation.clone();
        }
    }

    private updateGrounding() {
        const { groundModel, pedestal } = this.currentEnvironment;
        const model = this.sceneManager.viewer.getRootMesh();
        if (model) {
            if (groundModel === "grounded") {
                model.position.y = 0;
                this.updateBounds(model);
                const bounds = this.sceneManager.modelBounds;
                model.position.y = pedHeightMap[pedestal] + (0 - bounds.min.y);
            } else {
                model.position.y = pedHeightMap[pedestal];
            }

            console.log("modelY", model.position.y);

            this.updateBounds(model);
            this.reframe();

            EnvironmentBuilder.defaultPosition = model.position.clone();
        }
    }

    private updateCentering() {
        const { centerModel } = this.currentEnvironment;
        const model = this.sceneManager.viewer.getRootMesh();
        if (model) {
            const bounds = this.sceneManager.modelBounds;
            if (centerModel === "centered") {
                const center = Vector3.Center(bounds.min, bounds.max);
                model.position.x = -center.x;
                model.position.z = -center.z;
            } else {
                model.position.x = model.position.z = 0;
            }

            this.updateBounds(model);
            this.reframe();

            EnvironmentBuilder.defaultPosition = model.position.clone();
        }
    }

    updateBackground() {
        this.sceneManager.updateBackground(
            envBackgroundColorMap[this.currentEnvironment.environmentModel],
        );
    }

    clampCamera = (camera: Camera) => {
        const env = this.currentEnvironment.environmentModel;

        camera.position = Vector3.Clamp(
            camera.position,
            envCameraLimitsMap[env].min,
            envCameraLimitsMap[env].max,
        );
    };

    updateIbl() {
        const hdrTexture = CubeTexture.CreateFromPrefilteredData(
            envIblUrlMap[this.currentEnvironment.environmentModel],
            this.sceneManager.scene,
        );
        hdrTexture.rotationY = Math.PI;
        this.sceneManager.viewer.hdrTexture = hdrTexture;
        // this.sceneManager.scene.environmentIntensity = 0.4;

        this.sceneManager.scene.materials.forEach((mat: any) => {
            mat.reflectionTexture = hdrTexture;
            mat.enableSpecularAntiAliasing = false;
        });
    }

    setNoPick() {
        this.sceneManager.viewer.sceneManager.scene
            .getNodes()
            .forEach((node) => {
                if (
                    node.name.includes("pedestal") ||
                    node.name.includes("environment")
                ) {
                    (node as AbstractMesh).isPickable = false;
                    node.getChildMeshes().forEach((mesh) => {
                        mesh.isPickable = false;
                    });
                }
            });
    }

    updateBounds(rootModel: AbstractMesh) {
        const bounds = rootModel.getHierarchyBoundingVectors();
        this.sceneManager.modelBounds = bounds;

        const shadowPipeline = this.sceneManager.viewer.iblShadowsPipeline;
        if (shadowPipeline) {
            shadowPipeline.updateSceneBounds();
        }
    }

    reframe() {
        if (this.sceneManager.camera) {
            this.sceneManager.camera.beta = Math.PI * 0.45;
        }
        this.sceneManager.frame();
    }
}
