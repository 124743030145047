/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

//@ts-ignore
import { validateBytes } from "gltf-validator";

import type { AdobeViewer } from "@3di/adobe-3d-viewer";

interface NumericValueField {
    displayName: string;
    value: number;
    recommendedLimit: number;
    displayValue?: string;
    displayRecommendedLimit?: string;
}

interface PhysicalSizeField {
    displayName: string;
    value: number[];
    displayValue?: string;
    recommendedRange: [number, number]
}

export interface AssetTechInfo {
    physicalSize: PhysicalSizeField;
    fileFormat: Record<string, string>;
    originalFormat?: Record<string, string>;
    fileSize: NumericValueField;
    polycount: NumericValueField;
    drawCalls: NumericValueField;
    meshes: NumericValueField;
    materials: NumericValueField;
    textures: NumericValueField;
}

export async function getAssetTechInfo(file: Blob, originalFormat?: string) {
    const buffer = await file.arrayBuffer();
    try {
        const report = await validateBytes(new Uint8Array(buffer));
        const info: AssetTechInfo = {
            physicalSize: { displayName: "Physical size", value: [0, 0, 0], recommendedRange: [0.005, 12] },
            fileFormat: { displayName: "File format", value: "GLB" },
            originalFormat: {
                displayName: "Original File Format",
                value: originalFormat?.toUpperCase() || ""
            },
            fileSize: {
                displayName: "File size",
                value: file.size, // in KB
                recommendedLimit: 10000000, // in KB
            },
            polycount: {
                displayName: "Polycount",
                value: report.info.totalTriangleCount,
                recommendedLimit: 500000,
            },
            drawCalls: {
                displayName: "Draw calls",
                value: report.info.drawCallCount,
                recommendedLimit: 300,
            },
            meshes: { displayName: "Meshes", value: 0, recommendedLimit: 10 },
            materials: {
                displayName: "Materials",
                value: report.info.materialCount,
                recommendedLimit: 8,
            },
            textures: {
                displayName: "Textures",
                value: 0,
                recommendedLimit: 10,
            },
        };
        if (!originalFormat || originalFormat === "glb") {
            delete info.originalFormat
        }

        return info;
    } catch (error) {
        console.error("Validation failed: ", error);
        return;
    }
}

export function updateTechInfoFromViewer(
    viewer: AdobeViewer | undefined,
    scene: any,
    info: AssetTechInfo,
) {
    if (!(viewer && scene && info)) return;

    const modelBounds = scene.getWorldExtends();
    const modelScale = viewer.modelScale;
    const axes = ["x", "y", "z"];
    const physicalSize = axes.map((axis) => {
        return (
            Math.abs(modelBounds.max[axis] - modelBounds.min[axis]) / modelScale
        );
    });
    info.physicalSize.value = physicalSize;

    // exclude default babylon root meshes
    const adjustedMeshCount = scene.meshes.filter(
        (mesh: any) =>
            !["__root__", "modelRootMesh", "pivotMesh"].includes(mesh.name),
    ).length;
    info.meshes.value = adjustedMeshCount;

    // exclude default babylon environment and our ENV texture
    const adjustedTextureCount = scene.textures.filter((texture: any) => {
        return (
            !texture.name.includes("data:") &&
            !texture.name.includes(".env") &&
            !texture.name.includes("temp-pin-layer")
        );
    }).length;
    info.textures.value = adjustedTextureCount;
    return info;
}
