/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Content,
    Flex,
    Heading,
    IllustratedMessage,
    ProgressCircle,
} from "@adobe/react-spectrum";
import NoSearchResults from "@spectrum-icons/illustrations/NoSearchResults";
import NotFound from "@spectrum-icons/illustrations/NotFound";
import Unauthorized from "@spectrum-icons/illustrations/Unauthorized";
import { useTranslation } from "react-i18next";

export enum NoAssetsMessageType {
    loading = "loading",
    noReviews = "noReviews",
    noSharedReviews = "noSharedReviews",
    noSearchResults = "noSearchResults",
    unauthorized = "unauthorized",
}

interface NoAssetsViewProps {
    messageType: string | undefined;
    isForVr: boolean;
}

export function NoAssetsView({ messageType, isForVr }: NoAssetsViewProps) {
    const { t } = useTranslation("common");

    function messageView() {
        let content;
        switch (messageType) {
            case NoAssetsMessageType.loading:
                content = (
                    <ProgressCircle
                        aria-label={t("accessibility.progress.loading")}
                        isIndeterminate
                    />
                );
                break;
            case NoAssetsMessageType.noReviews:
                content = (
                    <IllustratedMessage>
                        <NotFound />
                        <Heading>{t("assets.noReviews.title")}</Heading>
                        <Content>
                            {isForVr
                                ? t("assets.noReviews.description.vr")
                                : t("assets.noReviews.description.web")}
                        </Content>
                    </IllustratedMessage>
                );
                break;
            case NoAssetsMessageType.noSharedReviews:
                content = (
                    <IllustratedMessage>
                        <NotFound />
                        <Heading>{t("assets.noSharedWithYou.title")}</Heading>
                        <Content>
                            {t("assets.noSharedWithYou.description")}
                        </Content>
                    </IllustratedMessage>
                );
                break;
            case NoAssetsMessageType.noSearchResults:
                content = (
                    <IllustratedMessage>
                        <NoSearchResults />
                        <Heading>{t("assets.search.noResults")}</Heading>
                    </IllustratedMessage>
                );
                break;
            case NoAssetsMessageType.unauthorized:
                content = (
                    <IllustratedMessage>
                        <Unauthorized />
                        <Heading>{t("assets.signedOut.title")}</Heading>
                        <Content>{t("assets.signedOut.description")}</Content>
                    </IllustratedMessage>
                );
                break;
            default:
                console.error("invalid MessageType");
        }
        return content;
    }

    return (
        <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center">
            {messageView()}
        </Flex>
    );
}
