/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { LogInRequiredView } from "../../components/LogInRequiredView";
import { usePostMessageContext } from "../../contexts/PostMessageContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

export function LogInRequiredDialog() {
    const { logIn, accessToken } = useHi5UserContext();
    const { postMessageBusApi } = usePostMessageContext();
    const [searchParams] = useSearchParams();
    const urn = searchParams.get("urn") || "";

    function goHomeCallback() {
        if (postMessageBusApi) postMessageBusApi.showDialog("log-in-check");
    }

    useEffect(() => {
        if (!(postMessageBusApi && accessToken)) return;
        // user is now logged in, resume access-check
        postMessageBusApi.showDialog("access-check", `urn=${urn}`);
    }, [accessToken, postMessageBusApi]);

    function logInCallback() {
        if (postMessageBusApi)
            postMessageBusApi.resizeWindow("log-in-required-susi");
        logIn();
    }

    return (
        <View maxHeight="400px" paddingTop="size-100" paddingX="size-100">
            <LogInRequiredView
                onGoHome={goHomeCallback}
                onLogIn={logInCallback}
                isForVr={true}
            />
        </View>
    );
}
