/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { SceneManager } from "@components/studio/src/scene/SceneManager";
import { useEffect, useState } from "react";

import { EnvironmentBuilder, EnvironmentState } from "@src/scene/EnvironmentBuilder";


export function useEnvironmentBuilder(sceneManager: SceneManager | undefined, environment?: EnvironmentState) {
    const [envBuilder, setEnvBuilder] = useState<EnvironmentBuilder>();

    useEffect(() => {
        if (!envBuilder && sceneManager && environment) {
            setEnvBuilder(
                new EnvironmentBuilder(
                    sceneManager,
                    environment,
                ),
            );
        }
    }, [envBuilder, sceneManager, environment]);

    useEffect(() => {
        if (envBuilder && environment) {
            envBuilder.environment = environment;
        }
    }, [
        envBuilder,
        environment
    ]);

    return {
        envBuilder
    }

}
