/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

import { useAcpContext } from "@src/contexts/AcpContext";
import type {
    Centering,
    Environment,
    EnvironmentState,
    Grounding,
    Pedestal,
    UpAxis,
} from "@src/scene/EnvironmentBuilder";

export function useSceneEnvironment(assetId?: string) {
    const { useEnvironmentMeta } = useAcpContext();

    const { data: envMeta } = useEnvironmentMeta(assetId || "");

    const [environment, setEnvironment] = useState<EnvironmentState>();

    useEffect(() => {
        if (envMeta) {
            setEnvironment({
                size: envMeta.physicalSize
                    ? JSON.parse(envMeta.physicalSize)
                    : [],
                scaling: envMeta?.["scaling"]
                    ? parseFloat(envMeta?.["scaling"])
                    : 1,
                pedestal: (envMeta.pedestal as Pedestal) || "none",
                environmentModel:
                    (envMeta.environment as Environment) || "default",
                groundModel: (envMeta?.grounding as Grounding) || "ungrounded",
                centerModel: (envMeta?.centering as Centering) || "uncentered",
                upAxis: (envMeta.upAxis as UpAxis) || "y+",
            });
        }
    }, [envMeta]);

    return environment;
}
