/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect } from "react";

import { usePostMessageContext } from "../../contexts/PostMessageContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

export function AssertLogOut() {
    const { logOut, imsReady, userProfile } = useHi5UserContext();
    const { postMessageBusApi } = usePostMessageContext();

    useEffect(() => {
        if (postMessageBusApi && imsReady && !userProfile) {
            postMessageBusApi.showDialog("log-in-check");
        }
    }, [postMessageBusApi, imsReady, userProfile]);

    function checkLoggedOut() {
        console.log("Logging out user");
        if (postMessageBusApi) {
            postMessageBusApi.clearCookies();
            logOut();
            return;
        }
    }

    useEffect(() => {
        checkLoggedOut();
    }, [postMessageBusApi]);

    return <div>Logging out user</div>;
}
