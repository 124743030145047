/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Text,
    Tabs,
    TabList,
    TabPanels,
    View,
    Button,
    Item,
    Avatar,
    Image,
} from "@adobe/react-spectrum";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { VRPreferences } from "./VRPreferences";
import { UserProfileView } from "../components/UserProfileView";
import { usePostMessageContext } from "../contexts/PostMessageContext";
import MicMute from "../images/MicMute.svg";
import MicUnmute from "../images/MicUnmute.svg";

import type { Key } from "@react-types/shared";

interface UserInfo {
    id: string;
    name: string;
    aviUrl: string;
    isMuted: boolean;
}

export enum AppSettingsTabType {
    account = "account",
    preferences = "preferences",
    social = "social",
    info = "info",
    home = "home",
}

export enum PreferenceName {
    lang = "lang",
    uiSize = "uiSize",
    controller = "controller",
    haptics = "haptics",
    preset = "preset",
    resolution = "resolution",
    spaceWarp = "spaceWarp",
    customResolution = "customResolution",
}

export function AppSettingsTabsView(
    selectedSettingsTab: Key,
    setSelectedSettingsTab: (tab: Key) => void,
    lang: string,
    uiSize: string,
    controller: string,
    haptics: boolean,
    preset: string,
    resolution: boolean,
    spaceWarp: boolean,
    customResolution: string,
    incomingUserList: UserInfo[],
) {
    const { userProfile, avatarUrl } = useHi5UserContext();
    const { postMessageBusApi } = usePostMessageContext();
    const [searchParams] = useSearchParams();

    const { t } = useTranslation("common");
    const tab = searchParams.get("tab") || "";

    useEffect(() => {
        if (tab == "preferences") {
            console.log("tab", tab);
        }
    }, [tab]);

    useEffect(() => {
        if (selectedSettingsTab == AppSettingsTabType.preferences) {
            postMessageBusApi?.resizeWindow("app-settings-preferences");
        } else if (selectedSettingsTab == AppSettingsTabType.account) {
            postMessageBusApi?.resizeWindow("app-settings-account");
        } else if (selectedSettingsTab == AppSettingsTabType.social) {
            postMessageBusApi?.resizeWindow("app-settings-social");
        } else if (selectedSettingsTab == AppSettingsTabType.info) {
            //todo
        }
    }, [selectedSettingsTab]);

    function logOutAndClearCookies() {
        if (postMessageBusApi) {
            postMessageBusApi.signOutVR();
        }
    }

    function accountView() {
        return (
            <View paddingX="20px" paddingY="25px">
                <View>
                    <Flex direction="row" alignItems="center" gap="20px">
                        <UserProfileView
                            userProfile={userProfile}
                            avatarUrl={avatarUrl}
                            isForVr={true}
                        />
                    </Flex>
                </View>
                <View paddingTop="30px">
                    <Flex direction="row" gap="10px">
                        <Button
                            width="180px"
                            onPress={() => {
                                logOutAndClearCookies();
                            }}
                            variant="secondary"
                            UNSAFE_style={{
                                borderRadius: "100px",
                            }}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.5em",
                                }}>
                                {t("account.signOut")}
                            </Text>
                        </Button>
                    </Flex>
                </View>
            </View>
        );
    }

    const [selectedLang, setSelectedLang] = useState<Key>(lang);
    const [selectedUiSize, setSelectedUiSize] = useState<Key>(uiSize);
    const [selectedController, setSelectedController] =
        useState<Key>(controller);
    const [selectedHaptics, setSelectedHaptics] = useState<boolean>(haptics);
    const [selectedPreset, setSelectedPreset] = useState<Key>(preset);
    const [selectedResolution, setSelectedResolution] =
        useState<boolean>(resolution);
    const [selectedSpaceWarp, setSelectedSpaceWarp] =
        useState<boolean>(spaceWarp);
    const [selectedCustomResolution, setSelectedCustomResolution] =
        useState<Key>(customResolution);

    useEffect(() => {
        setSelectedLang(lang);
        setSelectedUiSize(uiSize);
        setSelectedController(controller);
        setSelectedHaptics(haptics);
        setSelectedPreset(preset);
        setSelectedResolution(resolution);
        setSelectedSpaceWarp(spaceWarp);
        setSelectedCustomResolution(customResolution);
    }, [
        lang,
        uiSize,
        controller,
        haptics,
        resolution,
        spaceWarp,
        customResolution,
        preset,
    ]);

    function setPreferenceState(preference: Key, value: Key | boolean) {
        switch (preference) {
            case PreferenceName.lang:
                setSelectedLang(value as Key);
                break;
            case PreferenceName.uiSize:
                setSelectedUiSize(value as Key);
                break;
            case PreferenceName.controller:
                setSelectedController(value as Key);
                break;
            case PreferenceName.haptics:
                setSelectedHaptics(value as boolean);
                break;
            case PreferenceName.preset:
                setSelectedPreset(value as Key);
                break;
            case PreferenceName.resolution:
                setSelectedResolution(value as boolean);
                break;
            case PreferenceName.spaceWarp:
                setSelectedSpaceWarp(value as boolean);
                break;
            case PreferenceName.customResolution:
                setSelectedCustomResolution(value as Key);
                break;
        }
    }

    function preferencesView() {
        return (
            <View paddingX="20px" paddingY="25px">
                <VRPreferences
                    // isSignedIn={isSignedIn}
                    selectedLang={selectedLang}
                    selectedUiSize={selectedUiSize}
                    selectedController={selectedController}
                    selectedHaptics={!!selectedHaptics}
                    selectedPreset={selectedPreset}
                    selectedResolution={!!selectedResolution}
                    selectedSpaceWarp={!!selectedSpaceWarp}
                    selectedCustomResolution={selectedCustomResolution}
                    setPreferenceState={setPreferenceState}
                />
            </View>
        );
    }

    const defaultAvatar = "https://i.imgur.com/kJOwAdv.png";

    const [userList, setUserList] = useState(incomingUserList);

    useEffect(() => {
        setUserList(incomingUserList);
    }, [incomingUserList]);

    function formattedUserInfo(): UserInfo[] {
        const newUserList: UserInfo[] = [];
        userList.forEach((testUser) => {
            const userInfo: UserInfo = {
                id: testUser.id,
                name: testUser.name ? testUser.name : "User",
                aviUrl: testUser.aviUrl,
                isMuted: testUser.isMuted,
            };

            if (userInfo.name === userProfile?.displayName) {
                newUserList.unshift(userInfo);
            } else newUserList.push(userInfo);
        });
        return newUserList;
    }

    function ShowProfileDetail(avatarurl:string, userName:string)
    {
        return (
            <Flex
            gap="30px">
                <Avatar
                    alt={t("accessibility.account.profilePicture")}
                    src={avatarurl || defaultAvatar}
                    size="60px"
                />
                <Text
                UNSAFE_style={{
                    fontSize: "1.75em",
                    fontWeight: "bold",
                    marginTop: "10px",
                }}>
                {userName}
                </Text>
                <Text
                    UNSAFE_style={{
                        fontSize: "1.75em",
                        color: "gray",
                        fontWeight: "bold",
                        marginTop: "10px",
                    }}>
                    {userProfile &&
                    userProfile.displayName === userName
                        ? t("account.you")
                        : ""}
                </Text>
            </Flex>

        );
    }

    function IsMuted(isMuted:boolean)
    {
        return (
            isMuted ? (
                <Image
                    width="30px"
                    height="30px"
                    marginTop="15px"
                    marginEnd="20px"
                    src={MicMute}
                />
            ) : (
                <Image
                    width="30px"
                    height="30px"
                    marginTop="15px"
                    marginEnd="20px"
                    src={MicUnmute}
                />
            )
        );
    }

    function ShowOnlineUsers()
    {
        return(
        <View paddingX="20px" paddingY="25px">
                {formattedUserInfo().map((userList) => {
                    // console.log("userId", userList.id);
                    // console.log("userName", userList.name);
                    // console.log("userName char length", userList.name.length);
                    // console.log("userAviUrl", userList.aviUrl);
                    // console.log("userMicStatus:",userList.name , userList.isMuted); <-- turn on for debugging
                    return (
                        <div
                            key={userList.id}
                            style={{
                                overflowY: "auto",
                            }}>
                            <Flex
                                direction="row"
                                gap="30px"
                                marginBottom="20px"
                                justifyContent="space-between">
                                <Flex direction="row">
                                    {ShowProfileDetail(userList.aviUrl, userList.name)}
                                </Flex>
                                <Flex direction="row">
                                    {IsMuted(userList.isMuted)}
                                </Flex>
                            </Flex>
                        </div>
                    );
                })}
            </View>);
    }

    function ShowOfflineUsers()
    {
        return(
        <View paddingX="20px" paddingY="25px">
            <Text
                UNSAFE_style={{
                    fontSize: "1.75em",
                    fontWeight: "bold",
                }}>
                {t("account.social.offline")}
            </Text>
        </View>);
    }

    function socialView() {
        // console.log("formatted user info", formattedUserInfo()); <-- turn on for debugging
        if (userList.length > 0)
        {
            return (ShowOnlineUsers());
        }
        else
        {
            return (ShowOfflineUsers());
        }

    }

    function infoView() {
        return (
            <View>
                <Text>Info</Text>
            </View>
        );
    }

    return (
        <>
            {userProfile && tab == "" ? (
                <Tabs
                    selectedKey={selectedSettingsTab}
                    onSelectionChange={setSelectedSettingsTab}>
                    <Flex
                        direction="row"
                        alignItems="start"
                        marginStart="20px"
                        wrap>
                        <TabList minWidth="268px">
                            <Item key={AppSettingsTabType.preferences}>
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "1.5em",
                                    }}>
                                    {t("account.preferences")}
                                </Text>
                            </Item>
                            {/* <Item key={AppSettingsTabType.social}>
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "1.5em",
                                    }}>
                                    {t("account.social")}
                                </Text>
                            </Item> <--- deprecated for now, until we want to show online users via web and vr */}
                            <Item key={AppSettingsTabType.account}>
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "1.5em",
                                    }}>
                                    {t("account.account")}
                                </Text>
                            </Item>
                        </TabList>
                    </Flex>
                    <TabPanels>
                        <Item key={AppSettingsTabType.preferences}>
                            {preferencesView()}
                        </Item>
                        {/* <Item key={AppSettingsTabType.social}>
                            {socialView()}
                        </Item> <-- deprecated for now, until we want to show online users via web and vr */}
                        <Item key={AppSettingsTabType.info}>{infoView()}</Item>
                        <Item key={AppSettingsTabType.account}>
                            {accountView()}
                        </Item>
                    </TabPanels>
                </Tabs>
            ) : (
                <>{preferencesView()}</>
            )}
        </>
    );
}
