/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    Content,
    Dialog,
    Flex,
    Footer,
    Heading,
    Text,
    View,
} from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

interface LogInRequiredViewProps {
    onGoHome: () => void;
    onLogIn: () => void;
    isForVr?: boolean;
}

export function LogInRequiredView({
    onGoHome,
    onLogIn,
    isForVr = false,
}: LogInRequiredViewProps) {
    const { t } = useTranslation("common");

    return (
        <Dialog width={isForVr ? "600px" : "400px"} height="100%">
            <Heading
                UNSAFE_style={{
                    fontSize: isForVr ? "2.2em" : "",
                }}>
                {t("login.required.title")}
            </Heading>
            <Content>
                <View marginTop="size-250" height="28px">
                    <Text
                        UNSAFE_style={{
                            fontSize: isForVr ? "1.75em" : "",
                        }}>
                        {t("login.required.description")}
                    </Text>
                </View>
            </Content>
            <Footer>
                <View paddingBottom="1.5em" width="100%">
                    <Flex
                        direction="row"
                        width="100%"
                        gap="size-150"
                        justifyContent="space-between">
                        <Button
                            onPress={onGoHome}
                            width="50%"
                            variant="secondary"
                            style="fill"
                            UNSAFE_style={{
                                borderRadius: isForVr ? "100px" : "",
                            }}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: isForVr ? "1.5em" : "",
                                }}>
                                {t("navigation.goHome")}
                            </Text>
                        </Button>
                        <Button
                            onPress={onLogIn}
                            width="50%"
                            variant="cta"
                            UNSAFE_style={{
                                borderRadius: isForVr ? "100px" : "",
                            }}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: isForVr ? "1.5em" : "",
                                }}>
                                {t("navigation.signIn")}
                            </Text>
                        </Button>
                    </Flex>
                </View>
            </Footer>
        </Dialog>
    );
}
