/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ReviewListItem } from "../acp/AcpClientModels";
import { ADOBE_IMS_CONFIG, ASSET_APIS } from "@src/config";

export async function getSharedAssets(accessToken: string) {
    const options: RequestInit = {
        method: "POST",
        cache: "default",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "x-api-key": ADOBE_IMS_CONFIG.client_id,
            "content-type": "application/vnd.adobe.search-request+json",
            "x-product": "highfive/1.0",
            "x-product-location": "highfive 1.0",
        },
    };

    const body = JSON.stringify({
        scope: ["creative_cloud"],
        ownership_types: ["shared_with_me"],
        sort_orderby: "sync_updated_date",
        fetch_fields: {
            includes: ["app_metadata"],
        },
        shared_via_invite: true,
        hints: {
            acp_platform_v2: true,
        },
        "storage:assignee$$type": [],
        op_none_of: [
            {
                "app_metadata$$shell:visibility": "hiddenSelf",
            },
            {
                "app_metadata$$project:directoryType": "project",
            },
            {
                "app_metadata$$project:directoryType": "team",
            },
            {
                creative_cloud_toplevel_collection_name: ["appdata"],
            },
            {
                creative_cloud_toplevel_collection_name: "files",
            },
        ],
        q: "*",
        cc_teams_user_storage: true,
        creative_cloud_asset_type: ["dcx_composite"],
        sort_order: "desc",
        limit: 100,
    });
    options.body = body;
    const response = await fetch(
        `${ASSET_APIS.universalSearch}/search`,
        options,
    );

    const parsedBody = await response.json();
    const sharedReviews: ReviewListItem[] = [];
    if (parsedBody) {
        if (
            !(
                parsedBody["result_sets"] &&
                parsedBody["result_sets"][0] &&
                parsedBody["result_sets"][0]["items"]
            )
        )
            return;

        for (const asset of parsedBody["result_sets"][0]["items"]) {
            if (asset["type"] !== "application/vnd.adobe.usdcx+dcx")
                continue;
            let urn = asset["asset_id"];
            // force region code to be upper case
            const regionId = urn.split(":")[3];
            urn = urn.replace(regionId, regionId.toUpperCase());
            const displayName = asset["repo:name"].replace(".usdcx", "");
            const createDate = asset["create_date"];
            const createdBy = asset["creative_cloud_creator_id"];
            const modifyDate = asset["modify_date"];
            const listItem: ReviewListItem = {
                name: asset["repo:name"],
                assetId: urn,
                displayName,
                createdBy,
                createDate,
                modifyDate,
            };
            sharedReviews.push(listItem);
        }
    }
    return sharedReviews;
}
