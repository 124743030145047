/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import {
    useQuery,
    useQueryClient,
    QueryClient,
    useMutation,
} from "@tanstack/react-query";
import {
    PersistQueryClientProvider,
    PersistedClient,
} from "@tanstack/react-query-persist-client";
import {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useMemo,
} from "react";

import { useHi5UserContext } from "./HI5UserProvider";
import { ASSET_APIS, HI5_API_KEY } from "@src/config";
import { AcpClient, parseUrlExpiry } from "@src/lib/acp/AcpClient";
import {
    ReviewListItem,
    dcxToReviewListItem,
} from "@src/lib/acp/AcpClientModels";
import { getCollaboratorsCount } from "@src/lib/invitation/Collaborators";
import { getSharedAssets } from "@src/lib/search/SearchSharedAssets";

export const EnvironmentMetaFields = [
    "metaFormatVersion",
    "units",
    "environment",
    "scaling",
    "physicalSize",
    "pedestal",
    "upAxis",
    "grounding",
    "centering",
] as const;

export type ReviewerEnvMeta = Partial<
    Record<(typeof EnvironmentMetaFields)[number], string>
>;

export const acpQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000,
            refetchOnMount: false,
            networkMode: "online",
        },
    },
});

const persister = createSyncStoragePersister({
    storage: window.localStorage,
    throttleTime: 5_000,
    deserialize(cachedString) {
        const data = JSON.parse(cachedString) as PersistedClient;
        data.clientState.queries = data.clientState.queries.filter((query) => {
            if (
                typeof query.state.data === "string" &&
                query.state.data.startsWith("https://")
            ) {
                try {
                    const ttl = parseUrlExpiry(query.state.data);
                    if (ttl < 5 * 60) {
                        console.log("Query expired", JSON.stringify(query));
                        return false;
                    }
                } catch (e) {
                    console.error("Failed to parse query", {
                        query,
                        error: e,
                    });
                }
            }
            return true;
        });
        return data;
    },
});

export type AcpContextValue = ReturnType<typeof useAcpContextValue>;

export const AcpContext = createContext<AcpContextValue>({} as AcpContextValue);

export function useAcpContext() {
    const acpContext = useContext(AcpContext);
    if (!acpContext.acpClient) {
        throw new Error("Acp Context used out of provider scope.");
    }
    return acpContext;
}

async function validateGetUrl(url: string) {
    if (url) {
        const res = await fetch(url, { cache: "no-cache" });
        if (!res.ok) {
            const e = new Error(
                `Bad status on validateGetUrl ${url} code: ${res.status}`,
            );
            console.error(e);
            throw  e;
        }
    }
}

function useAcpClient() {
    const { imsReady, userId, userProfile, accessToken } = useHi5UserContext();

    const acpClient = useMemo(
        () => new AcpClient(ASSET_APIS.hostAcp, HI5_API_KEY),
        [],
    );

    useEffect(() => {
        if (imsReady && userId && userProfile && accessToken) {
            acpClient.initializeUser(
                userId,
                userProfile?.ownerOrg || userId,
                accessToken,
            );
            // @ts-ignore Leak to window for debugging
            window.acpClient = acpClient;
        }
    }, [imsReady, userId, userProfile, accessToken]);

    return { acpClient };
}

function useAcpContextValue() {
    const { accessToken } = useHi5UserContext();
    const { acpClient } = useAcpClient();

    const queryClient = useQueryClient();

    useEffect(() => {
        setTimeout(() => {
            // ensure these queries are fresh after hydration
            queryClient.invalidateQueries({ queryKey: ["myReviews"] });
            queryClient.invalidateQueries({ queryKey: ["sharedWithMeReviews"] });
            queryClient.invalidateQueries({ queryKey: ["thumbnailUrls"] });
            queryClient.invalidateQueries({ queryKey: ["glbUrls"] });
        }, 5_000);
    }, []);

    const useStorageQuota = () =>
        useQuery({
            queryKey: ["storageQuota"],
            async queryFn() {
                const { bytesLimit, bytesUsed } =
                    await acpClient.getStorageQuota();
                return {
                    bytesUsed,
                    bytesLimit,
                    percent: bytesUsed / bytesLimit,
                };
            },
        });

    const useMyReviews = () =>
        useQuery<ReviewListItem[]>({
            queryKey: ["myReviews"],
            async queryFn() {
                const children = await acpClient.getAllChildrenInDirectory(
                    "cloud-content",
                    "application/vnd.adobe.usdcx+dcx",
                );
                return children.map(dcxToReviewListItem);
            },
            refetchInterval: 3 * 1000,
        });

    const useSharedWithMeReviews = () =>
        useQuery<ReviewListItem[]>({
            queryKey: ["sharedWithMeReviews"],
            async queryFn() {
                if (!accessToken) return [];
                const sharedAssets = await getSharedAssets(accessToken);
                if (!sharedAssets) return [];
                return sharedAssets;
            },
            refetchInterval: 3 * 1000,
        });

    const useReviewListItem = (assetId: string) =>
        useQuery<ReviewListItem>({
            queryKey: ["reviewListItem", assetId],
            async queryFn() {
                console.log("fetching listItem", assetId);
                return dcxToReviewListItem(
                    await acpClient.resolveComposite(assetId),
                );
            },
            refetchOnMount: true,
        });

    const useCollaboratorCount = (assetId: string) =>
        useQuery<number>({
            queryKey: ["collaboratorCount", assetId],
            async queryFn() {
                if (!accessToken) return 0;
                const collaboratorCount = await getCollaboratorsCount(
                    accessToken,
                    assetId,
                );
                return collaboratorCount;
            },
            refetchOnMount: true,
        });

    const useReviewThumbnailUrl = (assetId: string) =>
        useQuery({
            queryKey: ["thumbnailUrls", assetId],
            async queryFn() {
                const url =
                    (await acpClient.getThumbnailRenditionUrl(assetId)) || "";
                await validateGetUrl(url);
                return url;
            },
            refetchOnMount: true,
        });

    const useGlbUrl = (assetId: string) =>
        useQuery({
            queryKey: ["glbUrls", assetId],
            async queryFn() {
                const url = (await acpClient.getGlbUrl(assetId)) || "";
                await validateGetUrl(url);
                return url;
            },
        });

    const useUsdzUrl = (assetId: string) =>
        useQuery({
            queryKey: ["usdzUrls", assetId],
            async queryFn() {
                return (await acpClient.getUsdzUrl(assetId)) || "";
            },
        });

    const getEnvironmentMeta = (assetId: string) =>
        acpClient.getTypedChildMetadata(
            assetId,
            "reviewer-meta",
            EnvironmentMetaFields,
        );

    const useEnvironmentMeta = (assetId: string) =>
        useQuery({
            queryKey: ["environmentalMeta", assetId],
            async queryFn() {
                const meta = await getEnvironmentMeta(assetId);
                console.log("got meta", meta);
                return meta;
            },
            refetchOnMount: true,
        });

    const useMutateEnvironmentMeta = (assetId: string) =>
        useMutation({
            async mutationFn(
                data: Partial<
                    Record<(typeof EnvironmentMetaFields)[number], string>
                >,
            ) {
                await acpClient.setTypedChildMetadata(
                    assetId,
                    "reviewer-meta",
                    data,
                );
            },
            onSuccess() {
                console.log("Invalidate envMeta", assetId);
                queryClient.invalidateQueries({
                    queryKey: ["environmentalMeta", assetId],
                });
            },
        });

    const useOriginalFileFormat = (assetId: string) =>
        useQuery({
            queryKey: ["originalFileFormat", assetId],
            async queryFn() {
                return (
                    (await acpClient.getSourceDocPath(assetId))
                        .split(".")
                        .pop() || "unknown"
                );
            },
        });

    const useRenameReviewMutation = () =>
        useMutation({
            async mutationFn({
                assetId,
                name,
            }: {
                assetId: string;
                name: string;
            }) {
                await acpClient.renameComposite(assetId, name);
                return assetId;
            },
            onSuccess(assetId) {
                queryClient.invalidateQueries({ queryKey: ["myReviews"] });
                queryClient.invalidateQueries({
                    queryKey: ["reviewListItem", assetId],
                });
            },
        });

    const useDiscardReviewMutation = () =>
        useMutation({
            async mutationFn(assetId: string) {
                await acpClient.discardComposite(assetId);
                return assetId;
            },
            onSuccess(assetId) {
                queryClient.invalidateQueries({ queryKey: ["myReviews"] });
                queryClient.invalidateQueries({
                    queryKey: ["reviewListItem", assetId],
                });
            },
        });

    return {
        acpClient,
        queryClient,
        // queries
        useStorageQuota,
        useMyReviews,
        useSharedWithMeReviews,
        useReviewListItem,
        useCollaboratorCount,
        useReviewThumbnailUrl,
        useGlbUrl,
        useUsdzUrl,
        getEnvironmentMeta,
        useEnvironmentMeta,
        useOriginalFileFormat,
        // mutations
        useMutateEnvironmentMeta,
        useRenameReviewMutation,
        useDiscardReviewMutation,
    };
}

function AcpClientProvider({ children }: PropsWithChildren) {
    const contextValue = useAcpContextValue();

    return (
        <AcpContext.Provider value={contextValue}>
            {children}
        </AcpContext.Provider>
    );
}

export function AcpContextProvider({ children }: PropsWithChildren) {
    return (
        <PersistQueryClientProvider
            client={acpQueryClient}
            persistOptions={{ persister }}>
            <AcpClientProvider>{children}</AcpClientProvider>
        </PersistQueryClientProvider>
    );
}
