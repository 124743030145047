/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";
import { useSearchParams } from "react-router-dom";

import { AccessRequestedView } from "../../components/AccessRequestedView";
import { usePostMessageContext } from "../../contexts/PostMessageContext";

export function AccessRequestedDialog() {
    const { postMessageBusApi } = usePostMessageContext();
    const [searchParams] = useSearchParams();
    const success = searchParams.get("success") || undefined;
    const statusCode = searchParams.get("status-code") || undefined;

    function goHomeCallback() {
        if (postMessageBusApi) postMessageBusApi.showDialog("log-in-check");
    }

    return (
        <View paddingTop="size-100" paddingX="size-100">
            <AccessRequestedView
                success={success}
                statusCode={statusCode}
                onGoHome={goHomeCallback}
                isForVr={true}
            />
        </View>
    );
}
