import { ENV } from "./config";

import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { nrConfig } from "./lib/new-relic-config";

switch (ENV) {
    case "local":
        break;
    case "feature":
        break;
    case "dev":
        break;
    default:
        break;

    case "stage":
        new BrowserAgent(nrConfig["stage"])
        break;
    case "prod":
        new BrowserAgent(nrConfig["prod"])
        break;

}
