/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useOpenInVR } from "../hooks/useOpenInVR";
import { getHomePath, getReviewPath } from "../util/PathUtils";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

export function MetaSSO() {
    const location = useLocation();
    const navigate = useNavigate();
    const hashString = location.hash && location.hash.substring(1);
    const { accessToken } = useHi5UserContext();
    const { getMetaId } = useOpenInVR(accessToken);
    const { acpClient } = useAcpContext();

    useEffect(() => {
        if (!accessToken) return;
        if (accessToken === undefined) {
            // not signed in, redirect to Home
            navigate(getHomePath());
            return;
        }
    }, [accessToken]);

    async function getMetaUserData(orgScopedId: string) {
        const cloudDir = await acpClient.getDirectory("cloud-content");
        const cloudDirAssetId = cloudDir["repo:assetId"];

        if (!(cloudDirAssetId && orgScopedId)) return;
        const metaUserData = await getMetaId(orgScopedId);
        if (!metaUserData || !metaUserData.userId) {
            // error getting userId from Meta, redirect to Home
            navigate(getHomePath());
            return;
        }
        const { userId, userIdAlias } = metaUserData;

        // save id and alias into cloudContentApplicationMetadataUrl so we don't have to prompt user to link Meta account again
        await acpClient.setApplicationMeta(
            cloudDirAssetId,
            {
                "highfive:metaUserId": userId,
                "highfive:metaUserIdAlias": userIdAlias,
            }

        );

        // success, navigate back to review that the SSO was initiated from
        const savedReviewUrn = localStorage.getItem("savedReviewUrn");
        if (savedReviewUrn)
            navigate(`${getReviewPath(savedReviewUrn)}&accountLinked=true`);
    }

    useEffect(() => {
        if (!hashString) {
            // missing hash string, redirect to Home
            navigate(getHomePath());
            return;
        }
        const parsedData = JSON.parse(atob(hashString));
        if (parsedData && parsedData.org_scoped_id) {
            getMetaUserData(parsedData.org_scoped_id);
        } else {
            // error getting data from Meta, redirect to Home
            console.error("error connecting Meta SSO");
            navigate(getHomePath());
        }
    }, [hashString]);

    return <div />;
}
