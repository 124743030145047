/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type { ACPRepoMetadataResource } from "@dcx/assets/types/src/Asset";
import type { AdobeRepoMetadata } from "@dcx/common-types";

export interface ReviewListItem {
    assetId: string;
    name: string;
    displayName: string;
    createDate: string;
    modifyDate: string;
    createdBy: string;
}

const CONTRIB = "repo:contributors";

export function dcxRawToReviewListItem(
    assetData: ACPRepoMetadataResource,
): ReviewListItem {
    if (
        !assetData["repo:name"] ||
        !assetData["repo:assetId"] ||
        !assetData["repo:createDate"] ||
        !assetData["repo:createdBy"] ||
        !assetData["repo:modifyDate"]
    ) {
        throw new Error("dcxRawToReviewListItem: Invalid Adobe Asset");
    }
    return {
        assetId: assetData["repo:assetId"],
        name: assetData["repo:name"],
        displayName: assetData["repo:name"].replace(".usdcx", ""),
        createDate: assetData["repo:createDate"],
        modifyDate: assetData["repo:modifyDate"],
        createdBy: assetData["repo:createdBy"],
        // @ts-ignore this exists I swear
        collaboratorCount: assetData[CONTRIB] ? assetData[CONTRIB].length : 1,
    };
}

export function dcxToReviewListItem(data: AdobeRepoMetadata): ReviewListItem {
    // @ts-ignore the types are wrong these exist
    const { name, assetId, createDate, createdBy, modifyDate } = data;
    if (!createdBy) {
        throw new Error("dcxToReviewListItem: Invalid Adobe Asset");
    }
    return {
        assetId,
        name,
        displayName: name.replace(".usdcx", ""),
        createdBy,
        createDate,
        modifyDate,
    };
}

export function isUsdDcx(assetData: ACPRepoMetadataResource) {
    return assetData["dc:format"] === "application/vnd.adobe.usdcx+dcx";
}
