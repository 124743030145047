/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { usePostMessageContext } from "../../contexts/PostMessageContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { checkUserAccess } from "@src/lib/invitation/Access";

export function AccessCheck() {
    const { postMessageBusApi } = usePostMessageContext();
    const { accessToken } = useHi5UserContext();

    const [searchParams] = useSearchParams();
    const assetId = searchParams.get("urn") || "";

    async function checkAccess() {
        if (!(postMessageBusApi && assetId)) return;
        if (!accessToken || accessToken === "") return; // default value - access token hasn't yet been checked

        postMessageBusApi.debug(`"urn is ${assetId}"`);
        postMessageBusApi.debug(`"checking access token ${accessToken}"`);

        if (accessToken === undefined) {
            // accessToken checked and not found
            // sign in required to access asset
            postMessageBusApi.showDialog("log-in-required", `urn=${assetId}`);
            return;
        }

        const responseStatus = await checkUserAccess(accessToken, assetId);
        postMessageBusApi.debug("checking access status");
        if (!responseStatus) {
            console.error("unexpected error from checkUserAccess");
            return;
        }
        switch (responseStatus) {
            // no unauthenticated cases as VR app requires login to check for license
            case 200:
                // user has access to asset
                postMessageBusApi.showDialog("enter-review", `urn=${assetId}`);
                break;
            case 401:
                // sign in required to access asset
                postMessageBusApi.showDialog(
                    "log-in-required",
                    `urn=${assetId}`,
                );
                break;
            case 403:
                // user does not have access to asset
                postMessageBusApi.showDialog(
                    "access-required",
                    `urn=${assetId}`,
                );
                break;
            case 404:
                // asset does not exist
                postMessageBusApi.showDialog("asset-not-found");
                break;
            default:
                console.error(
                    "unexpected response from checkUserAccess with status: ",
                    responseStatus,
                );
                postMessageBusApi.webError(
                    `unexpected response from checkUserAccess with status: ${responseStatus}`,
                );
                break;
        }
    }

    useEffect(() => {
        checkAccess();
    }, [postMessageBusApi, accessToken, assetId]);

    return <div>Checking access...</div>;
}
