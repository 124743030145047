/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { JanusRoutes } from "@shared/common";

import { sessionIdKey } from "./useAnalytics";
import {
    ASSET_APIS,
    HI5_API_KEY,
    HI5_WEBVIEW_API_KEY,
    JANUS_URL,
    baseUrl,
} from "../config";

export function useOpenInVR(accessToken?: string, assetUrn?: string) {
    const appId = "6119156544771306";
    const appKey = "d36ac4f219e6a6b5ab30becbe23e8373";
    const orgId = "118209529739453";

    async function getJumpUrl() {
        const redirectUrl = `${baseUrl}${JanusRoutes.accessCheck.path}?urn=${assetUrn}&region=US&sessionId=${
            sessionStorage.getItem(sessionIdKey) ?? ""
        }`;

        const response = await fetch(
            `${
                ASSET_APIS.ims
            }/ims/jumptoken/v1?client_id=${HI5_API_KEY}&target_client_id=${HI5_WEBVIEW_API_KEY}&target_redirect_uri=${encodeURIComponent(
                redirectUrl,
            )}&target_remember_me=true`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
        const parsedBody = await response.json();

        let jumpUrl = "";
        if (parsedBody && parsedBody.jump) {
            const failRedirectUrl = `${baseUrl}${JanusRoutes.logInCheck.path}`;
            jumpUrl = `${parsedBody.jump}?jump_fail_uri=${failRedirectUrl}`;
        } else {
            console.error("error creating jump url");
        }
        console.log("jumpUrl", jumpUrl);
        return jumpUrl;
    }

    async function linkAccount() {
        // save the current assetUrn so that we can redirect to it after completing Meta SSO
        if (assetUrn) localStorage.setItem("savedReviewUrn", assetUrn);
        const redirectUrl = window.location.href.includes("localhost") ? "https://localhost.adobe.com:8080" : baseUrl;
        window.location.href = `https://auth.oculus.com/sso/?redirect_uri=${redirectUrl}/meta-sso&organization_id=${orgId}`;
    }

    async function getMetaId(orgScopedId: string) {
        const access_token = `OC|${appId}|${appKey}`;
        const response = await fetch(
            `https://graph.oculus.com/${orgScopedId}?access_token=${access_token}&fields=id,alias`,
            {
                method: "GET",
                cache: "default",
            },
        );
        const parsedBody = await response.json();
        console.log("getMetaId", parsedBody);

        if (!(parsedBody && parsedBody.id)) {
            console.error("error getting Meta user id");
            return;
        }
        return { userId: parsedBody.id, userIdAlias: parsedBody.alias };
    }

    // commenting out getMetaAuth and (old) getMetaId for now while we have a workaround
    // may later be needed once org scoped user sessions are supported

    // async function getMetaAuth(accessCode: string, orgScopedId: string) {
    //     const body = new FormData();
    //     body.append("code", accessCode);
    //     body.append("access_token", `OC|${appId}|${appKey}`);
    //     body.append("org_scoped_id", orgScopedId);

    //     const response = await fetch(
    //         `https://graph.oculus.com/sso_authorize_code`,
    //         {
    //             method: "POST",
    //             cache: "default",
    //             body,
    //         },
    //     );
    //     const parsedBody = await response.json();
    //     console.log("getMetaAuth", parsedBody);

    //     if (!(parsedBody && parsedBody.oauth_token)) {
    //         console.error("error getting Meta auth token");
    //         return;
    //     }
    //     return parsedBody.oauth_token;
    // }

    // async function getMetaId(accessCode: string, orgScopedId: string) {
    //     const authToken = await getMetaAuth(accessCode, orgScopedId);
    //     if (!authToken) return;

    //     const response = await fetch(
    //         `https://graph.oculus.com/me?access_token=${authToken}&fields=id,alias`,
    //         {
    //             method: "GET",
    //             cache: "default",
    //         },
    //     );
    //     const parsedBody = await response.json();
    //     console.log("getMetaId", parsedBody);

    //     if (!(parsedBody && parsedBody.id)) {
    //         console.error("error getting Meta user id");
    //         return;
    //     }
    //     return parsedBody.id;
    //     return orgScopedId;
    // }

    /**
     * Generates an Oculus public Group Launch deep link https://developer.oculus.com/documentation/native/ps-group-launch/#create-a-public-group-launch-deep-link
     * @returns ComponentData
     */
    async function generateVrLink(metaUserId: string | undefined) {
        if (!metaUserId) return;
        const jumpUrl = await getJumpUrl();

        const body = new FormData();
        body.append("access_token", `OC|${appId}|${appKey}`);
        body.append("destination_api_name", "ProjectHighFive");
        body.append("create_room", "false");
        body.append("fields", "url");
        body.append("deeplink_message_override", jumpUrl);
        body.append("valid_for", "1"); // 1 hour validity
        body.append("leader", metaUserId);

        const response = await fetch(
            `https://graph.oculus.com/${appId}/app_deeplink_private`,
            {
                method: "POST",
                cache: "default",
                body,
            },
        );
        const parsedBody = await response.json();
        return parsedBody.url;
    }

    return { generateVrLink, linkAccount, getMetaId };
}
