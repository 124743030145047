/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";
import { useSearchParams } from "react-router-dom";

import { AccessRequiredView } from "../../components/AccessRequiredView";
import { usePostMessageContext } from "../../contexts/PostMessageContext";

export function AccessRequiredDialog() {
    const { postMessageBusApi } = usePostMessageContext();
    const [searchParams] = useSearchParams();
    const urn = searchParams.get("urn") || "";

    function goHomeCallback() {
        if (postMessageBusApi) postMessageBusApi.showDialog("log-in-check");
    }

    function onSuccessCallback() {
        if (postMessageBusApi) {
            postMessageBusApi.showDialog("access-requested", `success=true`);
        }
    }

    function onNotFoundCallback() {
        if (postMessageBusApi) postMessageBusApi.showDialog("asset-not-found");
    }

    function onFailureCallback(statusCode: string) {
        if (postMessageBusApi) {
            postMessageBusApi.showDialog(
                "access-requested",
                `success=false&status-code=${statusCode}`,
            );
        }
    }

    function switchAccountsCallback() {
        if (postMessageBusApi) {
            postMessageBusApi.clearCookies();
            postMessageBusApi.resizeWindow("log-in-required-susi");
        }
    }

    return (
        <View maxHeight="686" paddingTop="size-100" paddingX="size-100">
            <AccessRequiredView
                assetId={urn}
                onGoHome={goHomeCallback}
                onSuccess={onSuccessCallback}
                onNotFound={onNotFoundCallback}
                onFailure={onFailureCallback}
                onSwitchAccounts={switchAccountsCallback}
                isForVr={true}
            />
        </View>
    );
}
