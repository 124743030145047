/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { getAuthHeaders } from "../ims/Auth";
import { ASSET_APIS } from "@src/config";

export async function getCollaboratorsCount(accessToken: string, assetId: string): Promise<number> {
    const response = await fetch(
        `${ASSET_APIS.invitations}/share/${assetId}`,
        {
            headers: getAuthHeaders(accessToken),
        },
    );
    if (response.status != 200) {
        console.error("error getting collaborators", response.statusText);
        return 1;
    }
    const parsedBody = await response.json();
    if (parsedBody) {
        let collaborators = parsedBody.collaborators;
        if (
            collaborators.find(
                (collaborator: any) =>
                    collaborator.id == "all" &&
                    collaborator.commentPermissions.includes("read"),
            )
        ) {
            // asset is accessible to anyone with link
            return -1;
        }
        collaborators = collaborators.filter(
            (collaborator: any) =>
                collaborator.type == "imsUser" ||
                collaborator.type == "addressBookContact",
        );
        return collaborators.length;
    }
    return 1;
}
