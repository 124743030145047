/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import EventEmitter from "events";

import type {
    JobMessage,
    GraphSpec,
    JobStatus,
    Job as JobType,
    JobCreate,
} from "@shared/types/src";

export type JobsApiConfig = {
    url: string;
    key: string;
};



export async function getCompletedJob(
    accessToken: string,
    job: JobCreate,
    apiConfig: JobsApiConfig,
) {
    const jobsClient = new JobClient(
        accessToken,
        apiConfig,
        job.spec as GraphSpec,
    );
    await jobsClient.jobCompletePromise;
    return jobsClient.state;
}

const TOTAL_SUBMIT_RETIRES = 3;
const TOTAL_POLL_RETIRES = 10; // Only counts failures

export interface JobClientEvents {
    submit: [JobClient];
    progress: [JobClient];
    complete: [JobClient];
    failed: [JobClient];
}
export class JobClient extends EventEmitter<JobClientEvents> {
    private headers: Record<string, string>;
    private baseUrl: string;

    public spec: GraphSpec | undefined;

    state: "SUBMITTING" | JobStatus["state"] = "SUBMITTING";
    messages: JobMessage[] = [];
    progress = 0;

    jobId: string | undefined;
    jobUrl: string | undefined;

    jobCompletePromise: Promise<JobClient>;

    constructor(
        accessToken: string,
        apiConfig: JobsApiConfig,
        specOrExistingJobID: GraphSpec | string,
    ) {
        super();
        this.headers = {
            "content-type": "application/json",
            authorization: "Bearer " + accessToken,
            "x-api-key": apiConfig.key,
        };
        this.baseUrl = `${apiConfig.url}/jobs`;

        if (typeof specOrExistingJobID === "string") {
            this.setJobId(specOrExistingJobID);
            this.jobCompletePromise = this.pollStatus();
        } else {
            this.spec = specOrExistingJobID;
            this.jobCompletePromise = this.submitJob();
        }
    }

    private setJobId(jobId: string) {
        this.jobId = jobId;
        this.jobUrl = `${this.baseUrl}/${jobId}`;
    }

    submitRetries = 0;

    private async submitJob(): Promise<JobClient> {
        try {
            const response = await fetch(this.baseUrl, {
                method: "POST",
                headers: this.headers,
                body: JSON.stringify({
                    type: "usd.beta",
                    spec: this.spec,
                }),
            });
            const data = await response.json();
            if (!data.jobId) {
                throw new Error("Failed to get jobId");
            }
            this.setJobId(data.jobId);
            this.emit("submit", this);
            return await this.pollStatus();
        } catch (e) {
            console.error("Error submitting job", e);
            if (this.submitRetries < TOTAL_SUBMIT_RETIRES) {
                this.submitRetries++;
                return await this.submitJob();
            }
        }
        return this;
    }

    pollRetryCount = 0;

    private pollStatus(): Promise<JobClient> {
        return new Promise<JobClient>((res, rej) => {
            const jobCheckInterval = setInterval(async () => {
                try {
                    if (!this.jobUrl) {
                        throw new Error("Job not submitted");
                    }

                    const statusResponse = await fetch(this.jobUrl, {
                        headers: this.headers,
                    });

                    const statusData = (await statusResponse.json()) as JobType;
                    this.state = statusData.status.state;
                    this.progress = statusData.status.completion;

                    if (!this.spec) {
                        this.spec = statusData.spec as GraphSpec;
                    }

                    this.emit("progress", this);

                    if (
                        statusData.status.state === "COMPLETED" ||
                        statusData.status.state === "FAILED" ||
                        statusData.status.state === "CANCELED"
                    ) {
                        clearInterval(jobCheckInterval);
                        if (statusData.status.state === "COMPLETED") {
                            this.emit("complete", this);
                        } else {
                            this.emit("failed", this);
                        }
                        res(this);
                    }
                } catch (e) {
                    console.error("Failed to poll for job status", e);
                    if (this.pollRetryCount < TOTAL_POLL_RETIRES) {
                        this.pollRetryCount++;
                    } else {
                        clearInterval(jobCheckInterval);
                        rej(this);
                    }
                }
            }, 3000);
        });
    }

    async cancel() {
        if (this.jobUrl) {
            await fetch(this.jobUrl, {
                method: "DELETE",
                headers: this.headers,
            });
        }
    }
}
