/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Heading,
    IllustratedMessage,
    ProgressBar,
    Link,
    Text,
    View,
    Button,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { mediaTypeModelUsd } from "@shared/types";
import UploadIcon from "@spectrum-icons/illustrations/Upload";
import CancelIcon from "@spectrum-icons/workflow/Cancel";
import { useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { useUploadAsset } from "@src/hooks/useUploadAsset";

import type { FC } from "react";

const MAX_FILE_SIZE = 1024 * 1024 * 500; // 500MB
const MAX_NUMBER_OF_FILES = 1;

const types = [...mediaTypeModelUsd];

const dropAcceptTypes = {
    "application/binary": types,
};

interface Props {
    onComplete: (assetId: string) => void;
}

export const AssetUploadView: FC<Props> = ({ onComplete }) => {
    const { t } = useTranslation("web");

    const {
        uploadAndProcessAsset,
        isUploading,
        uploadProgress,
        processingProgress,
        isProcessing,
        cancel,
    } = useUploadAsset();

    const [dragErrorState, setDragErrorState] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);

    const onDrop = (acceptedFiles: File[], rejections: FileRejection[]) => {
        setIsDragOver(false);
        if (acceptedFiles.length === 1) {
            setDragErrorState(false);
            uploadAndProcessAsset(acceptedFiles[0], (assetId) => {
                if (assetId) {
                    onComplete(assetId);
                }
            }).catch(() => {
                ToastQueue.negative(
                    t("web:toast.upload.error.fileConversion", {
                        timeout: 5000,
                    }),
                );
            });
        } else if (rejections.length > 1) {
            setDragErrorState(true);
            console.error("Expected exactly one file to upload");
            ToastQueue.negative(t("web:toast.upload.uploadSingleFile"), {
                timeout: 5000,
            });
        }
    };

    const onDragEnter = () => {
        setDragErrorState(false);
        setIsDragOver(true);
    };

    const onDragLeave = () => {
        setIsDragOver(false);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: dropAcceptTypes,
        onDragEnter,
        onDragLeave,
        maxSize: MAX_FILE_SIZE,
        maxFiles: MAX_NUMBER_OF_FILES,
        multiple: false,
    });

    function doStupidHack() {
        // The dropzone uses window.showOpenFilePicker() which won't support file extensions with _ in them
        // This is a hack to trigger the file selection dialog without calling that method so we can support
        // x_t and x_b extensions. (S4RVR-2031)[https://jira.corp.adobe.com/browse/S4RVR-2031]
        // @ts-ignore
        document.querySelector("#dropper input")?.click();
    }

    function content() {
        if (isUploading) {
            return (
                <Flex
                    key="upload"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    height="size-2400">
                    <ProgressBar
                        width="60%"
                        size="L"
                        label={t("accessibility.progress.uploading")}
                        value={uploadProgress}
                        valueLabel={t(
                            "accessibility.progress.uploading.percent",
                            { val: uploadProgress / 100 },
                        )}
                    />
                    <Button
                        marginTop="size-600"
                        style="fill"
                        variant="primary"
                        onPress={cancel}>
                        <CancelIcon />
                        <Text>{t("upload.button.cancel")}</Text>
                    </Button>
                </Flex>
            );
        } else if (isProcessing) {
            return (
                <Flex
                    key="process"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    height="size-2400">
                    <ProgressBar
                        width="60%"
                        size="L"
                        label={t("accessibility.progress.processing")}
                        value={processingProgress * 100}
                        valueLabel={t(
                            "accessibility.progress.uploading.percent",
                            { val: processingProgress },
                        )}
                    />
                    <Button
                        marginTop="size-600"
                        style="fill"
                        variant="primary"
                        onPress={cancel}>
                        <CancelIcon />
                        <Text>{t("upload.button.cancel")}</Text>
                    </Button>
                </Flex>
            );
        } else {
            return (
                <View
                    id="dropper"
                    borderColor={
                        dragErrorState
                            ? "red-400"
                            : isDragOver
                              ? "blue-600"
                              : "gray-300"
                    }
                    borderWidth="thick"
                    borderRadius="medium">
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <View padding="size-300" paddingTop="size-600">
                            <IllustratedMessage maxHeight="size-3000">
                                <UploadIcon />
                                <Heading>{t("upload.title")}</Heading>
                                <View marginTop="size-150">
                                    <Text>
                                        <Link variant="primary" onPress={doStupidHack}>
                                            {t("upload.fileLink")}
                                        </Link>{" "}
                                        {t("upload.fromComputer")}
                                    </Text>
                                </View>
                            </IllustratedMessage>
                        </View>
                    </div>
                </View>
            );
        }
    }
    return (
        <View
            backgroundColor="gray-200"
            paddingX="4vh"
            paddingY="4vh"
            borderRadius="medium">
            {content()}
        </View>
    );
};
