export const nrConfig = {
    stage: {
        loader_config: {
            accountID: "2477989",
            trustKey: "1322840",
            agentID: "1835083718",
            licenseKey: "NRBR-50be04f848279d557fa",
            applicationID: "1835083718",
        },
        info: {
            beacon: "bam-cell.nr-data.net",
            errorBeacon: "bam-cell.nr-data.net",
            licenseKey: "NRBR-50be04f848279d557fa",
            applicationID: "1835083718",
            sa: 1,
        },
    },
    prod: {
        loader_config: {
            accountID: "2477989",
            trustKey: "1322840",
            agentID: "1835083260",
            licenseKey: "NRBR-50be04f848279d557fa",
            applicationID: "1835083260",
        },
        info: {
            beacon: "bam-cell.nr-data.net",
            errorBeacon: "bam-cell.nr-data.net",
            licenseKey: "NRBR-50be04f848279d557fa",
            applicationID: "1835083260",
            sa: 1,
        },
    },
}