/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import type {
    ConnectableNode,
    NodeFromType,
    OutputableNode,
    FileType,
    JobCallbackFn,
    _Node,
} from "./types.js";

import { genericInputNodes } from "@shared/types";

function makeOutputObject(str: string): FileType {
    const arr = str.split('/');
    return {
        type: arr[0],
        extension: arr[1],
    } as FileType;
}

const namePrefix = 'node';
export class JobGraph {
    ctr: number;
    nodes: Array<any>;
    handler: any;
    get body() {
        const ret: Record<string, any> = {
            type: 'usd.beta',
            spec: {},
        };
        for (let i = 1; i < this.nodes.length; i++) {
            const nodeId = namePrefix + i;
            const obj = { ...this.nodes[i] };
            // remove excess stuff
            delete obj.idx;
            Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
            ret.spec[nodeId] = obj;
        }
        return ret;
    }
    private makeHandler  = (that: JobGraph) => {
        return {
            get<T extends ConnectableNode, K extends NodeFromType<OutputableNode>>(target: K, prop: T, receiver: any) {
                // @ts-ignore
                if (prop === 'toJSON' || prop === 'idx') {
                    return Reflect.get(target, prop, receiver);
                }
                const inputNode = target;
                const nodeType: T = prop;
                that.ctr++;
                const idx = that.ctr;
                const inputIdx = inputNode.idx;
                // @ts-ignore
                const callback: ConnectableNodeConstructor<T, InputTypeFallback<NodeOutputType<K['type']>>> = (...args) => {
                    // figuring out in javascript land which arguments we got in the method
                    let inputs, parameters, output;
                    if (inputIdx === 0) {
                        // no input node as inherits from root
                        if (typeof args[0] === 'string'){
                            inputs = undefined;
                            parameters = undefined;
                            output = makeOutputObject(args[0]);
                        } else {
                            inputs = undefined;
                            parameters = args[0];
                            output = makeOutputObject(args[1]);
                        }
                    } else {
                        // yes there's an implicit input
                        // @ts-ignore
                        const inputType = genericInputNodes.includes(nodeType)
                            ? 'file'
                            : that.nodes[inputIdx].output.type;
                        inputs = {
                            [inputType]: namePrefix + inputIdx,
                        };
                        if (args.length === 3) {
                            inputs = { ...inputs, ...makeInputs(args[0]), };
                            parameters = args[1];
                            output = makeOutputObject(args[2]);
                        } else if (args.length === 2) {
                            // switch on whether output present
                            if (typeof args[1] === 'string'){
                                // how to distinguish between parameter or multi-input?
                                // TODO fix this, handle multi-input
                                const isFirstInput = checkIfInput(args[0]);
                                if (isFirstInput) {
                                    inputs = {
                                        ...inputs,
                                        ...makeInputs(args[0]),
                                    };
                                } else {
                                    parameters = args[0];
                                }
                                output = makeOutputObject(args[1]);
                            } else {
                                // no output step
                                inputs = { ...inputs, ...makeInputs(args[0]) };
                                parameters = args[1];
                                output = undefined;
                            }
                        } else if (args.length === 1) {
                            // TODO - as of now, there's no multi-input but no parameter operation
                            if (typeof args[0] === 'string'){
                                parameters = undefined;
                                output = makeOutputObject(args[0]);
                            } else {
                                parameters = args[0];
                                output = undefined;
                            }
                        }
                    }
                    that.nodes[idx] = {
                        idx,
                        type: nodeType,
                        parameters,
                        output,
                        inputs,
                    };
                    return new Proxy(that.nodes[idx], that.handler as any);
                };
                return callback;
            }
        }};
    constructor(callback: JobCallbackFn) {
        const idx = 0;
        this.ctr = idx;
        this.nodes = [{ idx } as _Node];
        this.handler = this.makeHandler(this);
        callback(new Proxy(this.nodes[idx], this.handler as any));
    }
}

function checkIfInput(obj: any): boolean {
    return typeof obj === 'object' && Object.keys(obj).every((x: any) => obj[x]?.idx);
}

function makeInputs(obj: any): Record<string, string> {
    if (typeof obj === 'object') {
        const ret: Record<string, string> = {};
        Object.keys(obj).forEach(x => {
            const input = obj[x];
            // NOTE: this prop lookup needs to bypass object proxy
            const idx = input?.idx;
            if (idx === undefined) throw new Error('ArgInputNoIdx');
            ret[x] = namePrefix + idx;
        });
        return ret;
    } else throw new Error('ArgNotInput');
}
