/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

// This file needs to import in both browser and node envs
import { mediaTypeScene, mediaTypeMaterialImport, mediaTypeImageImport, mediaTypeModelImport } from "@shared/types";

import { templateCatalog } from "./sunrise-templates/templateCatalog.js";

import type { JobStatus, IBLToLightDir } from "@shared/types";
export { JobGraph } from "./jobs/graph.js";
export * from "./chunk-uploader.js";
export * from "./invitationsApiClient.js";
export * as Jobs from "./jobs/index.js";
export * as IrisJobFields from "./iris-job-fields.js";
export * as PromiseHelpers from "./promiseHelpers.js";
export * from "./types/services.js";
export const { assetTemplate, projectTemplates } = templateCatalog;
export * from "./sunrise-templates/templateUtils.js";

export const config = {
    imsClientId: "Substance3DAutomation",
    imsClientIdHelios: "sunrise-client",
};

/**
 * @param svcEnv One of "prod", "stage", "dev", "feature", or "local"
 * @param host The helios host, e.g., pr-2248-sunrise.corp.ethos503-stage-va6.ethos.adobe.net
 *          Only used for "feature" environments.
 * @returns "sunrise" for prod; "sunrise/\<env\>" for stage, dev, and local; and
 *          sunrise/pr-1234 for feature environments.
 */
export const getSunriseEnv = (svcEnv: string, host: string) => {
    let envStr = `/${svcEnv}`;
    switch (svcEnv) {
        case "prod":
            envStr = "";
            break;
        case "feature":
            envStr = `/${host.split("-", 2).join("-")}`;
            break;
        default:
            break;
    }
    return `sunrise${envStr}`;
};

export type Route = {
    path: string;
    name: string;
    key?: string;
};
export type AppRoutes = Record<string, Route>;
export const CalliopeAppRoutes = {
    REPL: { key: "repl", name: "REPL", path: "/repl" },
    ADMIN_PANEL: { key: "adminPanel", name: "Admin Panel", path: "/admin" },
    HOME: { key: "home", name: "Upload", path: "/" },
    PROJECT: { key: "project", name: "Project", path: "/project/:projectId/" },
    TEMPLATE: {
        key: "template",
        name: "Template",
        path: "/project/:projectId/template/:templateId/",
    },
    CONFIGURE: {
        key: "recipe",
        name: "Recipe",
        path: "/template/:templateId/recipe/:configId/",
    },
    RENDER: {
        key: "render",
        name: "Render",
        path: "/template/:templateId/render/:renderId/",
    },
};

export const HeliosRoutes = {
    index: { path: "/", name: "Home" },
    config: { path: "/config", name: "Configure"},
    library: { path: "/library?", name: "Library" },
    projects: {
        path: "/projects/:projectId?/",
        name: "Projects",
    },
    search: {
        path: "/search/:searchValue",
        name: "search",
    },
    searchLanding: {
        path: "/search/",
        name: "searchLanding",
    },
    modelEditor: {
        path: "/projects/:projectId/shot/:shotId/editor",
        name: "Project Editor",
    },
    modelEditorPreview: {
        path: "/projects/:projectId/shot/:shotId/editor/preview",
        name: "Project Viewer Preview",
    },
    projectOutputDetailView: {
        path: "/projects/:projectId/shot/:shotId/detail/",
        name: "Project Output Detail",
    },
    limitedProjectDetailReview: {
        path: "/projects/review/:projectId/shot/:shotId/detail/",
        name: "Limited Project Output Detail",
    },
    studioStandAlone: {
        path: "/studio/stand-alone",
        name: "Stand Alone Model Viewer",
    },
    testLibrary: { path: "/testLibrary", name: "Test Library" },
    unauthorize: { path: "/auth", name: "Unauthorized" },
    notFound: { path: "/404", name: "Not Found" },
    unavailable: { path: "/503", name: "Service Unavailable" },
    libraryDetailView: {
        path: "/library/assets/:assetId/detail/:viewType",
        name: "Asset Detail",
    },
    limitedProjectReview: {
        path: "/projects/review/:projectId/",
        name: "Limited Project Review",
    },

    limitedAssetReview: {
        path: "/library/review/:assetId/detail/:viewType",
        name: "Asset Detail Review",
    },
    shareAssetLanding: {
        path: "/review",
        name: "Asset Review",
    },
    arViewer: {
        path: "/review/ar/:assetId",
        name: "AR Viewer",
    },
};

export const ArgusRoutes = {
    HOME: { key: "home", name: "Job Queries", path: "/admin/" },
    JOB: { key: "job", name: "Job", path: "/admin/job/:jobId" },
    PERFORMANCE: {key: "performance", name: "Job Performance", path: "/test-results/performance"},
    HEALTHCHECK: {key: "healthcheck", name: "Job Health Check", path: "/test-results/health-check"},
    QUALITATIVE: {key: "qualitative", name: "Job Quality", path: "/test-results/qualitative"},
    QUALITATIVE_HISTORY: {key: "qualitative-history", name: "Job Quality History", path: "/test-results/qualitative/history/:testType/:assetSha"},
    QUALITATIVE_SINGLE: {key: "qualitative-single", name: "Job Quality Single Test", path: "/test-results/qualitative/test/:testId"},
};

export const JanusRoutes = {
    index: {
        path: "/",
        name: "Hi5 Web Portal",
    },
    home: {
        path: "/home",
        name: "Hi5 Web Portal",
    },
    homeModal: {
        path: "/home/:modal/:assetId",
        name: "Import Modal",
    },
    twoUp: {
        path: "/view",
        name: "Comment and Pin 3d",
    },
    twoUpModal: {
        path: "/view/enter",
        name: "Enter Review Two Up"
    },
    oneUp: {
        path: "/comment",
        name: "Comment",
    },
    noUp: {
        path: "/comment-headless",
        name: "Comment API Only",
    },
    logInCheck: {
        path: "/log-in-check",
        name: "VR log in check",
    },
    startScreen: {
        path: "/start-screen",
        name: "VR start screen",
    },
    signedOut: {
        path: "/signed-out",
        name: "VR signed out",
    },
    assertLogOut: {
        path: "/assert-log-out",
        name: "VR assert log out",
    },
    accessCheck: {
        path: "/access-check",
        name: "VR access check",
    },
    enterReview: {
        path: "/enter-review",
        name: "VR enter review",
    },
    logInRequired: {
        path: "/log-in-required",
        name: "VR log in required",
    },
    accessRequired: {
        path: "/access-required",
        name: "VR access required",
    },
    accessRequested: {
        path: "/access-requested",
        name: "VR access requested",
    },
    assetNotFound: {
        path: "/asset-not-found",
        name: "VR asset not found",
    },
    reportAbuse: {
        path: "/report-abuse",
        name: "Report abuse",
    },
    appSettings: {
        path: "/app-settings",
        name: "VR app settings",
    },
    legalNotice: {
        path: "/legal-notice",
        name: "Legal Notice",
    },
    aboutHighFive: {
        path: "/about-high-five",
        name: "About Project High Five",
    },
    metaSSO: {
        path: "/meta-sso",
        name: "Redirect from Meta to review page",
    },
    shareDemo: {
        path: "/share-demo",
        name: "Share demo",
    },
    shareDemoTwoUp: {
        path: "/share-demo/view",
        name: "Share demo review",
    },
    visionPro: {
        path: "/vision-pro",
        name: "Vision Pro demo",
    },
    visionProTwoUp: {
        path: "/vision-pro/view",
        name: "Vision Pro demo review",
    },
    shareAudio: {
        path: "/share-audio",
        name: "Share audio"
    }
} as const;

// Workflow for authoring these vectors based on the IBLS:
// 1.) Load the IBL from the exported files from Stager back into stager
// 2.) Rotate the view so the origin intersects with the primariy light source, with the soruce behind the origin point
// 3.) Place a sphere at this intersection and record the values
// 4.) Normalize the values
// 5.) Negate all the values to get the light pointing back to the origin
// 6.) Negate the X value back to deal with Babylon's left handed coordinate system
// 7.) Enter that set of values here in the appropriate location in the map
export const iblPrimaryLightDirMap: IBLToLightDir = {
    Studio_White_Hard_Sunlight: {
        direction: [0.63, -0.56, -0.54],
        shadowIntensity: 0.25,
    },
    Studio_White_Cosmetics_Hard_Light: {
        direction: [-0.53, -0.76, -0.37],
        shadowIntensity: 0.45,
    },
    Studio_White_Soft_Light_02: {
        direction: [0.46, -0.65, -0.61],
        shadowIntensity: 0.45,
    },
    Studio_White_Cosmetics_Packshot_01: {
        direction: [0.58, -0.76, -0.31],
        shadowIntensity: 0.55,
    },
    Studio_Color_Soft_Light_01: {
        direction: [0.36, -0.64, 0.68],
        shadowIntensity: 0.2,
    },
    Studio_White_North_Daylight: {
        direction: [0.88, -0.47, -0.01],
        shadowIntensity: 0.12,
    },
    Studio_White_Soft_Light_05: {
        direction: [0.31, -0.55, -0.78],
        shadowIntensity: 0.4,
    },
    ibl_bl_product_e_com_jewelry_wh_all_key_global: {
        direction: [-0.46, -0.65, 0.61],
        shadowIntensity: 0.4,
    },
    Studio_White_Umbrella_Hard_Light_03: {
        direction: [0.46, -0.65, -0.61],
        shadowIntensity: 0.35,
    },
    Studio_White_Umbrella_Hard_Light_01: {
        direction: [0.46, -0.65, -0.61],
        shadowIntensity: 0.35,
    },
    ibl_bl_product_sl_large_scale: {
        direction: [0.46, -0.65, -0.61],
        shadowIntensity: 0.35,
    },
    Studio_Black_Soft_Light_05: {
        direction: [0.63, -0.56, -0.54],
        shadowIntensity: 0.35,
    },
    ibl_bl_product_fsdl_large_scale_v2: {
        direction: [-0.46, -0.65, 0.61],
        shadowIntensity: 0.45,
    },
    Studio_White_Umbrella_Soft_Light: {
        direction: [0.46, -0.65, -0.61],
        shadowIntensity: 0.35,
    },
    ibl_bl_product_e_shop_soft_contrast_2: {
        direction: [0.63, -0.56, -0.54],
        shadowIntensity: 0.35,
    },
    ibl_bl_product_e_shop_toplight_contrast_1: {
        direction: [-0.1985, -0.9791, 0.0442],
        shadowIntensity: 0.45,
    },
    ibl_bl_product_e_com_transparency_wh: {
        direction: [-0.46, -0.65, 0.61],
        shadowIntensity: 0.45,
    },
    "studio_white_soft_light-1k_180_front_comp": {
        direction: [-0.455, -0.637, 0.623],
        shadowIntensity: 0.45,
    },
};

export function unionMut<T>(setA: Set<T>, setB: Set<T>): void {
    for (const elem of setB) {
        setA.add(elem);
    }
}

export function intersection<T>(setA: Set<T>, setB: Set<T>): Set<T> {
    const _intersection: Set<T> = new Set();
    for (const elem of setB) {
        if (setA.has(elem)) {
            _intersection.add(elem);
        }
    }
    return _intersection;
}

export type JobStates = JobStatus["state"];
export function isTerminalState(state: JobStatus["state"]): boolean {
    return state === "COMPLETED" || state === "FAILED" || state === "CANCELED";
}

export const MediaTypesScene = mediaTypeScene;
export const MediaTypesMaterial = mediaTypeMaterialImport;
export const MediaTypesImage = mediaTypeImageImport;
export const MediaTypesModel = mediaTypeModelImport;

// Constants for the webviewer
export const DEFAULT_ASPECT_RATIO = 1;

// If you replace the terms file in helios, run the `hash-terms` script.
// From the `components/helios` directory run (e.g.):
// $ yarn hash-terms ./public/Adobe\ Prerelease\ Software\ License\ Agreement\ Project\ Sunrise\ 03.04.2024.pdf
// Then paste the result below.
export const SUNRISE_PRERELEASE_TERMS_HASH = "9cc2f0e888597d9787afceb74fda1e7b";
