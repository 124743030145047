/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    ActionGroup,
    Button,
    DialogContainer,
    DialogTrigger,
    Divider,
    Flex,
    Heading,
    Image,
    Item,
    Text,
    Tooltip,
    TooltipTrigger,
    View,
} from "@adobe/react-spectrum";
import { SceneManager } from "@components/studio/src/scene/SceneManager";
import { useMessagingContext } from "@shared/client";
import { JanusRoutes } from "@shared/common";
import Comment from "@spectrum-icons/workflow/Comment";
import Flag from "@spectrum-icons/workflow/Flag";
import FullScreen from "@spectrum-icons/workflow/FullScreen";
import Home from "@spectrum-icons/workflow/Home";
import Info from "@spectrum-icons/workflow/Info";
import { useState, useEffect, createContext, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate, matchPath } from "react-router-dom";

import { AssetInfoPanel } from "../components/AssetInfoPanel";
import { MicrophoneInput } from "../components/MicrophoneInput";
import { OpenInVR } from "../components/OpenInVR";
import { PresenceBubbles } from "../components/PresenceBubbles";
import { ProgressCircleView } from "../components/ProgressCircleView";
import { ReportAbuseView } from "../components/ReportAbuseView";
import { SeashellButton } from "../components/SeashellButton";
import { ShareSheetView } from "../components/ShareSheet";
import { StudioView } from "../components/StudioView";
import { UserProfileView } from "../components/UserProfileView";
import { usePinningSession } from "../hooks/usePinningSession";
import {
    getAssetTechInfo,
    updateTechInfoFromViewer,
} from "../util/HealthCheckUtils";
import {
    getHomePath,
    CustomFeatureSetType,
    isCustomFeatureSetEnabled,
} from "../util/PathUtils";
import { CommentsApi } from "@src/comments-api/CommentsApi";
import { AssetNotFoundView } from "@src/components/AssetNotFoundView";
import { CommentsPanel } from "@src/components/CommentsPanel";
import { EnterReviewDialog } from "@src/components/EnterReviewDialog";
import { NavigationPanel } from "@src/components/NavigationPanel";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useNetworkContext } from "@src/contexts/NetworkContext";
import { useAudioControls } from "@src/hooks/useAudioControls";
import { useEnvironmentBuilder } from "@src/hooks/useEnvironmentBuilder";
import { useNetworking } from "@src/hooks/useNetworking";
import { useRedirects } from "@src/hooks/useRedirects";
import { useSceneEnvironment } from "@src/hooks/useSceneEnvironment";
import arIconUrl from "@src/images/arkit-glyph~dark@2x.png";

import type { AssetTechInfo } from "../util/HealthCheckUtils";
import type { AdobeViewer } from "@3di/adobe-3d-viewer";
import type { Selection } from "@adobe/react-spectrum";
import type { ReactElement } from "react";
import { useAccessCheck } from "@src/hooks/useAccessCheck";

interface SceneContextValue {
    sceneInstance: any;
}

export const SceneContext = createContext<SceneContextValue>(
    {} as SceneContextValue,
);

export function useSceneContext() {
    const context = useContext(SceneContext);
    if (!context) {
        throw new Error(
            "useSceneContext must be used within a SceneContext.Provider",
        );
    }
    return context;
}

const DETAIL_PANEL_KEYS = {
    info: "info",
    comment: "comment",
    none: "none",
} as const;
type DetailPanelKey =
    (typeof DETAIL_PANEL_KEYS)[keyof typeof DETAIL_PANEL_KEYS];

type DetailViewRailButton = {
    key: DetailPanelKey;
    label: string;
    icon: ReactElement;
    hideTooltip: string;
    showTooltip: string;
};

const sidePanelButtons = [
    {
        key: DETAIL_PANEL_KEYS.comment,
        label: "actions.comment",
        icon: <Comment size="S" />,
        hideTooltip: "detailPanel.comment.hide.tooltip",
        showTooltip: "detailPanel.comment.show.tooltip",
    },
    {
        key: DETAIL_PANEL_KEYS.info,
        label: "actions.info",
        icon: <Info size="S" />,
        hideTooltip: "detailPanel.info.hide.tooltip",
        showTooltip: "detailPanel.info.show.tooltip",
    },
];

export function TwoUp() {
    const { t } = useTranslation("web");
    const navigate = useNavigate();
    const { reviewModalRedirect } = useRedirects();
    const { showModal } = useMessagingContext();

    const [searchParams] = useSearchParams();
    const assetId = searchParams.get("asset-id") || "";
    const commentApi = useMemo(() => new CommentsApi(assetId || ""), [assetId]);
    const shouldShowShareSheet = searchParams.get("share") || undefined;

    const { accessToken, userProfile, avatarUrl, logIn, userId } =
        useHi5UserContext();
    const {useReviewListItem, useGlbUrl, useUsdzUrl} = useAcpContext();
    const {isAccessIssue} = useAccessCheck(assetId);

    const {data: reviewItem, error: reviewItemError} = useReviewListItem(assetId);
    const {data: glbUrl} = useGlbUrl(assetId);
    const {data: usdzUrl} = useUsdzUrl(assetId);


    const [selectedPanelKey, setSelectedPanelKey] = useState<Selection>(
        new Set([DETAIL_PANEL_KEYS.comment]),
    );

    const [sceneManager, setSceneManager] = useState<SceneManager>();
    const envState = useSceneEnvironment(assetId);
    const [sceneInstance, setSceneInstance] = useState<any>();
    const [viewerInstance, setViewerInstance] = useState<
        AdobeViewer | undefined
    >();
    const pinningSession = usePinningSession(
        commentApi,
        viewerInstance,
        assetId,
    );
    useEnvironmentBuilder(sceneManager, envState);

    const networkContext = useNetworkContext();
    const { networkManager } = networkContext;
    const userReadyToJoin = !matchPath(
        JanusRoutes.twoUpModal,
        window.location.pathname,
    );

    useEffect(() => {
        if (!assetId || !reviewItem || !networkManager) return;
        if (!networkManager.isJoinedLobby()) {
            reviewModalRedirect(assetId);
        }

        if (!userReadyToJoin) {
            showModal(
                <EnterReviewDialog assetId={assetId} assetName={reviewItem.displayName} />,
            );
        }
    }, [assetId, reviewItem, networkManager, userReadyToJoin]);

    const { users } = useNetworking(
        pinningSession,
        assetId,
        avatarUrl,
        userProfile?.displayName ?? "",
        accessToken,
        viewerInstance,
        userReadyToJoin,
    );

    useEffect(() => {
        if (reviewItemError && !isAccessIssue) {
            showModal(
                <View height="256px">
                    <AssetNotFoundView
                        onGoHome={back}
                    />
                </View>,

                { isDismissable: false },
            );
        }
    }, [reviewItemError, isAccessIssue]);

    const [techInfo, setTechInfo] = useState<AssetTechInfo>();

    async function createTechInfo(name: string, url: string) {
        const res = await fetch(url);
        if (!res.ok || res.status !== 200) return;
        const info = await getAssetTechInfo(new File([await res.blob()], name));
        setTechInfo(info);
    }

    useEffect(() => {
        if (glbUrl && reviewItem) createTechInfo(reviewItem.name, glbUrl);
    }, [glbUrl, reviewItem]);

    const [updatedTechInfo, setUpdatedTechInfo] = useState<AssetTechInfo>();
    useEffect(() => {
        if (!(sceneInstance && techInfo) || techInfo.meshes.value) return;
        const updatedInfo = updateTechInfoFromViewer(
            viewerInstance,
            sceneInstance,
            techInfo,
        );
        setUpdatedTechInfo(updatedInfo);
    }, [sceneInstance, techInfo]);

    function back() {
        navigate(getHomePath());
    }

    const [isShareSheetOpen, setIsShareSheetOpen] =
        useState<boolean>(!!shouldShowShareSheet);
    function share() {
        return (
            <>
                <Button
                    variant="cta"
                    isDisabled={!reviewItem}
                    onPress={() => setIsShareSheetOpen(true)}>
                    <Text>{t("actions.share")}</Text>
                </Button>
                <DialogContainer onDismiss={() => setIsShareSheetOpen(false)}>
                    {isShareSheetOpen && (
                        <View width="size-5000" maxHeight="size-6000">
                            {assetId && reviewItem && (
                                <ShareSheetView
                                    assetUrn={assetId}
                                    assetName={reviewItem?.displayName}
                                    closeShareViewFunction={() =>
                                        setIsShareSheetOpen(false)
                                    }
                                />
                            )}
                        </View>
                    )}
                </DialogContainer>
            </>
        );
    }

    function logInButton() {
        return (
            <Button variant="cta" onPress={logIn}>
                <Text>{t("actions.signIn")}</Text>
            </Button>
        );
    }


    function openInVr() {
        return (
            <OpenInVR
                accessToken={accessToken}
                assetUrn={assetId}
            />
        );
    }

    const {
        audioDevices,
        mediaStream,
        currentAudioDeviceId,
        muted,
        setCurrentAudioDeviceId,
        setMuted,
    } = useAudioControls();
    function joinReview() {
        const displayName = userProfile?.displayName;
        if (
            !displayName ||
            !accessToken ||
            !assetId ||
            !sceneInstance ||
            !pinningSession
        )
            return;
        return assetId ? (
            <PresenceBubbles
                muted={muted}
                device={currentAudioDeviceId}
                users={users}
                mediaStream={mediaStream}
            />
        ) : null; // Or render some fallback UI
    }

    function micInput() {
        return (
            <TooltipTrigger>
                <MicrophoneInput
                    audioDevices={audioDevices}
                    muted={muted}
                    currentAudioDeviceId={currentAudioDeviceId}
                    setMuted={(on) => setMuted(!on)}
                    setDevice={setCurrentAudioDeviceId}
                />
                <Tooltip>{t("nav.tooltip.mute")}</Tooltip>
            </TooltipTrigger>
        );
    }

    function frame() {
        return (
            <TooltipTrigger>
                <ActionButton
                    isQuiet
                    onPress={() => {
                        if (sceneManager) {
                            sceneManager.frame();
                        }
                    }}>
                    <FullScreen
                        aria-label={t("accessibility.actions.fullScreen")}
                        size="S"
                    />
                </ActionButton>
                <Tooltip>{t("nav.tooltip.frame")}</Tooltip>
            </TooltipTrigger>
        );
    }

    function reportAbuse() {
        return (
            <DialogTrigger type="modal">
                <TooltipTrigger>
                    <ActionButton isQuiet>
                        <Flag
                            aria-label={t("accessibility.actions.reportAbuse")}
                            size="S"
                        />
                    </ActionButton>
                    <Tooltip>{t("detailPanel.reportAbuse.tooltip")}</Tooltip>
                </TooltipTrigger>

                {(close) => (
                    <ReportAbuseView
                        assetUrn={assetId}
                        onSubmit={close}
                        onCancel={close}
                    />
                )}
            </DialogTrigger>
        );
    }

    function infoPanel() {
        const isInfoPanelReady =
            accessToken &&
            assetId &&
            reviewItem &&
            updatedTechInfo;
        return (
            isInfoPanelReady && (
                <AssetInfoPanel
                    accessToken={accessToken}
                    ownerId={reviewItem.createdBy}
                    modifyDate={reviewItem.modifyDate}
                    techInfo={updatedTechInfo}
                />
            )
        );
    }

    function isPanelHidden(key: DetailPanelKey) {
        return [...selectedPanelKey][0] != key;
    }

    function openInAr() {
        if (!isCustomFeatureSetEnabled(CustomFeatureSetType.visionPro, userId))
            return;
        const isOpenInArReady = !!usdzUrl;

        const downloadUszdz = () => {
            if (!usdzUrl) return;
            const a = document.createElement("a");
            a.href = usdzUrl;
            a.setAttribute("download", "");
            a.click();
        };

        return (
            <>
                <Button
                    variant="secondary"
                    isDisabled={!isOpenInArReady}
                    onPress={downloadUszdz}>
                    <Image
                        width="21px"
                        height="21px"
                        src={arIconUrl}
                        marginEnd="size-75"
                    />
                    <Text>View in AR</Text>
                </Button>
            </>
        );
    }

    function openInSeashell() {
        if (
            !assetId ||
            !isCustomFeatureSetEnabled(CustomFeatureSetType.shareDemo, userId)
        )
            return;

        return <SeashellButton assetId={assetId} />;
    }

    const [shouldShowLoadingView, setShouldShowLoadingView] =
        useState<boolean>(true);
    useEffect(() => {
        if (glbUrl) setShouldShowLoadingView(false);
    }, [glbUrl]);

    const [pinsVisible, setPinsVisible] = useState<boolean>(true);
    useEffect(() => {
        if ([...selectedPanelKey][0] !== DETAIL_PANEL_KEYS.comment) {
            // hide pins when comment panel is not shown
            if (pinsVisible) {
                pinningSession?.pinManager.togglePinsVisibility();
                setPinsVisible(false);
            }
        } else {
            if (!pinsVisible) {
                pinningSession?.pinManager.togglePinsVisibility();
                setPinsVisible(true);
            }
        }
    }, [selectedPanelKey]);

    return (
        <SceneContext.Provider value={{ sceneInstance }}>
            <Flex direction="column" width="100%" height="100%">
                <View
                    backgroundColor="gray-200"
                    borderBottomColor="gray-75"
                    borderBottomWidth="thick"
                    height="size-800"
                    paddingX="2vw">
                    <Flex
                        direction="row"
                        width="100%"
                        height="100%"
                        alignItems="center"
                        justifyContent="space-between">
                        <View>
                            <Flex
                                direction="row"
                                justifyContent="start"
                                alignItems="center"
                                gap="size-200">
                                <TooltipTrigger>
                                    <ActionButton onPress={back} isQuiet>
                                        <Home aria-label="back" size="S" />
                                    </ActionButton>
                                    <Tooltip>{t("nav.tooltip.home")}</Tooltip>
                                </TooltipTrigger>
                                <View>
                                    <Heading level={4}>
                                        {reviewItem?.displayName}
                                    </Heading>
                                </View>
                                <Divider
                                    orientation="vertical"
                                    size="M"
                                    UNSAFE_style={{
                                        height: "40px",
                                        marginTop: "10px",
                                    }}
                                />
                                <View>{frame()}</View>
                                <View>{micInput()}</View>
                            </Flex>
                        </View>
                        <View>
                            <Flex gap="size-200">
                                {openInSeashell()}
                                {joinReview()}
                                {openInAr()}
                                {openInVr()}
                                {userProfile ? share() : logInButton()}
                                <UserProfileView
                                    userProfile={userProfile}
                                    avatarUrl={avatarUrl}
                                    trimStrings={true}
                                    isCompactView={true}
                                />
                            </Flex>
                        </View>
                    </Flex>
                </View>

                {shouldShowLoadingView ? (
                    <ProgressCircleView />
                ) : (
                    <Flex
                        direction="column"
                        justifyContent="space-between"
                        height="100%">
                        <div
                            style={{
                                width: "100%",
                                height: "calc(100vh - 64px)",
                            }}>
                            <Flex direction="row" width="100%" height="100%">
                                {sceneManager ? (
                                    <NavigationPanel
                                        sceneManager={sceneManager}
                                    />
                                ) : (
                                    <View
                                        backgroundColor="gray-100"
                                        height="100%"
                                        width="48px"
                                        borderStartColor="gray-75"
                                        borderStartWidth="thick"
                                        paddingTop="size-100"
                                        paddingX="size-75">
                                        &nbsp;
                                    </View>
                                )}
                                <div
                                    style={{
                                        flex: 1,
                                        height: "100%",
                                        maxHeight: "100%",
                                        maxWidth: "100%",
                                    }}>
                                    {envState && glbUrl && (
                                        <StudioView
                                            modelUrl={glbUrl}
                                            setSceneInstance={setSceneInstance}
                                            setViewerInstance={
                                                setViewerInstance
                                            }
                                            setSceneManager={setSceneManager}
                                        />
                                    )}
                                </div>
                                <View
                                    backgroundColor="gray-100"
                                    borderStartColor="gray-75"
                                    borderStartWidth="thick"
                                    width="size-4600"
                                    height="100%"
                                    isHidden={
                                        [...selectedPanelKey].length === 0
                                    }>
                                    <CommentsPanel
                                        visible={
                                            !isPanelHidden(
                                                DETAIL_PANEL_KEYS.comment,
                                            )
                                        }
                                        assetId={assetId}
                                        commentsApi={commentApi}
                                        ownerId={reviewItem?.createdBy}
                                    />
                                    <View
                                        width="100%"
                                        isHidden={isPanelHidden(
                                            DETAIL_PANEL_KEYS.info,
                                        )}>
                                        {infoPanel()}
                                    </View>
                                </View>
                                <View
                                    backgroundColor="gray-100"
                                    height="100%"
                                    width="48px"
                                    borderStartColor="gray-75"
                                    borderStartWidth="thick"
                                    paddingTop="size-100"
                                    paddingX="size-75">
                                    <Flex
                                        height="100%"
                                        direction="column"
                                        justifyContent="space-between">
                                        <ActionGroup
                                            orientation="vertical"
                                            selectionMode="single"
                                            selectedKeys={selectedPanelKey}
                                            onSelectionChange={
                                                setSelectedPanelKey
                                            }
                                            buttonLabelBehavior="hide"
                                            isQuiet>
                                            {sidePanelButtons.map(
                                                (
                                                    button: DetailViewRailButton,
                                                ) => {
                                                    return (
                                                        <Item
                                                            key={button.key}
                                                            aria-label={t(
                                                                button.label,
                                                            )}>
                                                            {button.icon}
                                                            <Text>
                                                                {isPanelHidden(
                                                                    button.key,
                                                                )
                                                                    ? t(
                                                                          button.showTooltip,
                                                                      )
                                                                    : t(
                                                                          button.hideTooltip,
                                                                      )}
                                                            </Text>
                                                        </Item>
                                                    );
                                                },
                                            )}
                                        </ActionGroup>
                                        <View paddingBottom="size-100">
                                            {reportAbuse()}
                                        </View>
                                    </Flex>
                                </View>
                            </Flex>
                        </div>
                    </Flex>
                )}
            </Flex>
        </SceneContext.Provider>
    );
}
