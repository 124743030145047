/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Picker, Item, SearchField, Text } from "@adobe/react-spectrum";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TabType } from "./AssetTabsView";
import { sortByRecent, sortByName, SortType } from "../util/SortUtils";
import { ReviewListItem } from "@src/lib/acp/AcpClientModels";

import type { Key } from "@react-types/shared";

function labelText(text: string, isForVr?: boolean) {
    return (
        <Text
            UNSAFE_style={
                isForVr
                    ? {
                          fontSize: "1.5em",
                          marginTop: "-5px",
                          paddingRight: "8px",
                      }
                    : {}
            }>
            {text}
        </Text>
    );
}

interface SortAndFilterViewProps {
    yourAssets: ReviewListItem[] | undefined;
    sharedWithYouAssets: ReviewListItem[] | undefined;
    filteredAssets: ReviewListItem[] | undefined;
    selectedTab: Key;
    setFilteredAssets: (assets: ReviewListItem[] | undefined) => void;
    isForVr?: boolean;
}

export function SortAndFilterView({
    yourAssets,
    sharedWithYouAssets,
    filteredAssets,
    selectedTab,
    setFilteredAssets,
    isForVr,
}: SortAndFilterViewProps) {
    const { t } = useTranslation("common");

    const [sortType, setSortType] = useState<Key>(SortType.recent);
    const [currentFilterInput, setCurrentFilterInput] = useState<string>("");

    function sortAssets<T extends {modifyDate?: string, displayName?: string}>(assets: T[] | undefined) {
        if (!assets) return;
        const sortingFn =
            sortType === SortType.recent ? sortByRecent : sortByName;
        return assets.slice().sort(sortingFn);
    }

    useEffect(() => {
        const sortedAssets = sortAssets(filteredAssets);
        setFilteredAssets(sortedAssets);
    }, [sortType]);

    function filter(input: string) {
        setCurrentFilterInput(input);
        const filterFn = (data: ReviewListItem) => {
            return (
                data.displayName &&
                data.displayName.toUpperCase().includes(input.toUpperCase())
            );
        };
        if (selectedTab === TabType.yourReviews) {
            if (!yourAssets) return;
            setFilteredAssets(sortAssets(yourAssets.filter(filterFn)));
        } else {
            if (!sharedWithYouAssets) return;
            setFilteredAssets(sortAssets(sharedWithYouAssets.filter(filterFn)));
        }
    }

    useEffect(() => {
        filter(currentFilterInput);
    }, [yourAssets, sharedWithYouAssets, selectedTab]);

    return (
        <Flex
            marginY="2vh"
            direction="row"
            width="100%"
            justifyContent="space-between">
            <Picker
                label={labelText(t("sortBy.sort"), isForVr)}
                labelPosition="side"
                selectedKey={sortType}
                onSelectionChange={(selected) => setSortType(selected)}
                isQuiet>
                <Item key={SortType.recent} aria-label={t("accessibility.sortBy.recent")}>
                    <Text
                        UNSAFE_style={{
                            fontSize: isForVr ? "1.6em" : "",
                        }}>
                        {t("sortBy.recent")}
                    </Text>
                </Item>
                <Item key={SortType.name} aria-label={t("accessibility.sortBy.name")}>
                    <Text
                        UNSAFE_style={{
                            fontSize: isForVr ? "1.6em" : "",
                        }}>
                        {t("sortBy.name")}
                    </Text>
                </Item>
            </Picker>
            <SearchField
                id="SearchField-Filter"
                label={labelText(t("search.filter"), isForVr)}
                labelPosition="side"
                onChange={filter}
                isQuiet
            />
        </Flex>
    );
}
