/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

/**
 * This file contains the integration data types and setup required for sharing for review (ccx-sharing & ccx-comments)
 * See this readme for detail about integrating with ccx-sharing
 * https://git.corp.adobe.com/CCX/ccx-sharing/blob/main/packages/component-react/integration.md
 *
 * For production invitation to work, we will need the Cloud Platform team to whitelist our api key and host names.
 */

/**
 * For sharing for review integration.
 */
export const SHARE_SHEET_ARTIFACT_ID = "artifact";
export const SHARE_SHEET_SHARE_ID = "share";
export const IMS_SERVICE_ACCOUNT_CODE = "creative_cloud";
export const IMS_SERVICE_LEVEL = "CS_LVL_2";

export const CCX_SHARING_VERSION = "";

export const ASSET_INVITE_NOTIFICATION =
    "cc_collab_highfive_invite_notification";

/**
 * Analytics data for sharing and comments.
 * Both data structures are very similar with minor differences.
 *
 * Analytics Data for ccx-sharing
 * https://git.corp.adobe.com/CCX/ccx-sharing/blob/main/packages/component-react/integration.md#analytics
 * *
 * Analytics Data for ccx-comments
 * From https://git.corp.adobe.com/CCX/ccx-comments/blob/main/ONBOARDING.md#Analytics-Registration
 */
export interface ShareSheetAnalyticsDataSource {
    // eslint-disable-next-line camelcase -- External API
    client_id: string; // client Id
    name: string; // SAP code
    os_version?: string; // Current platform OS version
    platform: "mac" | "ios" | "win" | "web";
    version: string; // (TODO: Verify)
}

interface CommentsAnalyticsDataSource {
    // eslint-disable-next-line camelcase -- External API
    client_id: string;
    name: string;
    device: string;
    platform: "mac" | "ios" | "win" | "web";
}

export interface ShareSheetAnalyticsDataEvent {
    // eslint-disable-next-line camelcase -- External API
    device_guid?: string;
    category: "WEB" | "TABLET" | "DESKTOP";
}

interface CommentsAnalyticsDataEvent {
    // eslint-disable-next-line camelcase -- External API
    session_guid: string;
    workflow: "space" | "non-space";
}

/**
 * Analytics data type shared by ccx-sharing and ccx-comments
 */
interface AnalyticsDataUI {
    // eslint-disable-next-line camelcase -- External API
    view_type: string;
}

export interface AnalyticsData {
    source: ShareSheetAnalyticsDataSource | CommentsAnalyticsDataSource;
    event: ShareSheetAnalyticsDataEvent | CommentsAnalyticsDataEvent;
    ui?: AnalyticsDataUI;
}

/**
 * ShareSheet Asset Data format.
 * Visit these links for more detail:
 * https://git.corp.adobe.com/CCX/ccx-sharing/tree/main/packages/component-react#assetdata-format
 * https://git.corp.adobe.com/CCX/ccx-sharing/blob/main/packages/component-react/README.md#assetdata-prop-details
 */
export interface ShareSheetAssetData {
    id: string;
    resourceId?: string;
    resourceViewUrl?: string;
    isAncestorShared?: boolean;
    isReadOnly?: boolean;
    isLoading?: boolean;
    isSaving?: boolean;
    mimeType?: string;
    modifiedDate?: number;
    name?: string;
    type?: string;
    useProductViewUrl?: boolean;
}

/**
 * ShareSheet Space data for AssetData 2.0
 */
export interface ShareSheetSpaceData {
    assetId: string;
    color: string;
    name: string;
}

interface EmailNotificationSharing {
    viewer?: string;
    editor?: string;
}

export interface EmailNotificationAcceptance {
    viewer?: string;
    editor?: string;
}

export interface EmailNotificationAccessRequest {
    grant?: string;
    reject?: string;
}

export interface EmailNotification {
    sharing?: EmailNotificationSharing;
    acceptance?: EmailNotificationAcceptance;
    accessRequest?: EmailNotificationAccessRequest;
}

/**
 * Preferred format: AssetData 2.0 format.
 * Correspond to CCX.Asset
 */
export interface ShareSheetAsset {
    assetId: string; // for AssetData 2.0 replacing id
    name: string;
    resourceId: string; // should be an id-based access url. This is to access a resource in CC Storage (CCStorageUrl)
    category?: string; // analytics
    extension?: string; // analytics
    emailNotification?: EmailNotification;
    canComment?: boolean;
    // targetUrl used by AssetData 2.0 replacing resourceViewUrl.
    // If not passed in, it is constructed from resourceId based on workflow, assetType, mimeType...
    targetUrl?: string;
    groupTargetUrl?: string;
    isAncestorShared?: boolean;
    isReadOnly?: boolean;
    isLoading?: boolean;
    isSaving?: boolean;
    mimeType?: string;
    modifiedDate?: number;
    size?: number;
    roles?: Array<string | object>;
    space?: ShareSheetSpaceData;
    team?: string;
    type?: string;
    useProductViewUrl?: boolean; // PS and AI only
}

/**
 * IMS data for share for review.
 * Used in ccx-comments and ccx-sharing.
 * sharing: https://git.corp.adobe.com/CCX/ccx-sharing/tree/main/packages/component-react#imsdata-format
 * commenting: https://git.corp.adobe.com/CCX/ccx-comments/tree/main/packages/ccx-comments#imsdata-prop-details
 * Note that optional data fields are used for analytics only.
 */
export interface CCACImsData {
    id: string;
    token: string;
    isPaid?: boolean;
    countryCode?: string;
    email?: string;
    name?: string;
    serviceCode?: string;
    serviceLevel?: string;
}

export interface ProjectData {
    color?: string;
    name?: string;
    urn: string;
}

export interface StringKeyedObject {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Unspecified
    [key: string]: any;
}
export interface TeamData {
    name: string;
}

/**
 * ShareSheet view provider properties.
 * From https://git.corp.adobe.com/CCX/ccx-sharing/tree/main/packages/component-react#configuration-props
 * See this link for additional information regarding ShareDialog:
 * https://git.corp.adobe.com/CCX/ccx-sharing/blob/main/packages/component-react/integration.md#sharedialog
 */
export interface ShareSheetProviderProps {
    // Required props
    assetData: ShareSheetAssetData | ShareSheetAsset;
    idpClientId: string;
    imsData: CCACImsData;
    invitationServiceApiKey: string;

    // Optional props
    analyticsData?: AnalyticsData;
    behanceClientId?: string;
    environment?: string;
    features?: StringKeyedObject;
    inviteApiVersion?: string;
    linkPrefix?: string;
    linksApiVersion?: string;
    locale?: string;
    openedDialog?: string;
    optionsMap?: StringKeyedObject;
    peopleGraphApiKey?: string;
    projectData?: ProjectData;
    reCaptchaSiteKey?: string;
    setupRedux?: boolean;
    storageRepositoryApiKey?: string;
    theme?: string;
    typekitId?: string;
    team?: TeamData;
    spaceDocumentOneOffInvites?: boolean;
    compactLayout?: boolean;
    requestAccessRole?: string;
    enablePassword?: boolean;
    /** These callbacks are required if using Artifact dialog */
    onArtifactCreate?: typeof Function;
    onArtifactUpdate?: typeof Function;
    onArtifactDelete?: typeof Function;
    onArtifactRename?: typeof Function;
}

/**
 * ShareSheet invite dialog data.
 * Unless the asset is a folder, both inviteCreateAcceptanceRequired and inviteCreateUseMounts must be false.
 */
export interface ShareSheetInviteDialogData {
    emailNotification: EmailNotification;
    inviteCreateAcceptanceRequired: boolean;
    inviteCreateUseMounts: boolean;
    disableLinkAccess?: boolean;
    disablePublicAccess?: boolean;
    readOnlyLinkAccess?: boolean;
    availableRoles?: Array<string | object>;
}

export interface ShareSheetDialogProps {
    inviteDialogData?: ShareSheetInviteDialogData;
    native?: boolean;
    showCloseButton?: boolean;
    showSettingsButton?: boolean;
    useNewLinkFooter?: boolean;
    showHeader?: boolean;
    tabletMode?: boolean;
    onClose?: () => void;
}

/**
 * Minimal Default share sheet invite dialog data
 */
export const DEFAULT_SHARE_INVITATION_DIALOG_DATA = {
    emailNotification: {
        sharing: {
            viewer: ASSET_INVITE_NOTIFICATION,
            editor: ASSET_INVITE_NOTIFICATION,
        },
        acceptance: {
            viewer: "cc_collab_invite_v2_viewer",
            editor: "cc_collab_invite_v2_editor",
        },
        accessRequest: {
            grant: "cc_collab_clouddoc_request_granted",
            reject: "cc_collab_clouddoc_request_denied",
        },
    },
    inviteCreateAcceptanceRequired: false,
    inviteCreateUseMounts: false,
};

export interface ValidNode {
    id: string;
    title: string;
    subTitle?: string;
}

export type ShareStatus = "unshared" | "shared" | "orgShared" | "public";

/**
 * Asset data used by ccx-comments.
 * From https://git.corp.adobe.com/CCX/ccx-comments/tree/main/packages/ccx-comments#assetdata-prop-details
 */
export interface CommentsAssetData {
    id: string; // asset id.
    nodeId?: string; // active node id.
    ownerId: string;
    region?: string;
    mimeType?: string;
    validNodes: ValidNode[]; // valid nodes for this asset.
    isSaving?: boolean;
    shareStatus: ShareStatus;
}

export interface AnnotationTypesEnabled {
    draw?: boolean;
    pin?: boolean;
    strikethrough?: boolean;
    insert?: boolean;
    replace?: boolean;
    highlight?: boolean;
}

export interface AnnotationCoordinates {
    noteX: number;
    noteY: number;
    subtype: string;
}

export type AnnotationTrigger =
    | "start"
    | "cancel"
    | "place"
    | "update"
    | "submit";

/** A subset of supported annotation types */
export type AnnotationType = "pin" | "draw" | "text";

export type CommentsEnvironment = "stage" | "prod";

export type GroupBy = "node" | "none";

/**
 * Comments provider props.
 * From https://git.corp.adobe.com/CCX/ccx-comments/blob/main/packages/ccx-comments/README.md#props-overview
 */
export interface CommentsProviderProps {
    // Required props
    appKey: string;
    appName: string;
    assetData: CommentsAssetData;
    imsData: CCACImsData;

    // Optional props
    analyticsData?: AnalyticsData;
    annotationsEnabled?: boolean;
    annotationsMultiPageEnabled?: boolean;
    annotationTypes?: AnnotationTypesEnabled;
    compactLayout?: boolean;
    latestMobileEnabled?: boolean;
    disableNetworkIndicator?: boolean;
    environment?: CommentsEnvironment;
    globalComments?: boolean;
    globalCommentsUnmapped?: boolean;
    globalCommentsViewAll?: boolean;
    groupBy?: GroupBy;
    mentionsEnabled?: boolean;
    peopleGraphAPIKey?: string;
    richTextEntry?: boolean;
    enableEmojiPicker?: boolean;
    theme?: string;
    locale?: string;
    hasReviewAccess?: boolean;
    spectrumUIScale?: string;
    reCaptchaSiteKey?: string;
    reCaptchaEnabled?: boolean;
    disableCommentCreation?: boolean;
    pollForUpdates?: boolean;

    // Callbacks
    onAnnotatingChange?: () => void;
    onNodeChange?: () => void;
    onCommentInteraction?: () => void;
    onAnnotationToggle?: () => void;
}

/**
 * AnnotationsOverlay props https://git.corp.adobe.com/CCX/ccx-comments/tree/main/packages/ccx-comments#props-overview-1
 **/
export interface AnnotationsOverlayProps {
    disableInteraction?: boolean;
    maskTextOverride?: string;
    nodeId?: string;
    scaleFactorX?: number;
    scaleFactorY?: number;
    sectionId?: string;
    showHighlight?: boolean;
    useMask?: boolean;
}

/**
 * AnnotationsMask props https://git.corp.adobe.com/CCX/ccx-comments/blob/main/packages/ccx-comments/README.md#annotationsmask
 */
export interface AnnotationsMaskProps {
    maskTextOverride: string;
    hovered: boolean;
}
