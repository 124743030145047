/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { VERSION, isVRPage } from "../config";
import { visitorGuid } from "../hooks/useAnalytics";
import { CommentsApi, CommentsApiEvent } from "@src/comments-api/CommentsApi";
import {
    PostMessageCommentsApi,
    type CommentsMessageBus,
} from "@src/comments-api/PostMessageCommentsApi";
import { CommentsPanel } from "@src/components/CommentsPanel";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useInputPostBus } from "@src/hooks/useInputPostBus";

export function OneUp() {
    const [searchParams] = useSearchParams();
    const assetId = searchParams.get("asset-id") || "";

    const commentsApi = useMemo(
        () => new CommentsApi(assetId || ""),
        [assetId],
    );
    const { userId } = useHi5UserContext();
    const { useReviewListItem } = useAcpContext();

    const {data: reviewItem} = useReviewListItem(assetId);

    const [messageBus, setMessageBus] = useState<
        CommentsMessageBus | undefined
    >(window);


    const [commentsPostMessageBusApi, setCommentsPostMessageBusApi] = useState<
        PostMessageCommentsApi | undefined
    >();
    const [componentLoaded, setComponentLoaded] = useState(false);
    const {refreshInputElements} = useInputPostBus(commentsPostMessageBusApi);

    function getAnnotationToolWrapper() {
        const shadowRoot =
            document.getElementsByTagName("cc-comments-list")[0].shadowRoot;
        const annotationToolDiv = shadowRoot?.querySelector(
            ".annotation-tool-wrapper",
        );
        return annotationToolDiv?.getElementsByTagName(
            "overlay-trigger-1-0-60",
        )[0];
    }

    function getSubmitButtonContainer() {
        const shadowRoot =
            document.getElementsByTagName("cc-comments-list")[0].shadowRoot;
        return shadowRoot?.querySelector(".submit-button-container");
    }

    function getAnnotationToggleContainer() {
        const shadowRoot =
            document.getElementsByTagName("cc-comments-list")[0].shadowRoot;
        return shadowRoot?.querySelector(".toggle-right-container");
    }

    useEffect(() => {
        if (isVRPage) {
            if (window.vuplex) {
                setMessageBus(window.vuplex as CommentsMessageBus);
            } else {
                window.addEventListener("vuplexready", () => {
                    setMessageBus(window.vuplex as CommentsMessageBus);
                });
            }
        }
    }, [isVRPage]);

    useEffect(() => {
        if (messageBus) {
            const commentBusApi = new PostMessageCommentsApi(messageBus);
            if (commentsApi.initialized) {
                commentBusApi.initializeCommentsApi(commentsApi);
            }
            setCommentsPostMessageBusApi(commentBusApi);
        }
    }, [messageBus, commentsApi]);

    useEffect(() => {
        if (commentsPostMessageBusApi)
            commentsPostMessageBusApi.webReady(VERSION, userId ?? "", visitorGuid);
    }, [commentsPostMessageBusApi]);

    useEffect(() => {
        if (commentsApi) {
            commentsApi.addListener(CommentsApiEvent.commentsSuccess, refreshInputElements);
            commentsApi.addListener(CommentsApiEvent.commentInteraction, refreshInputElements);
            commentsApi.addListener(CommentsApiEvent.commentsUpdate, refreshInputElements);
            commentsApi.addListener(CommentsApiEvent.nodeChanged, refreshInputElements);
            commentsApi.addListener(CommentsApiEvent.commentsFiltered, refreshInputElements);
        }
        return () => {
            if (commentsApi) {
                commentsApi.removeListener(CommentsApiEvent.commentsSuccess, refreshInputElements);
                commentsApi.removeListener(CommentsApiEvent.commentInteraction, refreshInputElements);
                commentsApi.removeListener(CommentsApiEvent.commentsUpdate, refreshInputElements);
                commentsApi.removeListener(CommentsApiEvent.nodeChanged, refreshInputElements);
                commentsApi.removeListener(CommentsApiEvent.commentsFiltered, refreshInputElements);
            }
        }
    }, [commentsApi, refreshInputElements])

    useEffect(() => {
        if (commentsPostMessageBusApi && commentsApi) {
            commentsPostMessageBusApi.addEventListener(
                "focus-comment-text-area",
                () => {
                    const textarea = document
                        .getElementsByTagName("cc-comments-list")[0]
                        .shadowRoot?.querySelector<HTMLElement>(".textarea");
                    if (!textarea) {
                        console.error("textarea not found");
                        return;
                    }
                    textarea.focus();
                },
            );
            commentsPostMessageBusApi.addEventListener(
                "set-pin-icon-status",
                (e: any) => {
                    const pinIcon =
                        getAnnotationToolWrapper()?.querySelector<HTMLElement>(
                            "#annotation-pin-button",
                        );
                    if (!pinIcon) {
                        console.error("pin icon not found");
                        return;
                    }
                    const isActive = pinIcon.classList.contains("is-selected");
                    const statusToSet = e.detail.isActive;
                    if (isActive !== statusToSet) pinIcon.click();
                },
            );
            commentsPostMessageBusApi.addEventListener("clear-text-focus", () => {
                const ccCommentsList =
                    document.getElementsByTagName("cc-comments-list")[0];
                if (ccCommentsList !== undefined) {
                    const activeElement: HTMLElement | null = ccCommentsList
                        .shadowRoot?.activeElement as HTMLElement;
                    if (!activeElement) {
                        console.error("active text input not found");
                        return;
                    }
                    activeElement.blur();
                } else {
                    console.error("could not find cc-comments-list element");
                }
            });
            commentsPostMessageBusApi.addEventListener("vr-keyboard-enter", () => {
                const submitButton =
                    getSubmitButtonContainer()?.querySelector<HTMLElement>(
                        "#ccx-comments-submit-btn",
                    );
                if (!submitButton) {
                    console.error("submit button not found");
                    return;
                }
                const isDisabled =
                    submitButton.classList.contains("is-disabled");
                if (!isDisabled) submitButton.click();
            });
            commentsPostMessageBusApi.addEventListener("vr-annotation-toggled", () => {
                const toggleAnnotationButton =
                    getAnnotationToggleContainer()?.querySelector<HTMLElement>(
                        "#ccx-comments-hide-annotations-button",
                    );
                if (!toggleAnnotationButton) {
                    console.error("toggle annotation button not found");
                    return;
                }
                toggleAnnotationButton.click();
            });
        }
    }, [commentsPostMessageBusApi, commentsApi]);

    useEffect(() => {
        if (!componentLoaded) return;
        const shadowRoot =
            document.getElementsByTagName("cc-comments-list")[0].shadowRoot;
        if (!shadowRoot) return;

        shadowRoot?.addEventListener("focusin", () => {
            const activeElement = shadowRoot.activeElement as HTMLElement;
            const isTextArea = activeElement.tagName === "TEXTAREA";
            const commentTextAreas =
                shadowRoot?.querySelectorAll<HTMLElement>("#ccx-comments-list");

            if (commentsPostMessageBusApi && activeElement && isTextArea) {
                //
                const outerParent =
                    activeElement?.parentElement?.parentElement?.parentElement
                        ?.parentElement?.parentElement?.parentElement
                        ?.parentElement;
                // console.log("activeElement-parent:", activeElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement);  <-- for debugging

                if (outerParent?.className.includes("flex")) {
                    commentsPostMessageBusApi.inputFieldBoundingRect(-1); //clicked in header text area
                } else {
                    activeElement.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                    const { scrollTop, scrollHeight } = commentTextAreas[0];

                    const percentage = (scrollTop / scrollHeight) * 100;
                    commentsPostMessageBusApi.inputFieldBoundingRect(percentage);
                    // console.error("percentage scrolled:", percentage); <-- for debugging
                }
            }
        });
    }, [componentLoaded]);


    const [ownerId, setOwnerId] = useState<string | undefined>("");
    useEffect(() => {
        if (reviewItem)
            setOwnerId(reviewItem.createdBy);
    }, [reviewItem]);

    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                overflow: "hidden",
            }}>
            <CommentsPanel
                assetId={assetId}
                commentsApi={commentsApi}
                ownerId={ownerId}
                onCommentsApiInitialized={() => {
                    if (commentsPostMessageBusApi) {
                        commentsPostMessageBusApi.initializeCommentsApi(commentsApi);
                    }
                    setComponentLoaded(true);
                }}
            />
        </div>
    );
}
