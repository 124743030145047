/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { generatePath } from "react-router-dom";

import { Routes } from "@src/routes";

export enum CustomFeatureSetType {
    shareDemo = "shareDemo",
    visionPro = "visionPro",
}

const visionProUsers = [
    "5FA805855FAB3D3B0A495FEF@adobe.com",
    "F7651A84646BEDB80A49413E@2739213f63e583bd49422e.e",
    "87951F57631C49D80A495FC2@82121f78631c0ca3495c57.e",
    "039D69E86393CBF10A495E9D@a8ce371862aa074e495fe8.e",
    "8D931F8A631C79620A495E53@837b1f32631c0c83495fa4.e",
    "C67714D9659DB4DE0A495E14@4b3e2c866436ba07495fb9.e",
    "2C6A1F9364E787870A495C91@1c0a296563ed62e1495fb9.e",
    "005E2AFD64246BD30A495F86@02491e85636c53f8495e07.e",
]

type CustomFeatureSetPathsType = Record<CustomFeatureSetType, string>;

const CustomFeatureSetPaths: CustomFeatureSetPathsType = {
    [CustomFeatureSetType.shareDemo]: Routes.shareDemo.path,
    [CustomFeatureSetType.visionPro]: Routes.visionPro.path,
};

const CustomFeatureSetReviewPaths: CustomFeatureSetPathsType = {
    [CustomFeatureSetType.shareDemo]: Routes.shareDemoTwoUp.path,
    [CustomFeatureSetType.visionPro]: Routes.visionProTwoUp.path,
};

export function isCustomFeatureSetEnabled(type: CustomFeatureSetType, userId?: string) {
    if (userId && type === CustomFeatureSetType.visionPro && visionProUsers.includes(userId)) {
        return true;
    }
    return location.href.includes(CustomFeatureSetPaths[type]);
}

const enabledType = Object.values(CustomFeatureSetType).find((type) =>
    isCustomFeatureSetEnabled(type),
);

export function getLandingPath() {
    return enabledType
        ? `${generatePath(CustomFeatureSetPaths[enabledType])}`
        : `${generatePath(Routes.index.path)}`;
}

export function getHomePath() {
    return enabledType
        ? `${generatePath(CustomFeatureSetPaths[enabledType])}`
        : `${generatePath(Routes.home.path)}`;
}

export function getReviewPath(assetUrn: string) {
    return enabledType
        ? `${generatePath(
            CustomFeatureSetReviewPaths[enabledType],
        )}?asset-id=${assetUrn}`
        : `${generatePath(Routes.twoUp.path)}?asset-id=${assetUrn}`;
}

export type HomeModal = "preview" | "environment" | "share" | "enter";

export function getHomeModalPath(modal: HomeModal, assetId: string) {
    return generatePath(Routes.homeModal.path, {
        modal,
        assetId,
    })
}

export function getReviewModalPath(assetId: string) {
    return `${generatePath(Routes.twoUpModal.path)}?asset-id=${assetId}`;
}
