/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Content,
    Dialog,
    Divider,
    Flex,
    Footer,
    Heading,
    Item,
    Picker,
    Radio,
    RadioGroup,
    Text,
    TextArea,
    TextField,
    View,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ADOBE_IMS_CONFIG, ASSET_APIS } from "../config";
import { isValidEmailFormat } from "../util/StringUtils";

import type { Key } from "react";

interface ReportAbuseProps {
    assetUrn: string | undefined;
    onCancel: () => void;
    onSubmit: (responseStatus: number) => void;
}

enum ReportAbuseCategory {
    spam = "spam",
    adult = "adult",
    fraud_scam = "fraud_scam",
    harmful = "harmful",
    self_harm = "self_harm",
    infringement = "infringement",
    misinformation = "misinformation",
    other = "other",
}

enum ReportAbuseSubCategory {
    adult_nudity_sexual_activity = "adult_nudity_sexual_activity",
    adult_violence_or_gore = "adult_violence_or_gore",
    adult_profanity = "adult_profanity",
    harmful_harassment = "harmful_harassment",
    harmful_hate_speech = "harmful_hate_speech",
    harmful_illegal_goods_services = "harmful_illegal_goods_services",
    harmful_promotes_terrorism = "harmful_promotes_terrorism",
    harmful_behavior_with_minors = "harmful_behavior_with_minors",
    infringement_copyright = "infringement_copyright",
    infringement_trademark = "infringement_trademark",
    infringement_privacy = "infringement_privacy",
}

export function ReportAbuseView({
    assetUrn,
    onCancel,
    onSubmit,
}: ReportAbuseProps) {
    const { t } = useTranslation("common");
    const { accessToken, userProfile } = useHi5UserContext();

    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    useEffect(() => {
        if (!userProfile) {
            setName(t("account.displayName.default"));
            setEmail(t("account.email.default"));
        } else {
            setName(userProfile.displayName);
            setEmail(userProfile.email);
        }
    }, [userProfile]);

    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const [selectedSubCategory, setSelectedSubCategory] = useState<Key>();
    const [description, setDescription] = useState<string>("");

    function isSubmitReady() {
        if (!(name && email && isValidEmailFormat(email))) return false;
        if (selectedCategory === ReportAbuseCategory.other) return description;
        if (
            selectedCategory === ReportAbuseCategory.adult ||
            selectedCategory === ReportAbuseCategory.harmful ||
            selectedCategory === ReportAbuseCategory.infringement
        )
            return selectedSubCategory;
        return selectedCategory;
    }

    async function submit() {
        if (!(assetUrn && selectedCategory)) return;
        const options: RequestInit = {
            method: "POST",
            cache: "default",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "x-api-key": ADOBE_IMS_CONFIG.client_id,
                "If-Match": "*",
                "Content-Type": "application/vnd.adobe.abuse-report+json",
            },
        };
        options.body = JSON.stringify({
            productId: "HighFive",
            assetList: [
                {
                    assetId: assetUrn,
                },
            ],
            reportDate: new Date(Date.now()),
            reportType: selectedSubCategory || selectedCategory,
            reportDesc: description,
            reporterName: name,
            reporterEmail: email,
        });
        const response = await fetch(
            `${ASSET_APIS.leia}/acp_report_abuse`,
            options,
        );
        if (response.ok) {
            if (onSubmit) onSubmit(response.status);
            ToastQueue.positive(t("toast.reportAbuse.successful"), {
                timeout: 5000,
            });
        } else {
            ToastQueue.negative(t("toast.reportAbuse.fail"), { timeout: 5000 });
        }
        return response;
    }

    return (
        <Dialog width="size-6000">
            <Heading>{t("reportAbuse.header")}</Heading>
            <Divider />
            <Content>
                <Flex direction="column" gap="size-275">
                    <View>
                        <Text>
                            {t("reportAbuse.description.1")}
                            <a
                                href={t("reportAbuse.description.link.url")}
                                target="_blank"
                                rel="noreferrer">
                                {t("reportAbuse.description.link.title")}
                            </a>
                            {t("reportAbuse.description.2")}
                        </Text>
                    </View>
                    <View width="size-2400">
                        <TextField
                            label={t("reportAbuse.name.label")}
                            value={name}
                            onChange={setName}
                            isDisabled={!!userProfile}
                        />
                        <TextField
                            label={t("reportAbuse.email.label")}
                            value={email}
                            onChange={setEmail}
                            isDisabled={!!userProfile}
                        />
                    </View>
                    <View>
                        <RadioGroup
                            label={t("reportAbuse.category.description")}
                            value={selectedCategory}
                            onChange={(value) => {
                                setSelectedCategory(value);
                                setSelectedSubCategory(undefined);
                            }}>
                            <Radio value={ReportAbuseCategory.spam}>
                                {t("reportAbuse.category.spam")}
                            </Radio>
                            <Radio value={ReportAbuseCategory.adult}>
                                {t("reportAbuse.category.adult")}
                            </Radio>
                            {selectedCategory === ReportAbuseCategory.adult &&
                                ((
                                    <View paddingStart="size-275">
                                        <Picker
                                            onSelectionChange={
                                                setSelectedSubCategory
                                            }>
                                            <Item
                                                key={
                                                    ReportAbuseSubCategory.adult_nudity_sexual_activity
                                                }>
                                                {t(
                                                    "reportAbuse.subCategory.adultNudity",
                                                )}
                                            </Item>
                                            <Item
                                                key={
                                                    ReportAbuseSubCategory.adult_violence_or_gore
                                                }>
                                                {t(
                                                    "reportAbuse.subCategory.adultViolence",
                                                )}
                                            </Item>
                                            <Item
                                                key={
                                                    ReportAbuseSubCategory.adult_profanity
                                                }>
                                                {t(
                                                    "reportAbuse.subCategory.adultProfanity",
                                                )}
                                            </Item>
                                        </Picker>
                                    </View>
                                ) as any)}
                            <Radio value={ReportAbuseCategory.fraud_scam}>
                                {t("reportAbuse.category.fraudScam")}
                            </Radio>
                            <Radio value={ReportAbuseCategory.harmful}>
                                {t("reportAbuse.category.harmful")}
                            </Radio>
                            {selectedCategory === ReportAbuseCategory.harmful &&
                                ((
                                    <View paddingStart="size-275">
                                        <Picker
                                            onSelectionChange={
                                                setSelectedSubCategory
                                            }>
                                            <Item
                                                key={
                                                    ReportAbuseSubCategory.harmful_harassment
                                                }>
                                                {t(
                                                    "reportAbuse.subCategory.harmfulHarassment",
                                                )}
                                            </Item>
                                            <Item
                                                key={
                                                    ReportAbuseSubCategory.harmful_hate_speech
                                                }>
                                                {t(
                                                    "reportAbuse.subCategory.harmfulHateSpeech",
                                                )}
                                            </Item>
                                            <Item
                                                key={
                                                    ReportAbuseSubCategory.harmful_illegal_goods_services
                                                }>
                                                {t(
                                                    "reportAbuse.subCategory.harmfulIllegal",
                                                )}
                                            </Item>
                                            <Item
                                                key={
                                                    ReportAbuseSubCategory.harmful_promotes_terrorism
                                                }>
                                                {t(
                                                    "reportAbuse.subCategory.harmfulTerrorism",
                                                )}
                                            </Item>
                                            <Item
                                                key={
                                                    ReportAbuseSubCategory.harmful_behavior_with_minors
                                                }>
                                                {t(
                                                    "reportAbuse.subCategory.harmfulMinors",
                                                )}
                                            </Item>
                                        </Picker>
                                    </View>
                                ) as any)}
                            <Radio value={ReportAbuseCategory.self_harm}>
                                {t("reportAbuse.category.selfHarm")}
                            </Radio>
                            <Radio value={ReportAbuseCategory.infringement}>
                                {t("reportAbuse.category.infringement")}
                            </Radio>
                            {selectedCategory ===
                                ReportAbuseCategory.infringement &&
                                ((
                                    <View paddingStart="size-275">
                                        <Picker
                                            onSelectionChange={
                                                setSelectedSubCategory
                                            }>
                                            <Item
                                                key={
                                                    ReportAbuseSubCategory.infringement_copyright
                                                }>
                                                {t(
                                                    "reportAbuse.subCategory.infringementCopyright",
                                                )}
                                            </Item>
                                            <Item
                                                key={
                                                    ReportAbuseSubCategory.infringement_trademark
                                                }>
                                                {t(
                                                    "reportAbuse.subCategory.infringementTrademark",
                                                )}
                                            </Item>
                                            <Item
                                                key={
                                                    ReportAbuseSubCategory.infringement_privacy
                                                }>
                                                {t(
                                                    "reportAbuse.subCategory.infringementPrivacy",
                                                )}
                                            </Item>
                                        </Picker>
                                    </View>
                                ) as any)}
                            <Radio value={ReportAbuseCategory.misinformation}>
                                {t("reportAbuse.category.misinformation")}
                            </Radio>
                            <Radio value={ReportAbuseCategory.other}>
                                {t("reportAbuse.category.other")}
                            </Radio>
                            {selectedCategory === ReportAbuseCategory.other &&
                                ((
                                    <View paddingStart="size-300">
                                        <TextArea
                                            height="size-1700"
                                            label={t(
                                                "reportAbuse.concern.description",
                                            )}
                                            width="size-3600"
                                            maxLength={1000}
                                            value={description}
                                            onChange={setDescription}
                                        />
                                    </View>
                                ) as any)}
                        </RadioGroup>
                    </View>
                    <Text
                        UNSAFE_style={{
                            fontSize: "0.8em",
                            color: "#d91c15",
                        }}>
                        *{t("reportAbuse.required")}
                    </Text>
                </Flex>
            </Content>
            <Footer>
                <Flex direction="row" width="100%" justifyContent="end">
                    <ButtonGroup>
                        <Button variant="secondary" onPress={onCancel}>
                            {t("actions.cancel")}
                        </Button>
                        <Button
                            variant="accent"
                            onPress={submit}
                            isDisabled={!isSubmitReady()}
                            autoFocus>
                            {t("actions.submit")}
                        </Button>
                    </ButtonGroup>
                </Flex>
            </Footer>
        </Dialog>
    );
}
