/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { AdobeViewer } from "@3di/adobe-3d-viewer";
import { Animation } from "@babylonjs/core/Animations/animation";
import { GizmoManager } from "@babylonjs/core/Gizmos/gizmoManager";
import "@babylonjs/core/Shaders/glowBlurPostProcess.fragment";
import "@babylonjs/core/Shaders/pass.fragment";
import "@babylonjs/core/Rendering/outlineRenderer";
import { HighlightLayer } from "@babylonjs/core/Layers/highlightLayer";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import { Quaternion, Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { Sprite } from "@babylonjs/core/Sprites/sprite";
import { SpriteManager } from "@babylonjs/core/Sprites/spriteManager";

import { BabylonGUIManager } from "./BabylonGUIManager";
import ResetSpriteSheet from "@src/images/reset-sprite-sheet.png";
import {
    EnvironmentBuilder,
    pedHeightMap,
} from "@src/scene/EnvironmentBuilder";
import { PinningSession } from "@src/scene/PinningSession";
import type {
    Quaternion as NetworkQuaternion,
    Vector3 as NetworkVector3,
} from "@src/util/NetworkDataUtils";

const MIN_SIZE_DISTANCE = 13;
const MAX_SIZE_DISTANCE = 0.5;
const MIN_SIZE = 1.2;
const MAX_SIZE = 0.1;

const HOLD_START_INDEX = 0;
const HOLD_END_INDEX = 31;
const DEFAULT_INDEX = 32;
const DISABLED_INDEX = 33;
const HOVER_INDEX = 34;

const PERSONAL_HIGHLIGHT_COLOR = "#1379F3";
const AUTO_RESET_RADIUS = 0.5;

export class ObjectManager extends BabylonGUIManager {
    private pinningSession: PinningSession | undefined;

    gizmoManager: GizmoManager;
    isGizmoEnabled: boolean = false;

    private modelRootMesh: AbstractMesh | null;

    private autoResetEnabled: boolean = false;
    resetSprite: Sprite | undefined;
    isResetDisabled: boolean = false;
    isResetVisible: boolean = false;

    private envBounds: { min: Vector3; max: Vector3 } | undefined;
    private pedestalBounds: { min: Vector3; max: Vector3 } | undefined;

    previousPosition: Vector3 = Vector3.Zero();
    previousRotation: Vector3 = Vector3.Zero();

    private highlightLayer: HighlightLayer;

    private resetTimer: Timer | undefined;

    constructor(
        private viewer: AdobeViewer,
        pinningSession: PinningSession,
    ) {
        super(viewer.sceneManager.scene);

        this.pinningSession = pinningSession;

        this.modelRootMesh = this.scene.getMeshByName("modelRootMesh");
        this.gizmoManager = new GizmoManager(this.scene);
        this.gizmoManager.positionGizmoEnabled = true;
        this.gizmoManager.rotationGizmoEnabled = true;

        if (this.modelRootMesh) {
            this.gizmoManager.attachableMeshes = [this.modelRootMesh];
            this.modelRootMesh.getChildMeshes().forEach((mesh) => {
                mesh.renderingGroupId = 0;
            });
        }

        this.highlightLayer = new HighlightLayer("highlightLayer", this.scene, {
            isStroke: true,
            renderingGroupId: 0,
            blurHorizontalSize: 0.5,
            blurVerticalSize: 0.5,
        });

        this.renderResetButton();

        const envMesh = this.getEnvironmentMesh();
        if (envMesh) {
            this.envBounds = envMesh.getHierarchyBoundingVectors();
        }
        const pedestalMesh = this.getPedestalMesh();
        if (pedestalMesh) {
            this.pedestalBounds =
                this.getPedestalMesh().getHierarchyBoundingVectors();
        }
    }

    enableAllGizmos() {
        this.gizmoManager.positionGizmoEnabled = true;
        this.gizmoManager.rotationGizmoEnabled = true;
        this.enableGizmos();
    }

    enablePositionGizmoOnly() {
        this.gizmoManager.positionGizmoEnabled = true;
        this.gizmoManager.rotationGizmoEnabled = false;
        this.enableGizmos();
    }

    enableRotationGizmoOnly() {
        this.gizmoManager.positionGizmoEnabled = false;
        this.gizmoManager.rotationGizmoEnabled = true;
        this.enableGizmos();
    }

    private enableGizmos() {
        if (!this.isGizmoEnabled) {
            this.gizmoManager.attachToMesh(this.modelRootMesh);
            this.highlightMesh();
            this.isGizmoEnabled = true;
            this.pinningSession?.pinManager.togglePinsVisibility();
        }
    }

    disableGizmos() {
        if (this.isGizmoEnabled) {
            this.gizmoManager.attachToMesh(null);
            this.unhighlightMesh();
            this.isGizmoEnabled = false;
            this.pinningSession?.pinManager.togglePinsVisibility();
        }
    }

    highlightMesh(color: string = PERSONAL_HIGHLIGHT_COLOR) {
        if (!this.modelRootMesh) return;

        this.highlightLayer.addMesh(
            this.modelRootMesh as Mesh,
            Color3.FromHexString(color),
        );

        const childMeshes = this.modelRootMesh.getChildMeshes();
        childMeshes.forEach((mesh) => {
            this.highlightLayer.addMesh(
                mesh as Mesh,
                Color3.FromHexString(color),
            );

            if (mesh.name !== "__root__") {
                mesh.renderOverlay = true;
                mesh.overlayColor = Color3.FromHexString(color);
                mesh.overlayAlpha = 0.2;
            }
        });
    }

    unhighlightMesh() {
        if (!this.modelRootMesh) return;

        this.highlightLayer.removeAllMeshes();

        const childMeshes = this.modelRootMesh.getChildMeshes();
        childMeshes.forEach((mesh) => {
            mesh.renderOverlay = false;
        });
    }

    renderResetButton() {
        const resetSpriteManager = new SpriteManager(
            "resetSpriteManager",
            ResetSpriteSheet,
            1,
            540,
            this.scene,
        );
        resetSpriteManager.isPickable = true;

        const reset = new Sprite("resetButton", resetSpriteManager);

        if (EnvironmentBuilder.pedestal === "none") {
            reset.position = Vector3.Zero();
        } else {
            reset.position.y = pedHeightMap[EnvironmentBuilder.pedestal] - 0.2;
        }

        reset.width = 0.8;
        reset.height = 0.8;
        resetSpriteManager.renderingGroupId = 3;
        reset.cellIndex = 32;
        reset.isVisible = false;
        reset.isPickable = true;

        this.resetSprite = reset;
        this.scaleButton();
    }

    startBorderAnimation() {
        this.resetSprite?.playAnimation(
            HOLD_START_INDEX,
            HOLD_END_INDEX,
            false,
            32,
        );
    }

    stopBorderAnimation() {
        if (this.resetSprite) {
            this.resetSprite.stopAnimation();
            this.resetSprite.cellIndex = DEFAULT_INDEX;
        }
    }

    scaleButton() {
        if (!this.resetSprite) {
            console.error("Button sprite not found");
            return;
        }

        this.scaleSprite(
            this.resetSprite,
            MIN_SIZE_DISTANCE,
            MAX_SIZE_DISTANCE,
            MIN_SIZE,
            MAX_SIZE,
        );
    }

    autoReset(callback: () => void) {
        if (!this.modelRootMesh) return;
        const distanceFromOrigin = Vector3.Distance(
            EnvironmentBuilder.defaultPosition.clone(),
            this.modelRootMesh.position,
        );

        if (distanceFromOrigin > AUTO_RESET_RADIUS) {
            this.autoResetEnabled = true;
        } else {
            if (this.autoResetEnabled) {
                this.resetModelTransform();
                callback();
            }
        }
    }

    handlePointerMove() {
        if (!this.resetSprite) {
            console.error("Button sprite not found");
            return;
        }
        if (!this.isResetVisible || this.isResetDisabled) {
            window.document.body.style.cursor = "";
            return;
        }

        const pickResult = this.scene.pickSprite(
            this.scene.pointerX,
            this.scene.pointerY,
        );
        if (pickResult && pickResult.pickedSprite === this.resetSprite) {
            window.document.body.style.cursor = "pointer";
            this.resetSprite.cellIndex = HOVER_INDEX;
        } else {
            window.document.body.style.cursor = "";
            this.resetSprite.cellIndex = DEFAULT_INDEX;
        }
    }

    handlePickResetStart(callback: () => void) {
        const pickSpriteResult = this.scene.pickSprite(
            this.scene.pointerX,
            this.scene.pointerY,
        );
        if (
            pickSpriteResult &&
            pickSpriteResult.pickedSprite === this.resetSprite
        ) {
            if (!this.isResetVisible || this.isResetDisabled) return;
            this.startBorderAnimation();
            this.resetTimer = setTimeout(() => {
                this.resetModelTransform();

                callback();
            }, 1000);
        }
    }

    handlePickResetStop() {
        this.stopBorderAnimation();
        clearTimeout(this.resetTimer);
    }

    handlePickMesh() {
        // block mesh highlighting while in pinning mode
        if (this.pinningSession?.inPinningMode) return;

        // block mesh highlighting when selecting pin
        const pickSprite = this.scene.pickSprite(
            this.scene.pointerX,
            this.scene.pointerY,
        );
        if (pickSprite && pickSprite.hit) return;

        const pickMeshResult = this.scene.pick(
            this.scene.pointerX,
            this.scene.pointerY,
        );

        if (pickMeshResult && pickMeshResult.pickedMesh) {
            this.enableAllGizmos();
        } else {
            this.disableGizmos();
        }
    }

    toggleResetVisibility(isVisible: boolean) {
        if (this.isResetVisible === isVisible) return;
        if (!this.resetSprite) return;
        this.resetSprite.isVisible = isVisible;
        this.isResetVisible = isVisible;
        if (isVisible) {
            this.scaleButton();
        }
    }

    toggleResetDisabled(isDisabled: boolean) {
        if (this.isResetDisabled === isDisabled) return;
        if (!this.resetSprite) {
            console.error("Button sprite not found");
            return;
        }
        this.isResetDisabled = isDisabled;
        if (this.isResetDisabled) {
            this.resetSprite.cellIndex = DISABLED_INDEX;
        } else {
            this.resetSprite.cellIndex = DEFAULT_INDEX;
        }
    }

    resetModelTransform() {
        if (!this.modelRootMesh) return;

        // animate lerping
        const positionAnimation = new Animation(
            "resetPosAnimation",
            "position",
            30,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_CONSTANT,
        );

        const keyFramesPos = [];
        keyFramesPos.push({
            frame: 0,
            value: this.modelRootMesh.position.clone(),
        });
        keyFramesPos.push({
            frame: 15,
            value: EnvironmentBuilder.defaultPosition.clone(),
        });

        positionAnimation.setKeys(keyFramesPos);

        const rotationAnimation = new Animation(
            "resetRotAnimation",
            "rotation",
            30,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_CONSTANT,
        );

        const keyFramesRot = [];
        keyFramesRot.push({
            frame: 0,
            value: this.modelRootMesh.rotation.clone(),
        });
        keyFramesRot.push({
            frame: 15,
            value: EnvironmentBuilder.defaultRotation.clone(),
        });

        rotationAnimation.setKeys(keyFramesRot);

        this.scene.beginDirectAnimation(
            this.modelRootMesh,
            [positionAnimation, rotationAnimation],
            0,
            15,
            false,
        );

        this.autoResetEnabled = false;
        this.toggleResetVisibility(false);
    }

    getPosition() {
        if (!this.modelRootMesh) return;
        return this.webToVRPosition(this.modelRootMesh.position);
    }

    getRotation() {
        if (!this.modelRootMesh) return;
        return this.webToVRRotation(this.modelRootMesh.rotation);
    }

    updatePosition(position: NetworkVector3) {
        if (!this.modelRootMesh) return;
        const webPosition = this.vrToWebPosition(Vector3.FromArray(position));
        this.modelRootMesh.position.x = webPosition[0];
        this.modelRootMesh.position.y = webPosition[1];
        this.modelRootMesh.position.z = webPosition[2];
        return;
    }

    updateRotation(rotation: NetworkQuaternion) {
        if (!this.modelRootMesh) return;
        const webRotation = this.vrToWebRotation(
            Quaternion.FromArray(rotation).toEulerAngles(),
        );
        this.modelRootMesh.rotation =
            Quaternion.FromArray(webRotation).toEulerAngles();
    }

    /**
     * @summary The VR position is used as ground truth, which is the equivalent of the pivotMesh position.
     * To convert the web modelRootMesh position to VR space:
     * 1. Negate transforms applied environment (pedestal height, grounding, etc.)
     * 2. Negate x and z coordinates
     * To avoid platform based encoding/decoding of data, web will send VR space positions to everybody and handle
     * the conversion on the client side.
     *
     * @param webPosition position in web space
     * @returns web position converted to VR space
     */
    webToVRPosition(webPosition: Vector3) {
        const vrPosition = webPosition
            .clone()
            .subtract(EnvironmentBuilder.defaultPosition);
        vrPosition.x = -vrPosition.x;
        vrPosition.z = -vrPosition.z;
        return vrPosition.asArray() as NetworkVector3;
    }

    /**
     * @summary Convert VR space position to web space position
     *
     * @param vrPosition
     * @returns VR position converted to web space
     */
    vrToWebPosition(vrPosition: Vector3) {
        const webPosition = vrPosition
            .clone()
            .add(EnvironmentBuilder.defaultPosition);
        webPosition.x = -webPosition.x;
        webPosition.z = -webPosition.z;
        return webPosition.asArray() as NetworkVector3;
    }

    /**
     * @summary The VR rotation is used as ground truth, which is the equivalent of the pivotMesh rotation.
     * To convert the web modelRootMesh rotation to VR space:
     * 1. Negate transforms applied environment (orientation, etc.)
     * 2. Negate x and z coordinates
     * To avoid platform based encoding/decoding of data, web will send VR space rotations to everybody and handle
     * the conversion on the client side.
     *
     * @param webRotation
     */
    webToVRRotation(webRotation: Vector3) {
        const envRotationInverse = Quaternion.FromEulerVector(
            EnvironmentBuilder.defaultRotation.negate(),
        );
        const vrRotation = webRotation
            .clone()
            .applyRotationQuaternion(envRotationInverse);
        vrRotation.x = -vrRotation.x;
        vrRotation.z = -vrRotation.z;
        return Quaternion.FromEulerVector(
            vrRotation,
        ).asArray() as NetworkQuaternion;
    }

    /**
     * @summary Convert VR space rotation to web space rotation
     *
     * @param vrRotation
     * @returns VR rotation converted to web space
     */
    vrToWebRotation(vrRotation: Vector3) {
        const envRotation = Quaternion.FromEulerVector(
            EnvironmentBuilder.defaultRotation,
        );
        const webRotation = vrRotation
            .clone()
            .applyRotationQuaternion(envRotation);
        webRotation.x = -webRotation.x;
        webRotation.z = -webRotation.z;
        return Quaternion.FromEulerVector(
            webRotation,
        ).asArray() as NetworkQuaternion;
    }

    getRootMesh() {
        return this.modelRootMesh as AbstractMesh;
    }

    getEnvironmentMesh() {
        const envNode = this.scene
            .getNodes()
            .find((node) => node.name.includes("reviewer_environment"));
        if (envNode?.parent) {
            return envNode.parent as AbstractMesh;
        } else {
            return envNode as AbstractMesh;
        }
    }

    getPedestalMesh() {
        const pedestalNode = this.scene
            .getNodes()
            .find((node) => node.name.includes("pedestal"));
        if (pedestalNode?.parent) {
            return pedestalNode.parent as AbstractMesh;
        } else {
            return pedestalNode as AbstractMesh;
        }
    }

    getRootMeshBounds() {
        return this.modelRootMesh?.getHierarchyBoundingVectors();
    }

    /**
     *
     * @returns true if the asset is intersecting the environment
     */
    isCollidingEnv() {
        const bounds = this.getRootMeshBounds();
        if (!bounds || !bounds.min || !bounds.max || !this.envBounds)
            return false;
        if (this.getEnvironmentMesh().name.includes("grid")) {
            return bounds.min.y < this.envBounds.min.y;
        } else {
            return !(
                bounds.min.x >= this.envBounds.min.x &&
                bounds.max.x <= this.envBounds.max.x &&
                bounds.min.y >= this.envBounds.min.y &&
                bounds.max.y <= this.envBounds.max.y &&
                bounds.min.z >= this.envBounds.min.z &&
                bounds.max.z <= this.envBounds.max.z
            );
        }
    }

    /**
     *
     * @returns true if the asset is intersecting the pedestal
     */
    isCollidingPedestal() {
        const bounds = this.getRootMeshBounds();
        if (!bounds || !bounds.min || !bounds.max || !this.pedestalBounds)
            return false;
        return !(
            bounds.min.x >= this.pedestalBounds.max.x ||
            bounds.max.x <= this.pedestalBounds.min.x ||
            bounds.min.y >= this.pedestalBounds.max.y ||
            bounds.max.y <= this.pedestalBounds.min.y ||
            bounds.min.z >= this.pedestalBounds.max.z ||
            bounds.max.z <= this.pedestalBounds.min.z
        );
    }
}
