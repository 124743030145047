/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export type Vector3 = [number, number, number];
export type Quaternion = [number, number, number, number];

export enum INTEREST_GROUPS {
    VR = 1,
    WEB = 2,
}

// -------------------------------------------------------------------------- //
// ----------------------------- HeartbeatData ------------------------------ //
// -------------------------------------------------------------------------- //

export interface HeartbeatData {
    time?: number;
    assetOwner: number;
    playerCount: number;
    playerData: Record<number, number>; // mapping of player actor number to packedStateFlags
}

export function packHeartbeatData(heartbeatData: HeartbeatData) {
    // 2 bytes per player and 6 bytes for the timestamp, asset owner, and player count
    // Calculate the total size needed for the byte array
    const totalSize = heartbeatData.playerCount * 2 + 6;
    const byteArray = new ArrayBuffer(totalSize);
    const data = new DataView(byteArray);
    let index = 0;

    // Timestamp (4 bytes)
    data.setFloat32(index, getTimestamp());
    index += 4;

    // AssetOwner (1 byte)
    data.setUint8(index, heartbeatData.assetOwner);
    index += 1;

    // PlayerDataCount (1 byte)
    data.setUint8(index, heartbeatData.playerCount);
    index += 1;

    // PlayerData (2 bytes per player)
    for (const playerNumber in heartbeatData.playerData) {
        const playerStateFlags = heartbeatData.playerData[playerNumber];
        data.setUint8(index, Number(playerNumber));
        index += 1;
        data.setUint8(index, playerStateFlags);
        index += 1;
    }

    return convertArrayBuffertoArray(data.buffer);
}

export function unpackHeartbeatData(rawData: number[], lastUpdateTime: number) {
    // Verify structure of heartbeat message
    if (rawData.length < 6 || (rawData.length - 6) % 2 != 0) {
        throw new Error("Invalid heartbeat message");
    }

    const data = convertArraytoDataView(rawData);
    let index = 0;
    // Unpack timestamp
    const time = data.getFloat32(index);
    index += 4;
    if (time < lastUpdateTime) {
        // data is old, skip
        return undefined;
    }

    // Unpack assetOwner
    const assetOwner = data.getUint8(index);
    index += 1;

    // Unpack playerCount
    const playerCount = data.getUint8(index);
    index += 1;

    // Check if playerCount matches playerData size
    if ((rawData.length - 6) / 2 !== playerCount) {
        throw new Error(
            "Invalid heartbeat message - mismatch between player count and player data size",
        );
    }

    // Unpack playerData
    const playerData: Record<number, number> = {};
    for (let i = 0; i < playerCount; i++) {
        // Unpack player number
        const playerNumber = data.getUint8(index);
        index += 1;

        // Unpack player heartbeat data
        const playerStateFlags = data.getUint8(index);
        index += 1;

        playerData[playerNumber] = playerStateFlags;
    }

    const heartbeatData = {
        time: time,
        assetOwner: assetOwner,
        playerCount: playerCount,
        playerData: playerData,
    };

    return heartbeatData;
}

// -------------------------------------------------------------------------- //
// ---------------------------- NetworkAssetData ---------------------------- //
// -------------------------------------------------------------------------- //

/**
 * @summary
 * NetworkAssetData is a data structure that holds all the data that needs to be sent
 * over the network for the asset.
 * This includes data for the owner of the asset and transform data for the asset
 * Byte Layout for High Frequency Data:
 * 0-3: Timestamp (float - 4 bytes) - for checking event order and latency
 * 4-35: Transform Data (31 bytes):
 *     4-7: Position X (float)
 *     8-11: Position Y (float)
 *     12-15: Position Z (float)
 *     16-19: Rotation X (float)
 *     20-23: Rotation Y (float)
 *     24-27: Rotation Z (float)
 *     28-31: Rotation W (float)
 *     32-35: Scale (float)
 */
export interface NetworkAssetData {
    // Low Frequency Data - Updated infrequently or situationally
    // Will be included in the heartbeat message
    ownerActorNumber?: number; // Actor Number of the current controller of the asset, 0 if no one is controlling it

    // High Frequency Data - Updated every frame
    time?: number;
    position: Vector3;
    rotation: Quaternion;
    scale: number; // no non-uniform scaling
}

/**
 * @summary
 * Function to pack all high frequency network data for an asset into a byte array.
 * This data will be sent to all other players every frame (or appropriate interval)
 * by the player controlling the asset.
 *
 * @param assetData NetworkAssetData to be packed
 * @returns byte array containing asset data
 */
export function packHighFreqNetworkAssetData(assetData: NetworkAssetData) {
    const data = new Float32Array(9); // 9 values to store, each containing 4 bytes = 36 bytes total
    let index = 0;

    // Timestamp (4 bytes)
    copyFloatToByteArray(getTimestamp(), data, index); // time since web page loaded in ms
    index += 1;

    // Pack transform data (32 bytes)
    copyVector3ToByteArray(assetData.position, data, index);
    index += 3;
    copyQuaternionToByteArray(assetData.rotation, data, index);
    index += 4;
    copyFloatToByteArray(assetData.scale, data, index);
    return convertArrayBuffertoArray(data.buffer);
}

/**
 * @summary
 * Function to unpack all high frequency network data for an asset from a byte array.
 * This data is received from the player controlling the asset every frame (or appropriate interval).
 *
 * @param byteArray byte array containing asset data
 * @param lastUpdateTime last time high frequency data was updated
 * @returns NetworkAssetData object with new data or undefined if data is old
 */
export function unpackHighFreqNetworkAssetData(
    rawData: number[],
    lastUpdateTime: number,
) {
    const data = convertArraytoFloat32Array(rawData);
    let index = 0;

    // Unpack timestamp
    const time = readFloatFromByteArray(data, index);
    index += 1;

    if (time < lastUpdateTime) {
        index += 8;
        // data is old, skip it
        return undefined;
    }

    // Unpack transform data
    const position = readVector3FromByteArray(data, index);
    index += 3;
    const rotation = readQuaternionFromByteArray(data, index);
    index += 4;
    const scale = readFloatFromByteArray(data, index);

    const assetData: NetworkAssetData = {
        time: time,
        position: position,
        rotation: rotation,
        scale: scale,
    };

    return assetData;
}

// -------------------------------------------------------------------------- //
// --------------------------- NetworkPlayerData ---------------------------- //
// -------------------------------------------------------------------------- //

/**
 * @summary
 * NetworkPlayerData is a data structure that holds all the data that needs to be sent over the network for players.
 * Some data never changes once set and is stored in Photon's custom properties for each player.
 * Player States are packed into a single byte for efficient transport and are included in th host's heartbeat message.
 * If any of the player states change, the player will send an update message to everyon that overrides the last heartbeat.
 * The next heartbeat will also reflect the changes.
 * High frequency data is sent every frame (or appropriate interval ~30/s) by each player
 * It includes transform data for the player and their laser pointers.
 * The host does not forward this data to other clients and is so frequent that missing a frame is not a big deal.
 *
 * Byte Layout for Low Frequency Data:
 * 0-3: Timestamp (float - 4 bytes) - for checking latency
 * 4: PackedStateFlags (byte - 1 byte) - packed data for the player's state flags
 */
export interface NetworkPlayerLowFreqData {
    // Persistent Data - stored in photon's custom properties for each player
    // On joining a room, this data is loaded by looking at each player's custom properties
    // actorNumber: number;

    // Low Frequency Data - Updated infrequently or situationally
    // Will be included in the heartbeat message
    time?: number;
    packedStateFlags: number;
}

export enum PlayerStateFlags {
    isIdle = 1 << 0, // 0b00000001
    isTyping = 1 << 1, // 0b00000010
    isHandRaised = 1 << 2, // 0b00000100
    isMuted = 1 << 3, // 0b00001000
    isTalking = 1 << 4, // 0b00010000
    hasMutedAudio = 1 << 5, // 0b00100000
    // Bits 6-7 reserved for future use
}

/**
 * @summary
 * Byte Layout for High Frequency Data:
 * 0-3: Timestamp (float - 4 bytes) - for checking latency
 * 4-31: Transform Data (28 bytes):
 *     4-7: Position X (float)
 *     8-11: Position Y (float)
 *     12-15: Position Z (float)
 */
export interface NetworkPlayerHighFreqData {
    // High Frequency Data - Updated every frame (or appropriate interval)
    time?: number;
    // Transform data for the player
    position: Vector3;
    // laserPointerData: NetworKLaserPointerData;
}

/**
 * Function to set each bit of the PlayerStateFlags
 *
 * @param packedStateFlags packedStateFlags 8 bit number to be updated
 * @param flag flag to be updated
 * @param value value to update the flag to (0 or 1)
 * @returns updated packedStateFlags 8 bit number as Uint8Array
 */
export function setPlayerStateFlag(
    packedStateFlags: number,
    flag: PlayerStateFlags,
    value: number,
) {
    return value ? packedStateFlags | flag : packedStateFlags & ~flag;
}

/**
 * Function to pack all low frequency network data for a player into a byte array.
 * Player will broadcast this data to all other players when it changes.
 */
export function packLowFreqNetworkPlayerData(
    playerLowFreqData: NetworkPlayerLowFreqData,
) {
    const byteArray = new ArrayBuffer(5); // create empty byte array containing 5 bytes
    const data = new DataView(byteArray);

    data.setFloat32(0, getTimestamp());
    data.setUint8(4, playerLowFreqData.packedStateFlags);

    return convertArrayBuffertoArray(data.buffer);
}

export function unpackLowFreqNetworkPlayerData(
    rawData: number[],
    lastUpdateTime: number,
) {
    const data = convertArraytoDataView(rawData);
    let index = 0;

    // Unpack timestamp
    const time = data.getFloat32(index);
    index += 4;
    if (time < lastUpdateTime) {
        // data is old, skip
        return undefined;
    }

    // Unpack PackedStateFlags
    const packedStateFlags = data.getUint8(index);

    const playerLowFreqData: NetworkPlayerLowFreqData = {
        time: time,
        packedStateFlags: packedStateFlags,
    };

    return playerLowFreqData;
}

export function packHighFreqNetworkPlayerData(
    playerHighFreqData: NetworkPlayerHighFreqData,
) {
    const data = new Float32Array(4); // 8 values to store, each containing 4 bytes = 32 bytes total
    let index = 0;

    // Timestamp (4 bytes)
    copyFloatToByteArray(getTimestamp(), data, index); // time since web page loaded in ms
    index += 1;

    // Pack transform data (32 bytes)
    copyVector3ToByteArray(playerHighFreqData.position, data, index);

    return convertArrayBuffertoArray(data.buffer);
}

/**
 * @summary
 * Function to unpack all high frequency network data for an asset from a byte array.
 * This data is received from the player controlling the asset every frame (or appropriate interval).
 *
 * @param byteArray byte array containing asset data
 * @param lastUpdateTime last time high frequency data was updated
 * @returns NetworkAssetData object with new data or undefined if data is old
 */
export function unpackHighFreqNetworkPlayerData(
    rawData: number[],
    lastUpdateTime: number,
) {
    const data = convertArraytoFloat32Array(rawData);
    let index = 0;

    // Unpack timestamp
    const time = readFloatFromByteArray(data, index);
    index += 1;

    if (time < lastUpdateTime) {
        index += 8;
        // data is old, skip it
        return undefined;
    }

    // // Unpack transform data
    const position = readVector3FromByteArray(data, index);
    index += 3;

    const playerHighFreqData: NetworkPlayerHighFreqData = {
        time: time,
        position: position,
    };

    return playerHighFreqData;
}

// -------------------------------------------------------------------------- //
// ------------------------ NetworkLaserPointerData ------------------------- //
// -------------------------------------------------------------------------- //

/**
 * @summary
 * NetworkLaserPointerData is a data structure that holds all the data that needs to be sent over the network for LaserPointers.
 * This includes data for the start and end positions of the laser pointer, whether it should be displayed, and the cursor type.
 * It is used to pack and unpack the data into a byte array for transport.
 * It is also used to store the data in a more readable format for the client.
 * Byte Layout for High Frequency Data:
 *     0: StateFlags (1 byte)
 *         Bit 0: ShouldDisplay (1 bit)
 *         Bit 1: IsHoveringOnAsset (1 bit)
 *         Bit 2: IsPinning (1 bit)
 *         Bit 3: IsGrabbingAsset (1 bit)
 *         Bits 4-7: Unused (4 bits) - Future expansion
 *     1-12: StartPosition (3 floats - 12 bytes)
 *         1-4: StartPosition X (float)
 *         5-8: StartPosition Y (float)
 *         9-12: StartPosition Z (float)
 *     13-24: EndPosition (3 floats - 12 bytes)
 *         13-16: EndPosition X (float)
 *         17-20: EndPosition Y (float)
 *         21-24: EndPosition Z (float)
 */
interface NetworkLaserPointerData {
    packedStateFlags: LaserStateFlags;
}

enum LaserStateFlags {
    shouldDisplay = 1 << 0, // 0b00000001
    isHoveringOnAsset = 1 << 1, // 0b00000010
    isPinning = 1 << 2, // 0b00000100
    isGrabbingAsset = 1 << 3, // 0b00001000
    // Bits 4-7 reserved for future use
}

// -------------------------------------------------------------------------- //
// --------------------------- NetworkTeleportData -------------------------- //
// -------------------------------------------------------------------------- //

/**
 * @summary
 * NetworkTeleportData is a data structure that holds all the data that needs to be sent
 * over the network when an actor is teleporting to another actor.
 * This includes actor numbers of the source and target actors.
 * Byte Layout for data:
 * 0-3: Source actor number (float - 4 bytes)
 * 4-7: Target actor number (float - 4 bytes)
 */
export interface NetworkTeleportData {
    // Low Frequency Data - Updated infrequently or situationally
    sourceActorNumber: number; // Actor Number of the source actor for the teleport event
    targetActorNumber: number; // Actor Number of the target actor for the teleport event
}

/**
 * @summary
 * Function to pack teleport data into a byte array.
 * This data will be sent to all other players when a teleport is initiated by the
 * source actor.
 *
 * @param assetData NetworkTeleportData to be packed
 * @returns byte array containing teleport data
 */
export function packNetworkTeleportData(teleportData: NetworkTeleportData) {
    const data = new Float32Array(2); // 2 values to store, each containing 4 bytes = 8 bytes total
    let index = 0;

    // Source Actor Number (4 bytes)
    copyFloatToByteArray(teleportData.sourceActorNumber, data, index);
    index += 1;

    // Target Actor Number (4 bytes)
    copyFloatToByteArray(teleportData.targetActorNumber, data, index);
    return convertArrayBuffertoArray(data.buffer);
}

/**
 * @summary
 * Function to unpack teleport data from a byte array.
 * This data is received from the actor that trigger a teleport to another actor.
 *
 * @param byteArray byte array containing teleport data
 * @returns NetworkTeleportData object with unpacked data
 */
export function unpackNetworkTeleportData(rawData: number[]) {
    const data = convertArraytoFloat32Array(rawData);
    let index = 0;

    // Unpack source actor number
    const sourceActorNumber = readFloatFromByteArray(data, index);
    index += 1;

    const targetActorNumber = readFloatFromByteArray(data, index);

    const teleportData: NetworkTeleportData = {
        sourceActorNumber,
        targetActorNumber,
    };

    return teleportData;
}

// -------------------------------------------------------------------------- //
// ---------------------------- Helper Functions ---------------------------- //
// -------------------------------------------------------------------------- //

function copyFloatToByteArray(
    float: number,
    data: Float32Array,
    index: number,
) {
    data.set([float], index);
}

function copyVector3ToByteArray(
    vector: Vector3,
    data: Float32Array,
    index: number,
) {
    data.set(vector, index);
}

function copyQuaternionToByteArray(
    quaternion: Quaternion,
    data: Float32Array,
    index: number,
) {
    data.set(quaternion, index);
}

function readFloatFromByteArray(data: Float32Array, index: number) {
    return data[index];
}

function readVector3FromByteArray(data: Float32Array, index: number) {
    const vector: Vector3 = [0, 0, 0];
    let vIdx = 0;
    for (let i = index; i < index + 3; i++) {
        vector[vIdx] = data[i];
        vIdx++;
    }
    return vector;
}

function readQuaternionFromByteArray(data: Float32Array, index: number) {
    const quaternion: Quaternion = [0, 0, 0, 0];
    let qIdx = 0;
    for (let i = index; i < index + 4; i++) {
        quaternion[qIdx] = data[i];
        qIdx++;
    }
    return quaternion;
}

function getTimestamp() {
    return performance.now() / 1000;
}

function convertArrayBuffertoArray(buffer: ArrayBufferLike) {
    // Create a new  8 bit view to access the binary data using the buffer of the Float32Array view
    const byteArray = new Uint8Array(buffer);
    // Convert to raw array type
    return Array.from(byteArray);
}

function convertArraytoFloat32Array(array: number[]) {
    const byteArray = Uint8Array.from(array);
    return new Float32Array(byteArray.buffer);
}

function convertArraytoDataView(array: number[]) {
    const byteArray = Uint8Array.from(array);
    return new DataView(byteArray.buffer);
}
