/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { JanusRoutes } from "@shared/common";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { PostMessageMenuApi } from "../api/PostMessageMenuApi";
import { useAnalytics } from "../hooks/useAnalytics";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useInputPostBus } from "@src/hooks/useInputPostBus";

import type { MenuMessageBus } from "../api/PostMessageMenuApi";
import { isVRPage } from "@src/config";

type ProviderProps = { children: React.ReactNode };

type PostMessageContextValue = {
    postMessageBusApi: PostMessageMenuApi | undefined;
    refreshMenuInputFields: () => void;
};

export const PostMessageContext = createContext<PostMessageContextValue>(
    {} as PostMessageContextValue,
);

export const usePostMessageContext = () => {
    const context = useContext(PostMessageContext);
    if (!context) {
        throw new Error(
            "usePostMessageContext must be used within a PostMessageProvider.",
        );
    }
    return context;
};

export const PostMessageProvider = ({ children }: ProviderProps): any => {
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [messageBus, setMessageBus] = useState<MenuMessageBus | undefined>(
        window,
    );

    const [postMessageBusApi, setPostMessageBusApi] = useState<
        PostMessageMenuApi | undefined
    >();

    const { userProfile, imsReady } = useHi5UserContext();
    const { vrSessionId, vrDeviceId } = useAnalytics();

    useEffect(() => {
        if (isVRPage) {
            if (window.vuplex) {
                setMessageBus(window.vuplex as MenuMessageBus);
            } 
        } else {
            window.addEventListener("vuplexready", () => {
                setMessageBus(window.vuplex as MenuMessageBus);
            });
        }
    }, [isVRPage]);

    useEffect(() => {
        if (location.pathname.includes(JanusRoutes.oneUp.path)) return; // comment panel page initializes a separate message bus
        if (messageBus) {
            const menuBusApi = new PostMessageMenuApi(messageBus);
            setPostMessageBusApi(menuBusApi);
        }
    }, [messageBus]);

    useEffect(() => {
        if (!postMessageBusApi) return;
        
        postMessageBusApi.webReady(userProfile?.userId ?? "");
        postMessageBusApi.addEventListener("clear-text-focus", () => {
            const activeElement: HTMLElement | null =
                document.activeElement as HTMLElement;
            if (!activeElement) {
                console.error("active text input not found");
                return;
            }
            activeElement.blur();
        });

        postMessageBusApi.addEventListener("set-vr-data", (data: any) => {
            vrSessionId.current = data.detail.sessionGuid;
            vrDeviceId.current = data.detail.deviceID;
        });
    }, [postMessageBusApi]);

    const { refreshInputElements } = useInputPostBus(postMessageBusApi);

    return (
        <PostMessageContext.Provider
            value={{
                postMessageBusApi,
                refreshMenuInputFields: refreshInputElements,
            }}>
            {children}
        </PostMessageContext.Provider>
    );
};
