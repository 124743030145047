/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Provider, defaultTheme as SpectrumTheme } from "@adobe/react-spectrum";
import React, { createContext, useContext, useEffect, useState } from "react";

import { useLocaleContext } from "./LocaleContext";

const LOCAL_STORAGE_ERROR_MSG = "Error accessing local storage";
const LOCAL_STORAGE_KEY = "preferredTheme";

const systemTheme = "system";
const darkTheme = "dark";
const lightTheme = "light";

interface ThemeContextProps {
    themeOptions: { theme: string; name: string }[];
    isDarkMode: boolean;
    currentTheme: string;
    handleThemeChange: (theme: string) => void;
}

interface ThemeProviderProps {
    children: React.ReactNode;
}

function readSavedTheme() {
    try {
        const savedThemePreference = localStorage.getItem(LOCAL_STORAGE_KEY);
        return savedThemePreference;
    } catch (e) {
        console.error(LOCAL_STORAGE_ERROR_MSG, e);
    }
    return undefined;
}

function saveTheme(theme: string) {
    try {
        localStorage.setItem(LOCAL_STORAGE_KEY, theme);
    } catch (e) {
        console.error(LOCAL_STORAGE_ERROR_MSG, e);
    }
}

const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

const ThemeContext = createContext<ThemeContextProps>({
    themeOptions: [],
    isDarkMode: false,
    currentTheme: "",
    handleThemeChange: () => {},
});

export const useThemeContext = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error("useThemeContext must be used within a ThemeProvider.");
    }
    return context;
};

export const ThemeProvider = ({ children }: ThemeProviderProps): any => {
    const { locale } = useLocaleContext();

    const themeOptions = [
        {
            theme: systemTheme,
            name: "account.preferences.colorTheme.mirrorSystem",
        },
        { theme: darkTheme, name: "account.preferences.colorTheme.dark" },
        { theme: lightTheme, name: "account.preferences.colorTheme.light" },
    ];

    const [currentTheme, setCurrentTheme] = useState(
        readSavedTheme() || darkTheme,
    );
    const [isDarkMode, setIsDarkMode] = useState(
        currentTheme === systemTheme
            ? mediaQuery.matches
            : currentTheme === darkTheme,
    );

    useEffect(() => {
        const handler = (e: MediaQueryListEvent) => {
            if (!readSavedTheme() || readSavedTheme() === systemTheme) {
                setIsDarkMode(e.matches);
            }
        };
        mediaQuery.addEventListener("change", handler);
    }, []);

    const handleThemeChange = (theme: string) => {
        setCurrentTheme(theme);
        saveTheme(theme);
        if (theme == darkTheme) {
            setIsDarkMode(true);
        } else if (theme == lightTheme) {
            setIsDarkMode(false);
        } else {
            setIsDarkMode(mediaQuery.matches);
        }
    };

    return (
        <ThemeContext.Provider
            value={{
                themeOptions,
                isDarkMode,
                currentTheme,
                handleThemeChange,
            }}>
            <Provider
                locale={locale}
                theme={SpectrumTheme}
                colorScheme={isDarkMode ? "dark" : "light"}
                scale="medium"
                UNSAFE_className="app">
                {children}
            </Provider>
        </ThemeContext.Provider>
    );
};
