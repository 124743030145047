/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import Cookies from "js-cookie";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

type LocaleProviderProps = { children: React.ReactNode };

interface LocaleContextProps {
    locale: string;
    availableLocales: { code: string; language: string }[];
    changeLocale: (language: string) => void;
    getLanguage: (code: string) => string | undefined;
}

const LocaleContext = createContext<LocaleContextProps>({
    locale: "",
    availableLocales: [],
    changeLocale: () => {},
    getLanguage: () => undefined,
});

export const useLocaleContext = () => {
    const context = useContext(LocaleContext);
    if (!context) {
        throw new Error(
            "useLocaleContext must be used within a LocaleProvider",
        );
    }
    return context;
};

export const LocaleProvider = ({ children }: LocaleProviderProps): any => {
    const { i18n } = useTranslation();

    const [locale, setLocale] = useState<string>(i18n.language as string);
    const availableLocales = [
        { code: "en-US", language: "English (United States)" },
        { code: "fr-FR", language: "Français (France)" },
        { code: "de-DE", language: "Deutsch (Deutschland)" },
        { code: "ja-JP", language: "日本語 (日本)" },
    ];

    const getLanguage = (code: string) => {
        return availableLocales.find((x) => x?.code == code)?.language;
    };

    const changeLocale = (code: string) => {
        setLocale(code);
        i18n.changeLanguage(code);
        Cookies.set('i18next', code, {path: "/"});
    };

    return (
        <LocaleContext.Provider
            value={{ locale, availableLocales, changeLocale, getLanguage }}>
            {children}
        </LocaleContext.Provider>
    );
};
