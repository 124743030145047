/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";

import { AssetNotFoundView } from "../../components/AssetNotFoundView";
import { usePostMessageContext } from "../../contexts/PostMessageContext";

export function AssetNotFoundDialog() {
    const { postMessageBusApi } = usePostMessageContext();


    function goHomeCallback() {
        if (postMessageBusApi) postMessageBusApi.showDialog("start-screen");
    }

    return (
        <View maxHeight="400px" paddingTop="size-100" paddingX="size-100">
            <AssetNotFoundView onGoHome={goHomeCallback} isForVr={true} />
        </View>
    );
}
