/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export async function retryCallbackWithJitter<T>(
    fn: () => Promise<T>,
    numRetries: number,
    retryDelayMs: number,
    retryJitterMs: number,
    log: any,
    onlyIfErrMessage?: string,
): Promise<T> {
    const errors = [];
    let skipRetry = false;
    for (let ctr = 0; ctr < numRetries && !skipRetry; ctr++) {
        try {
            const ret = await fn();
            return ret;
        } catch (err) {
            errors.push(err);
            if (onlyIfErrMessage) {
                if (
                    err &&
                    typeof err === "object" &&
                    "message" in err &&
                    typeof err.message === "string" &&
                    err.message.includes(onlyIfErrMessage)
                ) {
                    log.info(
                        { err, onlyIfErrMessage },
                        "RetryingWithErrMessageMatch",
                    );
                    skipRetry = false;
                } else {
                    log.warn(
                        { err, onlyIfErrMessage },
                        "SkipRetryingWithErrMessageMatch",
                    );
                    skipRetry = true;
                }
            }
            if (!skipRetry) {
                await new Promise((res) =>
                    setTimeout(
                        res,
                        retryDelayMs +
                            Math.floor(Math.random() * retryJitterMs + 1),
                    ),
                );
            }
        }
    }
    log.info({ errors, fn }, "FailedRetryCallback");
    throw errors[errors.length - 1];
}
