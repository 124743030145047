/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View, Flex, Header, Image, Text, Button } from "@adobe/react-spectrum";
import Settings from "@spectrum-icons/workflow/Settings";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { usePostMessageContext } from "../../contexts/PostMessageContext";
import appIcon from "../../images/rw_appicon_noshadow_64.png";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

export function SignedOut() {
    const { t } = useTranslation(["common", "vr"]);
    const { logIn, userProfile, imsReady } = useHi5UserContext();
    const { postMessageBusApi } = usePostMessageContext();
    const [searchParams] = useSearchParams();
    const useSUSI = searchParams.get("use-susi") || undefined;

    useEffect(() => {
        if (postMessageBusApi && imsReady && userProfile)
            postMessageBusApi.showDialog("log-in-check");
    }, [postMessageBusApi, imsReady, userProfile]);

    function resizeWindowAndLogIn() {
        if (!postMessageBusApi) return;
        postMessageBusApi.resizeWindow("log-in-required-susi");
        logIn();
    }

    function quitAppVR() {
        if (postMessageBusApi) postMessageBusApi.quitApp();
    }

    return (
        <View padding="60px">
            <Flex direction="column" gap="40px">
                <Flex
                    direction="row"
                    width="100%"
                    justifyContent="start"
                    alignItems="center"
                    gap="size-200">
                    <Image
                        width="48px"
                        height="48px"
                        minWidth="48px"
                        minHeight="48px"
                        alt={t("common:header.appIcon")}
                        src={appIcon}
                        UNSAFE_style={{
                            borderRadius: "8px",
                        }}
                    />
                    <Header
                        UNSAFE_style={{
                            fontSize: "2.2em",
                            fontWeight: "bold",
                        }}>
                        {t("common:header.appTitle")}
                    </Header>
                </Flex>
                <Text
                    UNSAFE_style={{
                        fontSize: "1.75em",
                    }}>
                    {t("vr:signedOut.welcome")}
                </Text>
                <Text
                    UNSAFE_style={{
                        fontSize: "1.75em",
                    }}>
                    {t("vr:signedOut.startReview.1")}
                    {t("vr:signedOut.startReview.link")}
                    {t("vr:signedOut.startReview.2")}
                </Text>
                <Text
                    UNSAFE_style={{
                        fontSize: "1.75em",
                    }}>
                    {t("vr:signedOut.quitApp.description")}
                </Text>
                <Flex
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    gap="size-200">
                    <Button
                        onPress={
                            useSUSI === "true"
                                ? resizeWindowAndLogIn
                                : quitAppVR
                        }
                        width="100%"
                        variant="cta"
                        UNSAFE_style={{
                            borderRadius: "100px",
                        }}>
                        <Text
                            UNSAFE_style={{
                                fontSize: "1.5em",
                            }}>
                            {useSUSI === "true"
                                ? t("vr:account.signIn")
                                : t("vr:signedOut.quitApp.button")}
                        </Text>
                    </Button>
                    <Button
                        variant="primary"
                        style="fill"
                        onPress={() => {
                            if (postMessageBusApi)
                                postMessageBusApi.showAppSettings();
                        }}>
                        <Settings />
                    </Button>
                </Flex>
            </Flex>
        </View>
    );
}
