/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const ns = ["common", "vr", "web"] as const;
type NS = typeof ns[number];

const supportedLngs = ["en-US", "fr-FR", "de-DE", "ja-JP"] as const;
type Languages = typeof supportedLngs[number];

declare module "i18next" {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

function cleanPath(result: string) {
    return result.replace('./', "/");
}

i18n.use(new Backend())
    .use(new LanguageDetector())
    .use(initReactI18next)
    .init({
        fallbackLng: ["en-US", "en"],
        supportedLngs: ["en-US", "fr-FR", "de-DE", "ja-JP"],
        ns: ["common", "web", "vr"],
        backend: {
            loadPath: ([ln]: Languages[], [ns]: NS[]) => {
                return cleanPath(require(`./locales/${ln}/${ns}.json`));
            }
        },
        returnNull: false,
    });

// eslint-disable-next-line no-restricted-exports
export default i18n;
