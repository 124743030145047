/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { templateCatalog } from "./templateCatalog.js";
import { iblPrimaryLightDirMap } from "../index.js";
import { toSpearCase } from "../util/strings.js";

import type {
    CameraData,
    CameraAngle,
    RenderSettings,
    TemplateEntry,
    TemplateView,
    Unarray,
    TemplateData,
} from "@shared/types";

export const THUMBNAIL_VARIANT = "Front";
export const WEBVIEWER_VARIANT = "Webviewer";
export const CAMERA_ID = "/root/camera";

const projectTemplates = templateCatalog.projectTemplates;

type VariantEntry = Unarray<
    ReturnType<typeof getTemplate>["template"]["variants"]
>;

// From projectTemplates
type CameraVariant = {
    camera: {
        customData: {
            adobe: {
                displayName: string;
                displayOrder: number;
            };
        };
        focalLength: number;
        horizontalAperture: number;
        verticalAperture: number;
        "xformOp:rotateXYZ": number[];
        "xformOp:translate": number[];
    };
    render_product: {
        resolution: number[];
    };
    light_set: {
        DomeLight: {
            "inputs:intensity": number;
            "xformOp:rotateY": number;
        };
    };
};

type LogFn = (obj: object, msg: string) => void;

const cameraDataFromVariant = (
    templateKey: string,
    variantEntry: VariantEntry,
    url: string,
): CameraData => {
    const [cameraKey, variantData] = Object.entries(variantEntry)[0];
    const cameraVariant = variantData as unknown as CameraVariant;
    const aspectRatio =
        cameraVariant.render_product.resolution[0] /
        cameraVariant.render_product.resolution[1];
    const templateName =
            getTemplate(templateKey).template.metadata.customLayerData.adobe
                .templateName
    return {
        id: CAMERA_ID,
        variant: cameraKey,
        aspectRatio,
        resolution: cameraVariant.render_product.resolution,
        hideFromTemplateUI: cameraKey === WEBVIEWER_VARIANT,
        thumbnailUrl: `/assets/images/templates/${templateKey}/CameraThumbnails/${cameraKey}.png`,
        templateUrl: url,
        domeLightRotation: cameraVariant.light_set.DomeLight["xformOp:rotateY"],
        domeLightIntensity:
            cameraVariant.light_set.DomeLight["inputs:intensity"],
        displayOrder: cameraVariant.camera.customData.adobe.displayOrder,
        displayName: cameraVariant.camera.customData.adobe.displayName,
        localeKey: `project.template.${toSpearCase(templateName)}.cameras.${toSpearCase(cameraVariant.camera.customData.adobe.displayName)}.name`
    };
};

/** A helper function that gets the given camera variant, for use with the
 * asset template specifically */
const cameraDataFromAssetTemplateVariant = (variantEntry: VariantEntry) => {
    const [cameraKey, variantData] = Object.entries(variantEntry)[0];
    const resolution = (variantData as unknown as CameraVariant).render_product
        .resolution;
    const aspectRatio = resolution[0] / resolution[1];
    const displayOrder = (variantData as unknown as CameraVariant).camera
        .customData.adobe.displayOrder;
    return {
        id: CAMERA_ID,
        variant: cameraKey,
        aspectRatio,
        hideFromTemplateUI: cameraKey === WEBVIEWER_VARIANT,
        displayOrder: displayOrder,
        resolution,
    };
};

// Required: This object contains all optional RenderSetting properties
export const DEFAULT_RENDER_SETTINGS = {
    backgroundColor: [0.819, 0.819, 0.819, 1],
    groundPlaneEnabled: true,
    domeLightRotation: 0.0,
    domeLightIntensity: 1.0,
    directionalLightVector: [0.5, -1, 0.5],
    shadowIntensity: 0.75,
};

/**
 * @param iblFilename The filename of the IBL file, without any path or extension (optional)
 *
 * @returns The local path to the IBL file, if iblFilename is defined. Otherwise, undefined
 */
export const constructIblPath = (iblFilename?: string) => {
    return iblFilename ? `/assets/ibl/${iblFilename}.env` : undefined;
};

export const DEFAULT_IBL_URL = "/assets/ibl/studio_white_soft_light-1k_180_front_comp.env";

/**
 * Extract the relevant render settings for the web viewer from the given
 * template.
 *
 * @param template A TemplateEntry (such as assetTemplate, or entries in
 * projectTemplate) or undefined. This is the template checked for the render
 * settings. If undefined, we use the default render settings
 *
 * @return The relevant render settings
 */
export const getRenderSettings = (
    template?: TemplateEntry,
    camera?: string,
): RenderSettings => {
    if (!template) {
        return DEFAULT_RENDER_SETTINGS;
    }

    // The template stores RGB, but we display sRGB. This converts from RGB to sRGB
    const RGBcolor = template.RenderSettings["adobe:backgroundColor"];
    const sRGBcolor = [0, 0, 0, RGBcolor[3]];
    for (let i = 0; i < 3; ++i) {
        sRGBcolor[i] = Math.pow(RGBcolor[i], 1.0 / 2.2);
    }

    const templateVariants = Object.values(template.variants);
    const templateVariant: CameraAngle | undefined = templateVariants.find(
        (variant) => Object.keys(variant)[0] === camera,
    );

    const cameraData: TemplateView | undefined = templateVariant
        ? (Object.values(templateVariant)[0] as unknown as TemplateView)
        : undefined;

    return {
        backgroundColor: sRGBcolor,
        groundPlaneEnabled:
            template.RenderSettings["adobe:groundplane:enabled"],
        domeLightRotation: cameraData
            ? cameraData.light_set.DomeLight["xformOp:rotateY"]
            : DEFAULT_RENDER_SETTINGS.domeLightRotation,
        domeLightIntensity: cameraData
            ? cameraData.light_set.DomeLight["inputs:intensity"]
            : DEFAULT_RENDER_SETTINGS.domeLightIntensity,
        directionalLightVector:
            iblPrimaryLightDirMap[template.iblFilename].direction,
        shadowIntensity:
            iblPrimaryLightDirMap[template.iblFilename].shadowIntensity,
    };
};

/** @returns key-value pair of the matched template */
export const getTemplate = (templateKey: string, logError?: LogFn) => {
    const matchedTemplate =
        projectTemplates[templateKey as keyof typeof projectTemplates];

    if (!matchedTemplate) {

        const logFn = logError ?? console.error;
        logFn({ templateKey }, "Failed to find template when creating a shot");
        throw new Error(`Cannot find template ${templateKey}`);
    }
    return { templateKey, template: matchedTemplate };
};

/** @returns a map of camera key and camera data */
const getTemplateCameras = (
    templateKey: string,
    logError?: LogFn,
): Map<string, CameraData> => {
    const { template: matchedTemplate } = getTemplate(templateKey, logError);

    const cameraMap = new Map<string, CameraData>(
        matchedTemplate.variants.map((entry) => {
            // There will only be a single cameraKey in the variant entry
            const cameraKey = Object.keys(entry)[0];
            return [
                cameraKey,
                cameraDataFromVariant(templateKey, entry, matchedTemplate.url),
            ];
        }),
    );
    return cameraMap;
};

/** @returns the camera data for the specified template key and camera */
export function getCameraData(
    templateKey: string,
    camera: string,
    logError?: LogFn,
) {
    const cameraMap = getTemplateCameras(templateKey, logError);

    const cameraData = cameraMap.get(camera);
    if (!cameraData) {
        throw new Error(
            `Could not find camera ${camera} from template ${templateKey}`,
        );
    }

    return cameraData;
}

/** @returns the camera data for the given template and camera */
export const getTemplateCameraData = (
    camera: string,
    template: ReturnType<typeof getTemplate>,
): CameraData => {
    const { templateKey, template: templateData } = template;
    const templateUrl = templateData.url;
    const templateVariants = Object.values(templateData.variants);
    const templateVariant: VariantEntry = templateVariants.find(
        (variant) => Object.keys(variant)[0] === camera,
    );
    const templateCameraData = cameraDataFromVariant(
        templateKey,
        templateVariant,
        templateUrl,
    );

    if (!templateCameraData) {
        throw new Error(`No camera data in template for camera ${camera}.`);
    }
    return templateCameraData;
};

/** @returns the camera data for the given camera from the asset template */
export const getAssetTemplateCameraData = (camera: string): CameraData => {
    // Each of the the variant has a single camera name as key, e.g. { Front: {...}}
    // The list of variants is thus [{ Front: {...}}, { Left: {...}}, ...]
    // Object.keys(variant)[0] gives us the camera name.
    const templateCameraData = Object.entries(
        templateCatalog.assetTemplate.variants,
    )
        .filter(
            ([, variant]) =>
                // asset orthographic camera types are lower cases.
                Object.keys(variant)[0].toLowerCase() === camera.toLowerCase(),
        )
        .map((entry) => {
            // entry is an array of [key, value] key is the index of of the matched camera in the variant array,
            // value is the object { cameraKey: cameraVariant }
            return cameraDataFromAssetTemplateVariant(entry[1]);
        })[0] as unknown as CameraData;

    if (!templateCameraData) {
        throw new Error(`No camera data in template for camera ${camera}.`);
    }
    return templateCameraData;
};

export const camerasForAnalytics = (cameras: string[]) => {
    return cameras
        .map((camera) => camera.replace(/,/g, "\\,"))
        .sort()
        .join(",")
        .toLocaleLowerCase();
};

export const templateForAnalytics = (template: TemplateData | string) => {
    let templateName;
    if (typeof template === "string") {
        templateName =
            getTemplate(template).template.metadata.customLayerData.adobe
                .templateName;
    } else {
        templateName = template.title;
    }
    return templateName.replace(/,/g, "\\,");
};
