/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex } from "@adobe/react-spectrum";
// import { ToastQueue } from "@react-spectrum/toast";
// import { useTranslation } from "react-i18next";

import { AssetGridView } from "./AssetGridView";
import { AssetListView } from "./AssetListView";
import { ReviewListItem } from "@src/lib/acp/AcpClientModels";

import type { TabType } from "./AssetTabsView";

interface AssetsViewProps {
    assets: ReviewListItem[] | undefined;
    onAssetClick: (urn: string) => void;
    gridLayoutEnabled: boolean;
    tabType: TabType;
    isForVr: boolean; // asset views will resize with window size for VR, maintain standard size for web
    locale: string;
}

export function AssetsView({
    assets,
    onAssetClick,
    gridLayoutEnabled,
    tabType,
    isForVr,
    locale,
}: AssetsViewProps) {
    // const { t } = useTranslation("common");

    function handleOnAssetClick(data: ReviewListItem) {
        onAssetClick(data.assetId);
        // if (data.isBlocked) {
        //     ToastQueue.negative(t("toast.enterReview.uploadProcessing"), {
        //         timeout: 5000,
        //     });
        // } else {
        // }
    }

    return (
        <Flex
            alignItems={gridLayoutEnabled ? "start" : "stretch"}
            direction={gridLayoutEnabled ? "row" : "column"}
            wrap={gridLayoutEnabled}
            width={gridLayoutEnabled ? "100%" : "98%"}
            height="100%"
            gap={gridLayoutEnabled ? (isForVr ? "5em" : "1.5em") : "0"}>
            {assets &&
                assets.map((data: ReviewListItem) =>
                    gridLayoutEnabled ? (
                        <div key={data.assetId} onClick={() => handleOnAssetClick(data)}  aria-label={data.displayName}>
                            <AssetGridView
                                data={data}
                                isForVr={isForVr}
                                tabType={tabType}
                                locale={locale}
                            />
                        </div>
                    ) : (
                        <div key={data.assetId} onClick={() => handleOnAssetClick(data)} aria-label={data.displayName}>
                            <AssetListView
                                data={data}
                                isForVr={isForVr}
                                tabType={tabType}
                                locale={locale}
                            />
                        </div>
                    ),
                )}
        </Flex>
    );
}
