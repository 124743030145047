/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Content,
    Dialog,
    DialogContainer,
    Flex,
    Footer,
    Heading,
    Text,
    View,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useOpenInVR } from "../hooks/useOpenInVR";
import { useAcpContext } from "@src/contexts/AcpContext";

interface OpenInVRProps {
    accessToken?: string;
    assetUrn?: string;
}

export function OpenInVR({ accessToken, assetUrn }: OpenInVRProps) {
    const { t } = useTranslation("web");
    const { acpClient } = useAcpContext();
    const { generateVrLink, linkAccount } = useOpenInVR(accessToken, assetUrn);
    const [searchParams] = useSearchParams();

    const shouldShowDialog = searchParams.get("accountLinked") || undefined;

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    useEffect(() => {
        if (shouldShowDialog) setIsDialogOpen(true);
    }, [shouldShowDialog]);

    const [metaUserId, setMetaUserId] = useState<string>();
    async function openVrLink() {
        const link: string | undefined = await generateVrLink(metaUserId);
        if (!link) {
            ToastQueue.negative(t("toast.openInVR.createLinkFailed"), {
                timeout: 5000,
            });
            return;
        }
        window.open(link, "_blank");
    }

    function isOpenInVrReady() {
        return accessToken && metaUserId !== undefined;
    }

    const [metaUserIdAlias, setMetaUserIdAlias] = useState<string>();
    async function checkLinkedMetaUserId() {
        const cloudDir = await acpClient.getDirectory("cloud-content");
        const cloudDirAssetId = cloudDir["repo:assetId"];

        if (!cloudDirAssetId) return;
        const cloudAppMeta =
            await acpClient.getApplicationMeta(cloudDirAssetId);

        if (!cloudAppMeta) {
            console.error("error getting Cloud Content application metadata");
            return;
        }
        const id = cloudAppMeta?.["highfive:metaUserId"] || "";
        setMetaUserId(id);
        const alias = cloudAppMeta?.["highfive:metaUserIdAlias"] || "";
        setMetaUserIdAlias(alias);
    }

    useEffect(() => {
        checkLinkedMetaUserId();
    }, []);

    return (
        <>
            <Button
                variant="secondary"
                isDisabled={!isOpenInVrReady()}
                onPress={() => setIsDialogOpen(true)}>
                <Text>{t("openInVr.button")}</Text>
            </Button>
            <DialogContainer
                onDismiss={() => setIsDialogOpen(false)}
                isDismissable>
                {isDialogOpen && (
                    <Dialog width="size-5000">
                        <Heading>{t("openInVr.dialog.title")}</Heading>
                        <Content>
                            {!metaUserId ? (
                                <View marginTop="size-300">
                                    <Text>
                                        {t(
                                            "openInVr.dialog.linkAccount.description",
                                        )}
                                    </Text>
                                </View>
                            ) : (
                                <Flex
                                    marginTop="size-300"
                                    direction="column"
                                    gap="size-200"
                                    justifyContent="space-between">
                                    <Text>
                                        {t(
                                            "openInVr.dialog.openLink.description.1",
                                        )}
                                    </Text>
                                    <Text
                                        UNSAFE_style={{
                                            fontWeight: 900,
                                            color: "rgb(249, 137, 23)",
                                        }}>
                                        {t(
                                            "openInVr.dialog.openLink.description.2",
                                        )}
                                    </Text>
                                    <Text>
                                        {t(
                                            "openInVr.dialog.openLink.description.3",
                                        )}
                                    </Text>
                                    {metaUserIdAlias && (
                                        <Text>
                                            {t(
                                                "openInVr.dialog.openLink.linkedAccount",
                                            )}
                                            {metaUserIdAlias}
                                        </Text>
                                    )}
                                </Flex>
                            )}
                        </Content>
                        <Footer>
                            <Flex
                                direction="row"
                                width="100%"
                                justifyContent="end">
                                {!metaUserId ? (
                                    <Button
                                        variant="accent"
                                        onPress={linkAccount}>
                                        {t("actions.linkAccount")}
                                    </Button>
                                ) : (
                                    <ButtonGroup>
                                        <Button
                                            variant="secondary"
                                            onPress={linkAccount}>
                                            {t("actions.linkNewAccount")}
                                        </Button>
                                        <Button
                                            variant="accent"
                                            onPress={openVrLink}>
                                            {t("actions.openInVR")}
                                        </Button>
                                    </ButtonGroup>
                                )}
                            </Flex>
                        </Footer>
                    </Dialog>
                )}
            </DialogContainer>
        </>
    );
}
