/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

export function getSeashellLink({
    assetId,
    url,
}: {
    assetId?: string;
    url?: string;
}) {
    if (!assetId && !url) {
        throw new Error("You must provide either an assetId or url");
    }
    if (url) {
        return `adbse://app?action=openCloudDoc&cloudURL=${encodeURIComponent(url)}`;
    }
    return `adbse://app?action=openCloudDoc&assetId=${assetId}`;
}

const LOCAL_STORAGE_KEY = "hasSeashellRegistered";

export function useSeashellLinking(assetId: string) {
    const hasPreviousVerification = !!localStorage.getItem(LOCAL_STORAGE_KEY);
    const [hasSeashellRegistered, setHasSeashellRegistered] = useState(
        hasPreviousVerification,
    );
    const [testComplete, setTestComplete] = useState(hasPreviousVerification);

    const hasSeashell = hasSeashellRegistered && testComplete;

    function openSeashellLink() {
        window.location.href = getSeashellLink({ assetId });
    }

    function downloadSeashell() {
        window.location.href =
            "https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=3DI&title=Internal+Beta";
    }

    useEffect(() => {
        function onBlur() {
            setHasSeashellRegistered(true);
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(true));
        }

        function cleanupListener() {
            window.removeEventListener("blur", onBlur);
        }

        if (assetId && !hasSeashellRegistered) {
            window.addEventListener("blur", onBlur);
            openSeashellLink();

            setTimeout(() => {
                setTestComplete(true);
                cleanupListener();
            }, 500);
        }
        return () => {
            cleanupListener();
        };
    }, [assetId, hasSeashellRegistered]);

    return { hasSeashell, openSeashellLink, downloadSeashell };
}
