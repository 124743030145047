/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    DialogTrigger,
    Dialog,
    Content,
    Flex,
    ActionButton,
    View,
    RadioGroup,
    Radio,
} from "@adobe/react-spectrum";
import ChevronDown from "@spectrum-icons/workflow/ChevronDown";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import MicMute from "../images/mic-mute-light.svg";
import MicUnmute from "../images/MicUnmuteUpdate.svg";

interface MangeMicState {
    currentAudioDeviceId: string;
    audioDevices: MediaDeviceInfo[];
    muted: boolean;
    setMuted: (data: boolean) => void;
    setDevice: (deviceId: string) => void;
}

export function MicrophoneInput({
    currentAudioDeviceId,
    audioDevices,
    muted,
    setMuted,
    setDevice,
}: MangeMicState) {
    const { t } = useTranslation("common");

    return (
        <View>
            <Flex
                direction="row"
                alignItems="self-start"
                UNSAFE_style={{
                    top: "5px",
                }}>
                <ActionButton
                    isQuiet
                    maxHeight={20}
                    maxWidth={10}
                    onPress={() => {
                        //set mic is on
                        setMuted(muted);
                    }}>
                    {muted ? (
                        <img
                            src={MicMute}
                            alt={t("actions.mute")}
                            width={15}
                            height={15}
                        />
                    ) : (
                        <img
                            src={MicUnmute}
                            alt={t("actions.unmute")}
                            width={15}
                            height={15}
                        />
                    )}
                </ActionButton>

                <DialogTrigger
                    type="popover"
                    placement="bottom"
                    hideArrow={true}>
                    <ActionButton
                        isQuiet
                        maxHeight={20}
                        maxWidth={20}
                        UNSAFE_style={{
                            right: "10px",
                        }}>
                        <ChevronDown />
                    </ActionButton>
                    <Dialog>
                        <Content>
                            {
                                <RadioGroup
                                    label="Mic Input"
                                    name="mic"
                                    value={currentAudioDeviceId}
                                    onChange={setDevice}>
                                    {audioDevices.map((device) => (
                                        <Radio
                                            key={device.deviceId}
                                            value={device.deviceId}>
                                            {device.label}
                                        </Radio>
                                    ))}
                                </RadioGroup>
                            }
                        </Content>
                    </Dialog>
                </DialogTrigger>
            </Flex>
        </View>
    );
}
