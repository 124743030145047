/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { PropsWithChildren, useEffect } from "react";

import { usePostMessageContext } from "@src/contexts/PostMessageContext";

export function VrPageWrapper({children}: PropsWithChildren) {
    const {refreshMenuInputFields} = usePostMessageContext();

    useEffect(() => {
        const observer = new MutationObserver((elements) => {
            for(const e of elements) {
                for(const node of [...e.addedNodes, ...e.removedNodes]) {
                    if (['textarea', 'input'].includes(node.nodeName.toLocaleLowerCase())) {
                        refreshMenuInputFields();
                    }
                }
            }
        });

        refreshMenuInputFields();

        observer.observe(document.body, {
            childList: true,
            subtree: true,
            attributes: false,
        })

        return () => {
            observer.disconnect();
        }
    }, []);


    return children;
}
