/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Text,
    Flex,
    Avatar,
    View,
    Badge,
    Item,
    ActionButton,
    Tooltip,
    TooltipTrigger,
    MenuTrigger,
    Menu,
} from "@adobe/react-spectrum";
import ChevronDown from "@spectrum-icons/workflow/ChevronDown";
import Crosshairs from "@spectrum-icons/workflow/Crosshairs";
import More from "@spectrum-icons/workflow/More";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import MicMute from "../images/mic-mute-light.svg";
import { useNetworkContext } from "@src/contexts/NetworkContext";
import { AFK_COLOR } from "@src/networking/AvatarsManager";
import { DetectSpeech } from "@src/util/DetectSpeech";

interface PresenceBubblesProps {
    muted: boolean;
    device: string;
    users: Set<string>;
    mediaStream: MediaStream | undefined;
}

const maxUsersDisplayed = 4;

export function PresenceBubbles({
    muted,
    device,
    users,
    mediaStream,
}: PresenceBubblesProps) {
    const { t } = useTranslation("common");
    const { networkManager, voiceManager } = useNetworkContext();

    const [colorsMap, setColorsMap] = useState<Record<number, string>>({});
    const [talkingFlagsMap, setTalkingFlagsMap] = useState<
        Record<number, boolean>
    >({}); // mapping of actors to their talking status
    const [idleFlagsMap, setIdleFlagsMap] = useState<Record<number, boolean>>(
        {},
    ); // mapping of actors to their talking status
    const [mutedFlagsMap, setMutedFlagsMap] = useState<Record<number, boolean>>(
        {},
    ); // mapping of actors to their muted status

    // const [showPopover, setShowPopover] = useState(false); // show popover when avatar is clicked

    useEffect(() => {
        const handleUserColorAssigned = (event: any) => {
            const { actorNr, color } = event.detail;
            if (color == null) {
                setColorsMap((prev) => {
                    delete prev[actorNr];
                    return prev;
                });
            } else {
                setColorsMap((prev) => ({
                    ...prev,
                    [actorNr]: color,
                }));
            }
        };

        window.addEventListener("onUserColorAssigned", handleUserColorAssigned);

        return () => {
            window.removeEventListener(
                "onUserColorAssigned",
                handleUserColorAssigned,
            );
        };
    }, []);

    useEffect(() => {
        const handleIsTalking = (event: any) => {
            const { actorNr, isTalking } = event.detail;
            setTalkingFlagsMap((prev) => ({
                ...prev,
                [actorNr]: isTalking,
            }));
        };

        window.addEventListener("isTalking", handleIsTalking);

        return () => {
            window.removeEventListener("isTalking", handleIsTalking);
        };
    }, []);

    useEffect(() => {
        const handleIsIdle = (event: any) => {
            const { actorNr, isIdle } = event.detail;
            setIdleFlagsMap((prev) => ({
                ...prev,
                [actorNr]: isIdle,
            }));
        };

        window.addEventListener("isIdle", handleIsIdle);

        return () => {
            window.removeEventListener("isIdle", handleIsIdle);
        };
    }, []);

    useEffect(() => {
        const handleIsMuted = (event: any) => {
            const { actorNr, isMuted } = event.detail;
            setMutedFlagsMap((prev) => ({
                ...prev,
                [actorNr]: isMuted,
            }));
        };

        window.addEventListener("isMuted", handleIsMuted);

        return () => {
            window.removeEventListener("isMuted", handleIsMuted);
        };
    }, []);

    // run local speech detection when microphone is on
    useEffect(() => {
        if (!networkManager || !mediaStream) return;

        let speechDetector: DetectSpeech;
        if (mediaStream && !muted) {
            speechDetector = new DetectSpeech(mediaStream);
            speechDetector.on("SPEAKING", (isCurrentlySpeaking) => {
                const actor = networkManager.myActor().actorNr ?? 0;
                setTalkingFlagsMap((prev) => ({
                    ...prev,
                    [actor]: isCurrentlySpeaking,
                }));
                networkManager?.setIsTalkingFlag(isCurrentlySpeaking);
            });
        } else {
            const actor = networkManager.myActor().actorNr ?? 0;
            setTalkingFlagsMap((prev) => ({
                ...prev,
                [actor]: false,
            }));
            networkManager.setIsTalkingFlag(false);
        }

        return () => {
            if (speechDetector) {
                speechDetector.dispose();
            }
        };
    }, [muted, mediaStream, networkManager]);

    useEffect(() => {
        // <-- new way to toggle mic
        voiceManager?.toggleMic(!muted, device);

        networkManager?.setIsMutedFlag(muted);
    }, [muted]);

    useEffect(() => {
        const actors = networkManager?.myRoomActorsArray();
        if (users?.size === 0) return;
        if (!actors) return;
    }, [
        // showPopover,
        users,
    ]);

    function handleDropdownSelection(
        selection: string | number,
        actor: Photon.LoadBalancing.Actor,
    ) {
        switch (selection) {
            case "teleport":
                networkManager?.onTeleportStart(actor);
        }
    }

    function renderLimitedAvatarView(actors: Photon.LoadBalancing.Actor[]) {
        let actorCount = 0; // using actor count becuase actorNr is always increment every time a new actor joins
        const overlapOffset = "-8px";
        return (
            <View>
                <Flex direction="row">
                    {actors.map((actor) => {
                        actorCount++;
                        const { avatarUrl, platform } =
                            actor.getCustomProperties();
                        // const zIndex = actors.length - actorCount;
                        return (
                            <View key={actorCount}>
                                <TooltipTrigger placement="bottom" delay={0}>
                                    <ActionButton
                                        isQuiet
                                        UNSAFE_style={{
                                            zIndex: 1,
                                            marginRight: overlapOffset,
                                        }}>
                                        <Avatar
                                            key={actor.actorNr}
                                            size="avatar-size-400"
                                            alt={t(
                                                "accessibility.account.profilePicture",
                                            )}
                                            src={avatarUrl}
                                            UNSAFE_style={{
                                                border: "2px solid #303030",
                                                boxShadow: `0 0 0 2px ${talkingFlagsMap[actor.actorNr] ? `white` : idleFlagsMap[actor.actorNr] ? AFK_COLOR : colorsMap[actor.actorNr]}`,
                                            }}
                                        />
                                    </ActionButton>
                                    <Tooltip>
                                        <Text>{actor.name}</Text>
                                        <Badge
                                            variant="indigo"
                                            UNSAFE_style={{
                                                left: 3,
                                            }}>
                                            {platform === "VR" ? "VR" : "Web"}
                                        </Badge>
                                    </Tooltip>
                                </TooltipTrigger>
                            </View>
                        );
                    })}
                </Flex>
            </View>
        );
    }

    function renderAvatarDropDownItem(actor: Photon.LoadBalancing.Actor) {
        const { avatarUrl, platform } = actor.getCustomProperties();
        const isCurrentUser =
            actor.actorNr === networkManager?.myActor().actorNr;
        return (
            <div
                style={{
                    width: "100%",
                    justifyContent: "start",
                }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: muted ? "space-between" : "start",
                        width: "100%",
                    }}
                    key={actor.actorNr}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            flexGrow: "1",
                        }}>
                        <Avatar
                            src={avatarUrl}
                            alt={t("accessibility.account.profilePicture")}
                        />
                        <Text
                            flexShrink={0}
                            UNSAFE_style={{
                                maxWidth: "fit-content",
                                paddingInlineEnd: "0px",
                            }}>
                            {actor.name}
                        </Text>
                        {isCurrentUser ? (
                            <Text
                                UNSAFE_style={{
                                    maxWidth: "fit-content",
                                    color: "var(--spectrum-global-color-gray-500)",
                                    order: 2,
                                }}>
                                You
                            </Text>
                        ) : (
                            <Badge
                                variant="indigo"
                                height={8}
                                UNSAFE_style={{
                                    order: 2,
                                }}>
                                <Text alignSelf={"center"}>
                                    {platform === "VR" ? "VR" : "Web"}
                                </Text>
                            </Badge>
                        )}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                        }}>
                        {muted && (
                            <img
                                src={MicMute}
                                alt={t("actions.mute")}
                                width={15}
                                height={15}
                            />
                        )}
                        {!isCurrentUser &&
                            !networkManager?.isUserInTeleport(
                                actor.actorNr,
                            ) && (
                                // TODO: render if not current user and not "away"
                                <MenuTrigger align="start" direction="right">
                                    <ActionButton
                                        isQuiet
                                        onPress={() => {
                                            console.log(
                                                "Clicked on " + actor.name,
                                            );
                                            // networkManager?.onTeleportStart(actor);
                                        }}>
                                        <More />
                                    </ActionButton>
                                    <Menu
                                        onAction={(key) =>
                                            handleDropdownSelection(key, actor)
                                        }>
                                        <Item key="teleport">
                                            <Crosshairs />
                                            <Text>{"Teleport To"}</Text>
                                        </Item>
                                    </Menu>
                                </MenuTrigger>
                            )}
                    </div>
                </div>
            </div>
        );
    }

    function renderAvatarDropDownView() {
        const actors = networkManager?.myRoomActorsArray() ?? [];
        // const extraUsers = actors.length - maxUsersDisplayed;
        return (
            <Flex direction="row">
                <View>
                    {renderLimitedAvatarView(
                        actors.slice(0, maxUsersDisplayed),
                    )}
                </View>
                <View>
                    <MenuTrigger direction="bottom" closeOnSelect={false}>
                        <ActionButton
                            isQuiet
                            UNSAFE_style={{
                                marginLeft: "12px",
                            }}>
                            <ChevronDown />
                        </ActionButton>
                        <Menu minWidth="300px">
                            {actors.map((actor) => {
                                return (
                                    <Item
                                        key={`dropdown-item-${actor.actorNr}`}>
                                        <Text>
                                            {renderAvatarDropDownItem(actor)}
                                        </Text>
                                    </Item>
                                );
                            })}
                        </Menu>
                    </MenuTrigger>
                </View>
            </Flex>
        );
    }

    return (
        <View>
            <Flex
                direction="row"
                alignItems="center"
                position="relative"
                UNSAFE_style={{
                    marginRight: "150px",
                    marginTop: "2px",
                }}>
                {networkManager?.myRoomActorsArray() &&
                    (networkManager.myRoomActorsArray().length <= 1
                        ? renderLimitedAvatarView(
                              networkManager?.myRoomActorsArray(),
                          )
                        : renderAvatarDropDownView())}
            </Flex>
        </View>
    );
}
