/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    PostMessageBus,
    PostedMessage,
} from "@src/comments-api/PostMessageBus";

export enum SharedOutgoingEvents {
    inputSelect = "inputFieldSelectionChange",
}

export enum SharedIncomingEvents {
    changeInput = "changeInputFieldText",
}

export type InputFieldSelectionChangeMessage = {
    type: "inputFieldSelectionChange";
    data: {
        fieldName: string;
        textInField: string;
    };
};

export type ChangeInputFieldTextMessage = {
    type: "changeInputFieldText";
    data: {
        newText: string;
        oldText: string;
        fieldName: string;
    };
};

export class PostMessageSharedApi<
    T extends PostMessageBus,
> extends EventTarget {
    constructor(public messageBus: T) {
        super();
        messageBus.addEventListener("message", this.messageHandler);
    }

    onInputFocus = (focusEvent: FocusEvent) => {
        console.log("onInputFocus event", focusEvent)
        const el = focusEvent.target;
        if (el) {
            const input = el as unknown as HTMLInputElement;
            console.log("Input select", {
                type: SharedOutgoingEvents.inputSelect,
                data: {
                    fieldName: input.name,
                    textInField: input.value,
                },
            });
            this.messageBus.postMessage({
                type: SharedOutgoingEvents.inputSelect,
                data: {
                    fieldName: input.name,
                    textInField: input.value,
                },
            });
        }
    }

    messageHandler = (payload: PostedMessage) => {
        if (!(payload && payload.data && typeof payload.data == "string"))
            return;
        const message = JSON.parse(payload.data);

        if (message.type === SharedIncomingEvents.changeInput) {
            this.dispatchEvent(
                new CustomEvent(SharedIncomingEvents.changeInput, {
                    detail: (message as ChangeInputFieldTextMessage).data,
                }),
            );
        }
    };
}
