/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { UserProvider, useUserContext } from "@shared/client";
import { JanusRoutes } from "@shared/common";

import { ADOBE_IMS_CONFIG, IMS_ENV } from "../config";
import { saveSessionGuid } from "@src/hooks/useAnalytics";
import { useRedirects } from "@src/hooks/useRedirects";

import type { PropsWithChildren } from "react";

export function useHi5UserContext() {
    return useUserContext();
}

export function HI5UserProvider({ children }: PropsWithChildren) {
    const { landingRedirect } = useRedirects();

    return (
        <UserProvider
            imsConfig={ADOBE_IMS_CONFIG}
            isProd={IMS_ENV === "prod"}
            onLogin={() => saveSessionGuid()}
            onLogout={() => {
                saveSessionGuid();
                landingRedirect();
            }}
            loginRequiredPaths={[
                JanusRoutes.home.path,
                JanusRoutes.twoUp.path,
                JanusRoutes.oneUp.path,
                JanusRoutes.accessCheck.path,
                JanusRoutes.accessRequired.path,
            ]}>
            {children}
        </UserProvider>
    );
}
