/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { PostMessageSharedApi } from "./PostMessageSharedApi";
import { PostMessageBus } from "../comments-api/PostMessageBus";
import { VERSION } from "../config";
import { visitorGuid } from "../hooks/useAnalytics";
import { ReviewerEnvMeta } from "@src/contexts/AcpContext";
import { Environment, Pedestal, getEnvUrl, getPedestalUrl } from "@src/scene/EnvironmentBuilder";

type DialogDimensionsType = Record<string, Record<string, number>>;

const vuplexResMultiplier = 2;

const DialogDimensions: DialogDimensionsType = {
    "log-in-check": { width: 400, height: 200 },
    "start-screen": { width: 1000, height: 700 },
    "signed-out": { width: 400, height: 275 },
    "enter-review": { width: 400, height: 365 },
    "log-in-required": { width: 300, height: 200 },
    "log-in-required-susi": { width: 400, height: 660 },
    "access-check": { width: 400, height: 200 },
    "access-required": { width: 300, height: 250 },
    "access-requested": { width: 300, height: 200 },
    "asset-not-found": { width: 300, height: 200 },
    "app-settings-account": { width: 300, height: 250 },
    "app-settings-preferences": { width: 300, height: 400 },
    "app-settings-preferences-ext": { width: 300, height: 400 },
    "app-settings-preferences-customGP": { width: 300, height: 460 },
    "app-settings-social": { width: 300, height: 330 },
};

export enum MenuOutGoingMessageType {
    webReady = "webReady",
    showDialog = "showDialog",
    enterReview = "enterReview",
    clearCookies = "clearCookies",
    resizeWindow = "resizeWindow",
    error = "error",
    debug = "debug",
    appSettingChanged = "appSettingChanged",
    showAppSettings = "showAppSettings",
    showLegalNotice = "showLegalNotice",
    changeToAccountView = "changeToAccountView",
    changeToPreferencesView = "changeToPreferencesView",
    openQuestAccesibility = "openQuestAccesibility",
    openAboutHighFive = "openAboutHighFive",
    reportAbuseCancelled = "reportAbuseCancelled",
    reportAbuseSubmitted = "reportAbuseSubmitted",
    interceptLink = "interceptLink",
    signOutVR = "signOutVR",
    adobeUser = "adobeUser",
    authtoken = "authtoken",
    quitApp = "quitApp",
}

export enum MenuIncomingMessageType {
    error = "error",
    clearTextFocus = "clearTextFocus",
    initAppSettings = "initAppSettings",
    vrReady = "vrReady",
    userJoined = "userJoined",
    userLeft = "userLeft",
    usersInReview = "usersInReview",
}

/**
 * Outgoing Message Types
 */
export type WebReadyMessage = {
    type: MenuOutGoingMessageType.webReady;
    data: {
        version: string;
        userId: string;
        visitorId: string;
    };
};

export type ShowDialogMessage = {
    type: MenuOutGoingMessageType.showDialog;
    data: {
        dialogName: string;
        width: number;
        height: number;
        queryString: string;
    };
};

export type EnterReviewMessage = {
    type: MenuOutGoingMessageType.enterReview;
    data: {
        urn: string;
        assetDownloadUrl: string;
        envMeta?: ReviewerEnvMeta;
    };
};

export type ClearCookiesMessage = {
    type: MenuOutGoingMessageType.clearCookies;
};

export type ResizeWindowMessage = {
    type: MenuOutGoingMessageType.resizeWindow;
    data: {
        width: number;
        height: number;
    };
};

export type DebugMessage = {
    type: MenuOutGoingMessageType.debug;
    message: string;
};

export type AppSettingChangedMessage = {
    type: MenuOutGoingMessageType.appSettingChanged;
    data: {
        key: string;
        value: string;
    };
};

export type ShowAppSettingsMessage = {
    type: MenuOutGoingMessageType.showAppSettings;
};

export type ShowLegalNoticeMessage = {
    type: MenuOutGoingMessageType.showLegalNotice;
};

export type ChangeToAccountViewMessage = {
    type: MenuOutGoingMessageType.changeToAccountView;
};

export type ChangeToPreferencesViewMessage = {
    type: MenuOutGoingMessageType.changeToPreferencesView;
};

export type OpenQuestAccesibilityMessage = {
    type: MenuOutGoingMessageType.openQuestAccesibility;
};

export type OpenAboutHighFiveMessage = {
    type: MenuOutGoingMessageType.openAboutHighFive;
};

export type ReportAbuseCancelledMessage = {
    type: MenuOutGoingMessageType.reportAbuseCancelled;
};

export type ReportAbuseSubmittedMessage = {
    type: MenuOutGoingMessageType.reportAbuseSubmitted;
    data: {
        responseStatus: number;
    };
};

export type InterceptLinkMessage = {
    type: MenuOutGoingMessageType.interceptLink;
    data: {
        link: string;
    };
};

export type SignOutVRMessage = {
    type: MenuOutGoingMessageType.signOutVR;
};

export type AdobeUserMessage = {
    type: MenuOutGoingMessageType.adobeUser;
    data: {
        name: string;
        url: string;
    };
};

export type QuitAppMessage = {
    type: MenuOutGoingMessageType.quitApp;
};

export type UserHasAuthMessage = {
    type: MenuOutGoingMessageType.authtoken;
    data: {
        token: string;
    };
};

/**
 * Shared message types
 */
export type ErrorMessage = {
    type: MenuOutGoingMessageType.error | MenuIncomingMessageType.error;
    message: string;
};

/**
 * Incoming Message types
 */
export type ClearTextFocusMessage = {
    type: MenuIncomingMessageType.clearTextFocus;
};

export type InitAppSettingsMessage = {
    type: MenuIncomingMessageType.initAppSettings;
    data: {
        lang: string;
        uiSize: string;
        controller: string;
        hapticsValue: string;
    };
};

export type VRReadyMessage = {
    type: MenuIncomingMessageType.vrReady;
    data: {
        deviceId: string;
        sessionGuid: string;
    };
};

export type UserJoinedMessage = {
    type: MenuIncomingMessageType.userJoined;
    data: {
        id: string;
        name: string;
        aviUrl: string;
        isMuted: boolean;
    };
};

export type UserLeftMessage = {
    type: MenuIncomingMessageType.userLeft;
    data: {
        id: string;
    };
};

export type UsersInReviewMessage = {
    type: MenuIncomingMessageType.usersInReview;
    data: {
        users: string;
    };
};


export type MenuOutGoingMessage =
    | EnterReviewMessage
    | ErrorMessage
    | WebReadyMessage
    | ShowDialogMessage
    | ClearCookiesMessage
    | ResizeWindowMessage
    | DebugMessage
    | AppSettingChangedMessage
    | ShowAppSettingsMessage
    | ShowLegalNoticeMessage
    | ChangeToAccountViewMessage
    | ChangeToPreferencesViewMessage
    | OpenQuestAccesibilityMessage
    | OpenAboutHighFiveMessage
    | ReportAbuseCancelledMessage
    | ReportAbuseSubmittedMessage
    | InterceptLinkMessage
    | SignOutVRMessage
    | AdobeUserMessage
    | UserHasAuthMessage
    | QuitAppMessage

export type MenuIncomingMessage =
    | ErrorMessage
    | ClearTextFocusMessage
    | InitAppSettingsMessage
    | VRReadyMessage
    | UserJoinedMessage
    | UserLeftMessage
    | UsersInReviewMessage

export interface VuplexIncomingMessage {
    data: string;
}

export type MenuMessageBus = PostMessageBus<
    VuplexIncomingMessage,
    MenuOutGoingMessage
>;

export class PostMessageMenuApi extends PostMessageSharedApi<MenuMessageBus> {
    constructor(messageBus: MenuMessageBus = window) {
        super(messageBus);
        messageBus.addEventListener("message", this.messageHandler);
    }


    // outgoing message functions
    enterReview = (
        urn: string,
        assetDownloadUrl: string,
        envMeta?: ReviewerEnvMeta,
    ) => {
        console.log("enterReview", urn, assetDownloadUrl);

        if (envMeta?.environment) {
            envMeta.environment = getEnvUrl(envMeta?.environment as Environment);
        }
        if (envMeta?.pedestal) {
            envMeta.pedestal = getPedestalUrl(envMeta.pedestal as Pedestal);
        }

        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.enterReview,
            data: {
                urn,
                assetDownloadUrl,
                envMeta,
            },
        });
    };

    webReady = (userId: string) => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.webReady,
            data: {
                version: VERSION,
                userId,
                visitorId: visitorGuid,
            },
        });
    };

    showDialog = (dialogName: string, queryString = "") => {
        const width = DialogDimensions[dialogName].width * vuplexResMultiplier;
        const height =
            DialogDimensions[dialogName].height * vuplexResMultiplier;
        queryString = queryString
            ? `?${queryString}`
            : ``;
        console.log("showDialog", dialogName, width, height, queryString);
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.showDialog,
            data: {
                dialogName,
                width,
                height,
                queryString,
            },
        });
    };

    clearCookies = () => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.clearCookies,
        });
    };

    resizeWindow = (key: string) => {
        const width = DialogDimensions[key].width * vuplexResMultiplier;
        const height = DialogDimensions[key].height * vuplexResMultiplier;
        console.log("resize", width, height);
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.resizeWindow,
            data: {
                width,
                height,
            },
        });
    };

    debug = (message: string) => {
        console.log("debug", message);
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.debug,
            message,
        });
    };

    webError = (message: string) => {
        console.log("webError", message);
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.error,
            message,
        });
    };

    appSettingChanged = (key: string, value: any) => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.appSettingChanged,
            data: {
                key,
                value,
            },
        });
    };

    showAppSettings = () => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.showAppSettings,
        });
    };

    showLegalNotice = () => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.showLegalNotice,
        });
    };

    changeToAccountView = () => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.changeToAccountView,
        });
    };

    changeToPreferencesView = () => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.changeToPreferencesView,
        });
    };

    openQuestAccesibility = () => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.openQuestAccesibility,
        });
    };

    openAboutHighFive = () => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.openAboutHighFive,
        });
    };

    reportAbuseCancelled = () => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.reportAbuseCancelled,
        });
    };

    reportAbuseSubmitted = (responseStatus: number) => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.reportAbuseSubmitted,
            data: {
                responseStatus,
            },
        });
    };

    interceptLink = (link: string) => {
        console.log("interceptLink", link);
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.interceptLink,
            data: {
                link,
            },
        });
    };

    signOutVR = () => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.signOutVR,
        });
    };

    adobeUser = (name: string, url: string) => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.adobeUser,
            data: {
                name,
                url,
            },
        });
    };

    userHasAuth = (token: string) => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.authtoken,
            data: {
                token,
            },
        });
    };

    quitApp = () => {
        this.messageBus.postMessage({
            type: MenuOutGoingMessageType.quitApp,
        });
    };

    // incoming message functions
    private error(message: string) {
        console.error("error", message);
    }

    messageHandler = (payload: VuplexIncomingMessage) => {
        if (!(payload && payload.data && typeof payload.data == "string"))
            return;
        try {
            const message: MenuIncomingMessage = JSON.parse(payload.data);
            switch (message.type) {
                case MenuIncomingMessageType.error:
                    this.error(message.message);
                    break;
                case MenuIncomingMessageType.clearTextFocus:
                    this.dispatchEvent(new CustomEvent("clear-text-focus"));
                    break;
                case MenuIncomingMessageType.initAppSettings:
                    this.dispatchEvent(
                        new CustomEvent("init-app-settings", {
                            detail: message.data,
                        }),
                    );

                    console.log(
                        "AppSettingsRecieved",
                        JSON.stringify(message.data),
                    );

                    break;
                case MenuIncomingMessageType.vrReady:
                    this.dispatchEvent(
                        new CustomEvent("set-vr-data", {
                            detail: message.data,
                        }),
                    );
                    break;
                case MenuIncomingMessageType.userJoined:
                    this.dispatchEvent(
                        new CustomEvent("user-joined", {
                            detail: message.data,
                        }),
                    );

                    // console.log(
                    //     "userJoined-recieved",
                    //     JSON.stringify(message.data),
                    // ); //<-- for debugging
                    break;
                case MenuIncomingMessageType.userLeft:
                    this.dispatchEvent(
                        new CustomEvent("user-left", {
                            detail: message.data,
                        }),
                    );
                    break;

                case MenuIncomingMessageType.usersInReview:
                    this.dispatchEvent(
                        new CustomEvent("users-in-review", {
                            detail: message.data,
                        }),
                    );

                    // console.log(
                    //     "Users In Review Recieved",
                    //     JSON.stringify(message.data),
                    // ); <-- for debugging
                    break;
            }
        } catch (err) {
            console.error("Received invalid message", payload);
        }
    };

    dispose() {
        this.messageBus.removeEventListener("message", this.messageHandler);
    }
}
